<template>
  <div>
    <v-card class="pa-1" flat>
      <v-card color="white" rounded="lg">
        <!-- options -->
        <div
          class="d-flex justify-center justify-sm-space-between flex-wrap px-3 py-2"
        >
          <v-btn
            class="my-1"
            color="save"
            @click="downloadTemplate"
            :disabled="loading"
            dark
          >
            <v-icon class="mr-2">mdi-download</v-icon>Descargar plantilla para
            importar
          </v-btn>

          <v-btn
            class="my-1"
            color="warning"
            @click="resetForm"
            :disabled="loading"
          >
            <v-icon class="mr-2">mdi-broom</v-icon>Reiniciar
          </v-btn>
        </div>

        <v-divider></v-divider>

        <v-stepper v-model="step" flat>
          <!-- steps headers -->
          <v-stepper-header class="flex-nowrap">
            <v-stepper-step
              :rules="stepRules(1)"
              :complete="stepIsComplete(1)"
              :step="1"
              >Carga de datos</v-stepper-step
            >
            <v-stepper-step
              :rules="stepRules(2)"
              :complete="stepIsComplete(2)"
              :step="2"
              >Datos de los proveedores</v-stepper-step
            >
            <!-- only for products import -->
            <template v-if="!onlyProviders">
              <v-stepper-step
                :rules="stepRules(3)"
                :complete="stepIsComplete(3)"
                :step="3"
                >Datos de la segmentación</v-stepper-step
              >
              <v-stepper-step
                :rules="stepRules(4)"
                :complete="stepIsComplete(4)"
                :step="4"
                >Previsualización de los datos</v-stepper-step
              >
              <v-stepper-step
                :rules="stepRules(5)"
                :complete="stepIsComplete(5)"
                :step="5"
                >Procesamiento de los datos</v-stepper-step
              >
            </template>
          </v-stepper-header>

          <!-- steps body -->
          <v-stepper-items>
            <!-- upload data -->
            <v-stepper-content :step="1">
              <v-row justify="center" dense>
                <v-col cols="12" sm="10" md="7" lg="6" class="pa-3">
                  <v-checkbox
                    v-model="onlyProviders"
                    label="Importar solo proveedores de productos"
                    hide-details
                  >
                  </v-checkbox>
                </v-col>
                <v-col cols="12"></v-col>
                <!-- select warehouses -->
                <template v-if="!onlyProviders">
                  <v-col cols="12" sm="10" md="7" class="pa-3 pb-0">
                    <div class="text-h6 text-center">
                      Seleccionar almacén para los productos
                    </div>
                  </v-col>
                  <v-col cols="12" sm="7" md="6" class="pa-3">
                    <v-autocomplete
                      v-model="selectedWarehouse"
                      :items="warehouses"
                      item-text="Warehouse"
                      item-value="ID_Warehouse"
                      label="Seleccione almacén"
                      :disabled="loading || stepIsComplete(1)"
                      append-outer
                      outlined
                      dense
                      attach
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12"></v-col>
                </template>

                <!-- upload file -->
                <v-col cols="12" sm="10" md="7" class="pa-3">
                  <div class="text-h6 text-center mb-3">
                    Importar datos desde archivo
                  </div>
                  <div class="d-flex flex-nowrap align-center">
                    <v-file-input
                      prepend-icon=""
                      prepend-inner-icon="mdi-file-excel"
                      v-model="selectedFile"
                      accept=".xlsx, .xls, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      label="Seleccione hoja de calculo"
                      :disabled="loading || stepIsComplete(1)"
                      hide-details
                      outlined
                      dense
                    >
                    </v-file-input>
                    <v-btn
                      class="ml-2"
                      color="primary"
                      @click="uploadProviders"
                      :disabled="loading || stepIsComplete(1)"
                    >
                      <v-icon class="mr-2">mdi-import</v-icon>Cargar archivo
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
              <v-row justify="end" dense>
                <v-col cols="12" sm="6" class="text-center text-sm-right py-3">
                  <v-btn
                    color="comment"
                    @click="step = 2"
                    :disabled="!stepIsComplete(1)"
                    dark
                  >
                    Continuar
                    <v-icon class="ml-2">mdi-chevron-right</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-stepper-content>

            <!-- providers data -->
            <v-stepper-content :step="2">
              <div class="text-h6">Datos de los proveedores importados</div>

              <v-data-table
                :headers="providersHeaders"
                :items="providers"
                :items-per-page="50"
                :footer-props="{
                  showFirstLastPage: true,
                  firstIcon: 'mdi-page-first',
                  lastIcon: 'mdi-page-last',
                  'items-per-page-options': [50, 100, 250],
                  'items-per-page-text': 'Proveedores por página:',
                }"
              >
                <template #item.errors="{ item }">
                  <v-btn @click="showErrorRow(item)" color="error" icon small>
                    <v-icon color="error">mdi-alert</v-icon>
                  </v-btn>
                </template>
                <template #item.status="{ item }">
                  <v-chip
                    :color="item.SystemDateInsert ? 'grey' : 'success'"
                    label
                    small
                    >{{ item.SystemDateInsert ? "Existe" : "Creado" }}</v-chip
                  >
                </template>
              </v-data-table>

              <v-row justify="space-between" dense>
                <v-col cols="12" sm="6" class="text-center text-sm-left py-3">
                  <v-btn color="comment" @click="step = 1" dark>
                    <v-icon class="mr-2">mdi-chevron-left</v-icon>
                    Regresar
                  </v-btn>
                </v-col>

                <v-col cols="12" sm="6" class="text-center text-sm-right py-3">
                  <v-btn
                    v-if="!onlyProviders"
                    color="comment"
                    @click="uploadSegmentationData"
                    :disabled="!stepIsComplete(2)"
                    dark
                  >
                    Continuar
                    <v-icon class="ml-2">mdi-chevron-right</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-stepper-content>

            <template v-if="!onlyProviders">
              <!-- segmentation data -->
              <v-stepper-content :step="3">
                <div class="text-h6">Datos de la segmentación</div>

                <v-data-table
                  v-if="hasErrorSegmentation"
                  :headers="productsHeaders"
                  :items="products"
                  :items-per-page="50"
                  :footer-props="{
                    showFirstLastPage: true,
                    firstIcon: 'mdi-page-first',
                    lastIcon: 'mdi-page-last',
                    'items-per-page-options': [50, 100, 250],
                    'items-per-page-text': 'Registros por página:',
                  }"
                >
                  <template #item.errors="{ item }">
                    <v-btn @click="showErrorRow(item)" color="error" icon small>
                      <v-icon color="error">mdi-alert</v-icon>
                    </v-btn>
                  </template>
                  <template #item.status="{ item }">
                    <v-btn
                      v-if="item.warnings.details.length"
                      @click="showWarningRow(item)"
                      color="error"
                      icon
                      small
                    >
                      <v-icon color="warning">mdi-alert</v-icon>
                    </v-btn>
                    <v-icon v-else color="success">mdi-check-circle</v-icon>
                  </template>
                </v-data-table>
                <template v-else>
                  <v-tabs v-model="tab" background-color="primary" dark>
                    <v-tab>Familias</v-tab>
                    <v-tab>Categorías</v-tab>
                    <v-tab>Subcategorías</v-tab>

                    <v-tabs-items v-model="tab">
                      <v-tab-item>
                        <v-data-table
                          :headers="segmentationHeaders.families"
                          :items="segmentationData.families"
                          :items-per-page="50"
                          :footer-props="{
                            showFirstLastPage: true,
                            firstIcon: 'mdi-page-first',
                            lastIcon: 'mdi-page-last',
                            'items-per-page-options': [50, 100, 250],
                            'items-per-page-text': 'Familias por página:',
                          }"
                        >
                          <template #item.isExisting="{ item }">
                            <v-chip
                              :color="item.isExisting ? 'grey' : 'success'"
                              label
                              small
                              >{{
                                item.isExisting ? "Existe" : "Creado"
                              }}</v-chip
                            >
                          </template>
                        </v-data-table>
                      </v-tab-item>

                      <v-tab-item>
                        <v-data-table
                          :headers="segmentationHeaders.categories"
                          :items="segmentationData.categories"
                          :items-per-page="50"
                          :footer-props="{
                            showFirstLastPage: true,
                            firstIcon: 'mdi-page-first',
                            lastIcon: 'mdi-page-last',
                            'items-per-page-options': [50, 100, 250],
                            'items-per-page-text': 'Categorías por página:',
                          }"
                        >
                          <template #item.isExisting="{ item }">
                            <v-chip
                              :color="item.isExisting ? 'grey' : 'success'"
                              label
                              small
                              >{{
                                item.isExisting ? "Existe" : "Creado"
                              }}</v-chip
                            >
                          </template>
                        </v-data-table>
                      </v-tab-item>

                      <v-tab-item>
                        <v-data-table
                          :headers="segmentationHeaders.subcategories"
                          :items="segmentationData.subcategories"
                          :items-per-page="50"
                          :footer-props="{
                            showFirstLastPage: true,
                            firstIcon: 'mdi-page-first',
                            lastIcon: 'mdi-page-last',
                            'items-per-page-options': [50, 100, 250],
                            'items-per-page-text': 'Subcategorías por página:',
                          }"
                        >
                          <template #item.isExisting="{ item }">
                            <v-chip
                              :color="item.isExisting ? 'grey' : 'success'"
                              label
                              small
                              >{{
                                item.isExisting ? "Existe" : "Creado"
                              }}</v-chip
                            >
                          </template>
                        </v-data-table>
                      </v-tab-item>
                    </v-tabs-items>
                  </v-tabs>
                </template>

                <v-row justify="space-between" dense>
                  <v-col cols="12" sm="6" class="text-center text-sm-left py-3">
                    <v-btn color="comment" @click="step = 2" dark>
                      <v-icon class="mr-2">mdi-chevron-left</v-icon>
                      Regresar
                    </v-btn>
                  </v-col>

                  <v-col
                    cols="12"
                    sm="6"
                    class="text-center text-sm-right py-3"
                  >
                    <v-btn
                      v-if="!onlyProviders"
                      color="comment"
                      @click="uploadValidateProducts"
                      :disabled="!stepIsComplete(3)"
                      dark
                    >
                      Continuar
                      <v-icon class="ml-2">mdi-chevron-right</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-stepper-content>

              <!-- products data -->
              <v-stepper-content :step="4">
                <div class="text-h6">Previsualización de los datos</div>

                <v-data-table
                  :headers="productsHeaders"
                  :items="products"
                  :items-per-page="50"
                  :footer-props="{
                    showFirstLastPage: true,
                    firstIcon: 'mdi-page-first',
                    lastIcon: 'mdi-page-last',
                    'items-per-page-options': [50, 100, 250],
                    'items-per-page-text': 'Productos por página:',
                  }"
                >
                  <template #item.errors="{ item }">
                    <v-btn @click="showErrorRow(item)" color="error" icon small>
                      <v-icon color="error">mdi-alert</v-icon>
                    </v-btn>
                  </template>
                  <template #item.status="{ item }">
                    <v-btn
                      v-if="item.warnings.details.length"
                      @click="showWarningRow(item)"
                      color="error"
                      icon
                      small
                    >
                      <v-icon color="warning">mdi-alert</v-icon>
                    </v-btn>
                    <v-icon v-else color="success">mdi-check-circle</v-icon>
                  </template>
                </v-data-table>

                <v-row justify="space-between" dense>
                  <v-col cols="12" sm="6" class="text-center text-sm-left py-3">
                    <v-btn color="comment" @click="step = 3" dark>
                      <v-icon class="mr-2">mdi-chevron-left</v-icon>
                      Regresar
                    </v-btn>
                  </v-col>

                  <v-col
                    cols="12"
                    sm="6"
                    class="text-center text-sm-right py-3"
                  >
                    <v-btn
                      v-if="!onlyProviders"
                      color="comment"
                      @click="checkWarnings"
                      :disabled="!stepIsComplete(4)"
                      dark
                    >
                      Continuar
                      <v-icon class="ml-2">mdi-chevron-right</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-stepper-content>

              <!-- products process data -->
              <v-stepper-content :step="5">
                <div class="text-h6">Procesamiento de los datos</div>

                <v-data-table
                  :headers="productsHeaders"
                  :items="productsProcessed"
                  :items-per-page="50"
                  :footer-props="{
                    showFirstLastPage: true,
                    firstIcon: 'mdi-page-first',
                    lastIcon: 'mdi-page-last',
                    'items-per-page-options': [50, 100, 250],
                    'items-per-page-text': 'Productos por página:',
                  }"
                >
                  <template #item.status="{}">
                    <v-icon color="success">mdi-check-circle</v-icon>
                  </template>
                </v-data-table>

                <v-row justify="start" dense>
                  <v-col cols="12" sm="6" class="text-center text-sm-left py-3">
                    <v-btn color="comment" @click="step = 4" dark>
                      <v-icon class="mr-2">mdi-chevron-left</v-icon>
                      Regresar
                    </v-btn>
                  </v-col>
                </v-row>
              </v-stepper-content>
            </template>
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </v-card>

    <!-- general alert -->
    <alert
      v-model="alert.status"
      :title="alert.title"
      :message="alert.message"
      :type="alert.type"
    ></alert>

    <alert
      v-model="confirm.status"
      :title="confirm.title"
      :message="confirm.message"
      :type="confirm.type"
      color="warning"
      @action="importProducts"
    ></alert>

    <!-- loading screen -->
    <block :value="loading"></block>
  </div>
</template>

<script>
const headerStatus = { text: "Estado", value: "status" };
const headerError = { text: "Errores", value: "errors" };

export default {
  name: "ManageProductImport",

  props: ["win"],

  data: () => ({
    loading: false,
    alert: {
      status: false,
      title: "",
      message: "",
      type: "info",
    },
    confirm: {
      status: false,
      title: "",
      message: "",
      type: "info",
    },
    step: 1,
    stepsStatus: ["", "", "", "", ""],
    warehouses: [],
    onlyProviders: false,
    selectedWarehouse: "",
    selectedFile: null,
    providers: [],
    providersHeaders: [],
    productsHeaders: [],
    products: [],
    productsProcessed: [],
    warnings: [],
    hasErrorSegmentation: false,
    segmentationHeaders: {
      families: [
        { text: "Familia", value: "name" },
        { text: "Estatus", value: "isExisting" },
      ],
      categories: [
        { text: "Categoría", value: "name" },
        { text: "Familia", value: "family" },
        { text: "Estatus", value: "isExisting" },
      ],
      subcategories: [
        { text: "Subcategoría", value: "name" },
        { text: "Categoría", value: "category" },
        { text: "Familia", value: "family" },
        { text: "Estatus", value: "isExisting" },
      ],
    },
    segmentationData: {
      families: [],
      categories: [],
      subcategories: [],
    },
    tab: null,
  }),
  beforeMount() {
    this.getWarehouses();
  },
  methods: {
    resetForm() {
      this.step = 1;
      this.onlyProviders = false;
      this.selectedFile = null;
      this.selectedWarehouse = "";
      this.stepsStatus = ["", "", "", "", ""];
    },
    showAlert(type, title, message) {
      this.alert.message = message;
      this.alert.title = title;
      this.alert.type = type;
      this.alert.status = true;
    },
    showSuccess(title, message) {
      this.showAlert("info", title, message);
    },
    showWarning(title, message) {
      this.showAlert("warning", title, message);
    },
    showError(title, message) {
      this.showAlert("error", title, message);
    },
    showErrorRow(item) {
      this.showAlert(
        "warning",
        "Error en archivo",
        "Fila " + item.errors.row + "<br>" + item.errors.details.join("<br>")
      );
    },
    showWarningRow(item) {
      this.showAlert(
        "warning",
        "Precauciones con datos",
        "Fila " +
          item.warnings.row +
          "<br>" +
          item.warnings.details.join("<br>")
      );
    },
    showConfirmWarning() {
      let msg =
        "¿Desea continuar con la creación de los productos? Tenga en " +
        "cuenta que existen las siguientes sitauciones con sus datos: ";

      msg += "<ol>";
      for (const item of this.warnings) {
        let htmlWarnings = "";
        for (const warning of item.warnings.details) {
          htmlWarnings += `<li>${warning}</li>`;
        }
        msg += `<li>Fila: ${item.warnings.row}<ol>${htmlWarnings}</ol></li>`;
      }
      msg += "</l>";
      this.confirm.type = "confirm";
      this.confirm.title = "Precauciones con datos";
      this.confirm.message = msg;
      this.confirm.status = true;
    },
    getErrorMsg(err) {
      return (
        err?.response?.data?.message ||
        err.message ||
        "Ha ocurrido un error al cargar el archivo, vuelva a intentarlo más tarde."
      );
    },
    async getWarehouses() {
      try {
        this.warehouses = await this.$API.warehouse.getWarehouses();
      } catch (error) {
        this.showError("Error", "No se cargaron los datos de almacenes");
      }
    },
    downloadTemplate() {
      const filePath = "/Plantilla_Inventario.xlsx";
      const link = document.createElement("a");
      link.href = filePath;
      link.download = "Plantilla_Inventario.xlsx";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    stepIsComplete(step) {
      const index = step - 1;
      return this.stepsStatus[index] === "success";
    },
    stepRules(step) {
      const index = step - 1;
      return [
        () => !this.stepsStatus[index] || this.stepsStatus[index] === "success",
      ];
    },
    stepSetSuccess(step) {
      this.stepsStatus[step - 1] = "success";
      this.step = step;
    },
    stepSetError(step) {
      const stepIndex = step - 1;
      for (let i = this.stepsStatus.length - 1; i > stepIndex; i--) {
        this.stepsStatus[i] = "";
      }
      this.stepsStatus[stepIndex] = "error";
      this.step = step;
    },
    async uploadProviders() {
      try {
        if (!this.selectedFile) {
          throw new Error("Por favor seleccionar un archivo.");
        }

        this.loading = true;

        const formData = new FormData();
        formData.append("excelFile", this.selectedFile);
        this.stepSetSuccess(1);

        const { data } = await this.$API.providers.importProviders(formData);

        this.providersHeaders = [{ ...headerStatus }, ...data.headers];
        this.providers = data.data;
        this.stepSetSuccess(2);
      } catch (err) {
        const errMsg = this.getErrorMsg(err);
        this.showAlert("warning", "Advertencia", errMsg);

        if (err?.response?.data?.data?.errors) {
          const { headers, errors } = err.response.data.data;

          this.providersHeaders = [{ ...headerError }, ...headers];
          this.providers = errors;
          this.stepSetError(2);
        } else {
          this.stepSetError(1);
        }
      } finally {
        this.loading = false;
      }
    },
    async uploadSegmentationData() {
      try {
        if (this.stepIsComplete(3)) {
          this.stepSetSuccess(3);
          return;
        }

        this.loading = true;
        const formData = new FormData();
        formData.append("excelFile", this.selectedFile);

        const { data } = await this.$API.products.dataSegmentation(formData);

        this.segmentationData = {
          families: data.data.families,
          categories: data.data.categories,
          subcategories: data.data.subcategories,
        };
        this.hasErrorSegmentation = false;
        this.stepSetSuccess(3);
      } catch (err) {
        const errMsg = this.getErrorMsg(err);
        this.showAlert("warning", "Advertencia", errMsg);

        if (err?.response?.data?.data?.errors) {
          const { headers, errors } = err.response.data.data;

          this.productsHeaders = [{ ...headerError }, ...headers];
          this.products = errors;
          this.stepSetError(3);
          this.hasErrorSegmentation = true;
        } else {
          this.stepSetError(1);
        }
      } finally {
        this.loading = false;
      }
    },
    async uploadValidateProducts() {
      try {
        if (this.stepIsComplete(4)) {
          this.stepSetSuccess(4);
          return;
        }

        if (!this.selectedFile) {
          throw new Error("Por favor seleccionar un archivo.");
        }

        this.loading = true;

        const formData = new FormData();
        formData.append("excelFile", this.selectedFile);

        const { data } = await this.$API.products.importValidateProducts(
          formData
        );

        this.productsHeaders = [{ ...headerStatus }, ...data.headers];
        this.products = data.data;
        this.warnings = data.warnings;
        this.stepSetSuccess(4);
      } catch (err) {
        const errMsg = this.getErrorMsg(err);
        this.showAlert("warning", "Advertencia", errMsg);

        if (err?.response?.data?.data?.errors) {
          const { headers, errors } = err.response.data.data;

          this.productsHeaders = [{ ...headerError }, ...headers];
          this.products = errors;
          this.stepSetError(4);
        } else {
          this.stepSetError(1);
        }
      } finally {
        this.loading = false;
      }
    },
    checkWarnings() {
      if (this.stepIsComplete(5)) {
        this.stepSetSuccess(5);
        return;
      }
      if (!this.warnings.length) {
        this.importProducts();
      } else {
        this.showConfirmWarning();
      }
    },
    async importProducts() {
      try {
        if (this.stepIsComplete(5)) {
          this.stepSetSuccess(5);
          return;
        }
        this.loading = true;
        this.confirm.status = false;

        const { data } = await this.$API.products.importProducts({
          warehouse: this.selectedWarehouse,
          products: this.products,
        });

        this.productsProcessed = data.data;

        this.stepSetSuccess(5);
      } catch (err) {
        const errMsg = this.getErrorMsg(err);
        this.showAlert("warning", "Advertencia", errMsg);

        if (err?.response?.data?.data?.errors) {
          const { headers, errors } = err.response.data.data;

          this.productsHeaders = [...headers];
          this.products = errors;
          this.stepSetError(5);
        } else {
          this.stepSetError(1);
        }
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
