import axios from "./axios";
// import axios2 from "axios";

export default {
    /**
     * Add Patient
     * @api {POST} /business
     */
    addBusiness(data) {
        const options = {
            method: 'POST',
            url: '/business',
            headers: {'content-type': 'application/json'},
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    FindOne(id){
        const options = {
            method: 'GET',
            url: `/business/${id}`,
            headers: {'content-type': 'application/json'},
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => { 
                    reject(error.message)
                })
        })
    },
    businessList(data) {
        if(axios.defaults.baseURL.length === 0)  window.location.reload();  
        const options = {
            method: 'POST',
            url: `/business/getlist`,
            headers: {'content-type': 'application/json'},
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    getBusinessLogos() {
        const options = {
            method: 'GET',
            url: `/get_business_logos`,
            headers: {'content-type': 'application/json'},
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    businessListHistory(data) {
        // if(axios.defaults.baseURL.length === 0)  window.location.reload();  
        const options = {
            method: 'POST',
            url: `/business/getlist/history`,
            headers: {'content-type': 'application/json'},
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    businessListFiltered(data) {
        const options = {
            method: 'POST',
            url: `/business/getlist/filtered`,
            headers: {'content-type': 'application/json'},
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    updateBusiness(data, id) {
        const options = {
            method: 'PUT',
            url: `/business/${id}`,
            headers: {'content-type': 'application/json'},
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)

                })
        })
    },
    deleteBusiness(id) {
        const options = {
            method: 'DELETE',
            url: `/business/${id}`,
            headers: {'content-type': 'application/json'},
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)

                })
        })
    },
    updateGoogleDriveApi(data) {
        const options = {
            method: 'POST',
            url: `/business/updategoogledriveapi`,
            headers: {'content-type': 'application/json'},
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    updateEmailCredentials(data) {
        const options = {
            method: 'POST',
            url: `/business/updateemailcredentials`,
            headers: {'content-type': 'application/json'},
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    updateFuelStationData(data) {
        const options = {
            method: 'POST',
            url: `/business/updateFuelStationData`,
            headers: {'content-type': 'application/json'},
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    updateRestartControlNumber(data) {
        const options = {
            method: 'POST',
            url: `/business/updateRestartControlNumber`,
            headers: {'content-type': 'application/json'},
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    updateColorInvoices(data) {
        const options = {
            method: 'POST',
            url: `/business/updateColorInvoices`,
            headers: {'content-type': 'application/json'},
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    updateColorTitleInvoices(data) {
        const options = {
            method: 'POST',
            url: `/business/updateColorTitleInvoices`,
            headers: {'content-type': 'application/json'},
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    updatePrescriptionHeights(data) {
		console.log('Data:', data);
        const options = {
            method: 'POST',
            url: `/business/updatePrescriptionHeights`,
            headers: {'content-type': 'application/json'},
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    updateZoneConfiguration(data) {
        const options = {
            method: 'POST',
            url: `/business/updateZoneConfiguration`,
            headers: {'content-type': 'application/json'},
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    getZoneConfiguration(data) {
        const options = {
            method: 'POST',
            url: `/business/getZoneConfiguration`,
            headers: {'content-type': 'application/json'},
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
}