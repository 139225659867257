import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {

    ///////////////////////////////////////INGLES/////////////////////////////////////////
    'en': {
        //Internationalization for Drawer.vue
        customize: 'Customize',
        dashboard: 'Dashboard',
        customers: 'Customers',
        addnewcustomer: 'Add New Customer',
        searchcustomer: 'Search Customer',
        notifications: 'Notifications',
        advertisement: 'Advertisement',
        patients: 'Patients',
        searchpatient: 'Search Patient',
        searchveterinarypatient: 'Search Veterinary Patient',
        inventory: 'Inventory',
        addprovider: 'Add Provider',
        searchproviders: 'Search Providers',
        searchpurchareorders: 'Search Purchare Orders',
        addproducts: 'Add Products',
        searchproducts: 'Search Products',
        services: 'Services',
        pricelist: 'Price List',
        settings: 'Settings',
        adduser: 'Add User',
        searchuser: 'Search User',
        addbusiness: 'Add Business',
        searchbusiness: 'Search Business',
        managetemplate: 'Manage Templates',
        logout: 'Logout',
        businesstype: 'Business Type',
        branchname: 'Branch Name',
        numberofusersinthebranch: 'Number of users in the branch',
        titlemodalselectbranch: 'You are a business manager',
        textmodalselectbranch: 'Please select the branch you want to enter (In case there are no branches available, contact the administrator).',
        selectbranch: 'Select a branch',
        continueselectbranch: 'Continue',
        required: 'Required Field',
        managevaccineplans: 'Manage Vaccine Plans',
        AddEmail: 'Add Email',
        EmailList: 'Email List',


        //Internationalization for dashboard
        vwdashboard_dashboard: 'Dashboard',
        vwdashboard_totalcustomers: 'Total Customers',
        vwdashboard_totalpatients: 'Total Patients',
        vwdashboard_todaytotalcustomers: 'Today Total Customers',
        vwdashboard_todaytotalpatients: 'Today Total Patients',
        vwdashboard_totalsalesmonth: 'Total sales of the month',
        vwdashboard_comparesalesmonth: 'Compare sales of Last month',
        vwdashboard_topproductssales: 'Top Products Sales',
        vwdashboard_todayvisits: 'Today Visits',

        //Internationalization for invoices
        vwinvoices_invoices: 'Invoices',
        vwinvoices_generateinvoices: 'GENERATE INVOICES',
        vwinvoices_invoiceshistory: 'INVOICES HISTORY',
        vwinvoices_readytobill: 'READY TO BILL',
        vwinvoices_cfromdate: 'From Date',
        vwinvoices_ctodate: 'To Date',
        vwinvoices_cidclient: 'ID Client:',
        vwinvoices_cidname: 'ID Name:',
        vwinvoices_cinvoicetype: 'Invoice Type',
        vwinvoices_clegalidinvoice: 'Legal ID Invoice',
        vwinvoices_cnewinvoice: 'New Invoice',
        vwinvoices_csearchcustomer: 'Search Customer',
        vwinvoices_clegalname: 'Legal Name',
        vwinvoices_clegalentity: 'Legal Entity',
        vwinvoices_clegalnit: 'Legal NIT',
        vwinvoices_clegaladdress: 'Legal Address',
        vwinvoices_clegaltaxpayersize: 'Legal TaxPayer Size',
        vwinvoices_clegalnrc: 'Legal NRC',
        vwinvoices_clegalsector: 'Legal Sector',
        vwinvoices_clegalinformation: 'Legal Information',
        vwinvoices_cclientisnontaxable: 'Client is non taxable',
        vwinvoices_csaveasnewclient: 'SAVE AS NEW CLIENT',
        vwinvoices_cselectssbill: 'Select the products or services to bill',
        vwinvoices_cfilter: 'Filter',
        vwinvoices_cpricelist: 'Price List',
        vwinvoices_citemstype: 'Items Type',
        vwinvoices_cwarehouse: 'Warehouse',
        vwinvoices_ccurrentinvoiceitems: 'Current Invoice Items',
        vwinvoices_capplydiscount: 'Apply Discount',
        vwinvoices_cactualprice: 'Actual Price',
        vwinvoices_cdiscountporcentage: 'Discount Porcentage',
        vwinvoices_cdiscountfixed: 'Discount Fixed',
        vwinvoices_cbtnapply: 'Apply',
        vwinvoices_cbtncancel: 'Cancel',
        vwinvoices_cgeneratepdf: 'Generate PDF',
        vwinvoices_cnontaxable: 'Non taxable',
        vwinvoices_ctotal: 'Total Sales',
        vwinvoices_csendtobill: 'Send to Bill',
        vwinvoices_csaveasdraft: 'Save as Draft',
        vwinvoices_cnullinvoice: 'Null Invoice',
        vwinvoices_cadmincredentials: 'Please Enter Admin Credentials',
        vwinvoices_close: 'Close',
        vwinvoices_onlyshowpendingtobill: 'Only show pending to bill',
        vwinvoices_onlyshowpendingtopay: 'Only show pending to pay',
        vwinvoices_onlyshownullfied: 'Only show nullfied',
        vwinvoices_selectedinvoiceitem: 'Selected Invoice Item',
        vwinvoices_creationdate: 'Creation Date',
        vwinvoices_invoicename: 'Invoice Name',
        vwinvoices_invoiceaddress: 'Invoice Address',
        vwinvoices_invoicenrc: 'Invoice NRC',
        vwinvoices_invoicenit: 'Invoice NIT',
        vwinvoices_invoiceentity: 'Invoice Entity',
        vwinvoices_invoicetaxpayersize: 'Invoice TaxPayer Size',
        vwinvoices_invoicesector: 'Invoice Sector',
        vwinvoices_currentinvoiceitems: 'Current Invoice Items',
        vwinvoices_totalinletters: 'TOTAL IN LETTERS',
        vwinvoices_deleteinvoice: 'DELTE INVOICE',
        vwinvoices_billthisnow: 'BILL THIS NOW',
        vwinvoices_deletethisinvoice: 'Are you sure to delete this invoice ?',
        Changue_Name_Of_Product: "Change Name Of Product",

        //Internacionalization for user module
        vwuser_username: 'User name*',
        vwuser_chosephoto: 'Choose your photo',
        vwuser_oldpassword: 'Old Password*',
        vwuser_newpassword: 'New Password*',
        vwuser_password: 'Contraseña*',


        //Settings Module
        VaccinePlansAvailable: 'Vaccine Plans Available',
        No_Selected_Vaccine: 'No Selected Vaccine',
        ManageMedicationPlans: "Manage Medication Plans",
        Medication_Comment: "Medication Comment",
        No_selected_Medication: "No selected Medication",
        Add_Medication_Plan: "Add Medication Plan",
        Save: "Save",
        Medication: "Medication",
        Do_you_want_to_add: "Do you want to add",
        Do_you_want_to_remove: "Do you want to remove",
        Yes: "Yes",
        No: "No",

        //vetertinaryMedication
        New_Medications:"New Medications",
        Clear_Data: "Clear Data",
        Med_Save: "Save",
        Add_Medications: "Add Medications",
        ID_Patient: "ID Patient",
        Breed: "Breed",
        Patient_Name: "Patient Name",
        Species: "Species",
        Medication_List: "Medication List",
        Medication_Plans: "Medication Plans",

        //veterinary vaccine
        New_Vaccine: "New Vaccine",
        vac_Clear_Data : "Clear Data",
        vac_Save: "Save",
        Show_all_vaccines: "Show all vaccines",
        vac_ID_Patient: "ID Patient",
        vac_Breed: "Breed",
        vac_Patient_Name: "Patient Name",
        vac_Species: "Species",
        Vaccine_List: "Vaccine List",
        Vaccine_Plans: "Vaccine Plans",
        Add_Vaccine_Plan: "Add Vaccine Plan",
        Vaccine_Comment: "Vaccine Comment",
        Vaccines:"Vaccines",

        //deletepopup
        Are_you_sure_you_want_to_delete: "Are you sure you want to delete",
        del_Yes: "Yes",
        del_No: "No",

        //manageroles
        Feature: "Feature",
        Capabilities: "Capabilities",
        Update: "Update",
        Delete: "Delete",
        category: "Category",
        View: "View",
        Create: "Create",
        Generate_Invoice: "Generate Invoice",
        Advertisement: "Advertisement",
        Invoice_History: "Invoice History",
        Ready_to_Bill: "Ready to Bill",
        Manage_Customer: "Manage Customer",
        Manage_Patients: "Manage Patients",
        Billing_History: "Billing History",
        Laboratory_Test: "Laboratory Test",
        Notifications: "Notifications",
        Manage_Providers: "Manage Providers",
        Manage_Products: "Manage Products",
        Manage_Services: "Manage Services",
        Category: "Category",
        Manage_user_roles: "Manage user roles",
        Create_user_roles: "Create new user role",
        Search_user_roles: "User roles",
        Select_profile_def: "Select defined profile",
        Disable_all_features: "Disable all features",
        Admin_for_business: "Admin for business",
        User_for_business: "User for business",
        Manage_Transfers: "Manage Transfers",
        Transfer_Request: "Transfer Request",
        Billing_Template: "Billing Template",
        Cut_Box: "Cut Box",
        Change_Price_Product: "Change Price Product",
        Invoice_Save: "Invoice Save",
        Send: "Send",
        Billing_History_Invalidate:  "Invalidate",
        Billing_History_Make_Contingencies: "Make Contingencies",
        Print : "Print",
        Quotation: "Quotation",
        Quotation_Billing: "Quotation Billing",
        Export_Excel: "Export to Excel",
        Export_PDF: "Export to PDF",
        Generate_Invoice_Save: "Save Invoice",
        Create_Template: "Create Template",
        Add_Customer: "Add Customer",
        Create_Patient: "Add as Patient",
        Search_Customer: "Search Customer",
        Accounts_Receivable: "Accounts Receivable",
        View_List: "View List",
        View_List_D: "View Detail Information",
        Make_Credit_Memos: "Make Credit Memos",
        View_Credit_History_And_Credit_Invoices : "View Credit History And Credit Invoices",
        Customer_Patient: "Patient",
        Billed_Services: "Billed services",
        Add: "Add",
        View_Assigned: "View Assigned",
        Assign_Test: "Assign Test",
        Credits: "Credits",
        Warehouse: "Warehouse",
        Search_Provider: "Search Provider",
        Purchase_Order: "Purchase Order",
        Billing: "Billing",
        Add_Products: "Add Products",
        Select_W_Principal: "Select Principal Warehouse",
        View_Products: "View Products",
        Add_Providers: "Add Providers",
        Add_Transfers: "Add Transfers",
        Add_Transfers_Request: "Add Transfers Request",
        Search_Transfers: "Search Transfers",
        List_Details: "List Details",
        Search_Transfers_Request: "Search Transfers Request",
        Import_Json: "Import JSON",
        Accounts_Payable: "Accounts Payable",
        History_of_Withholding_Vouchers: "History of Withholding Vouchers",
        Products_Of_A_Supplier: "Products of A Supplier",
        View_Global_Withholding_Voucher_History: "View Global Withholding Voucher History",
        Create_Manual_Withholding_Tax_Receipt: "Create Manual Withholding Tax Receipt",
        Print_Withholding_Tax_Receipts: "Print Withholding Tax Receipts",
        Invalidating_Withholding_Vouchers: "Invalidating Withholding Vouchers",
        Make_a_Credit_Memo: "Make a Credit Memo",
        Credit_Memo_and_Credit_Purchase_Order_History: "Credit Memo and Credit Purchase Order History",
        View_Product_Information: "View Product Information",
        Add_Products_to_Supplier: "Add Products to Supplier",
        Make_Contingencies: "Make Contingencies",
        Search_Patient: "Search Patients",
        Add_Tests: "Add Tests",
        Save_Registry: "Previous Tests",
        Previous_Tests: "View Previous Exams",
        Grooming: "Grooming",
        Medical_History: "Medical History",
        Prescription: "Prescription",
        Medications: "Medications and Vaccines",
        Surgery: "Surgery",
        Documents: "Documents and Images",
        Medical_Reports: "Medical Reports",
        Breeds: "Breed",
        View_History: "Ver Historial",
        Register_visit: "Register visit",
        Expiring: "Expiring",
        Print_Health_Certificate: "Print Health Certificate",
        Print_Medical_Record: "Print Medical Record",
        Create_Health_Certificate: "Create Health Certificate",
        Create_Medical_Record: "Create Medical Record",
       
        Assignment_of_Accounts_Products: "Assignment of Accounts Product",
        Assignment_of_Accounts_Services: "Assignment  of Account Services",
        Assignment_of_Accounts_General_Parameters: "Assignment of Account General Parameters",
        Products_History: "Products History",
        Services_History: "Services History",
        Search_Account: "Search Account",

        Assign_Cost_Of_Sale: "Assign Cost Of Sale",
        Assign_Inventory : "Assign Inventory",
        Assign_Account : "Assign Account",
        View_Accounts_Purchases : "View Accounts Purchases",
        Update_Accounts_Purchases : "Update Accounts Purchases",
        View_Sales_Accounts : "View Sales Accounts",
        Update_Sales_Accounts : "Update Sales Accounts",
        View_Other_Accounts : "View Other Accounts",
        Update_Other_Accounts : "Update Other Accounts",
        Search_Account_Global: "Search Account Global",

        Add_Accounting_Entry: "Add Accounting",
        Accounting_Search_Entry: "Search Accounting",
        Expense_Management: "Expense Management",
        Expense_Providers: "Expense Providers",
        Expense_Entry: "Expense Entry",
        Expense_History_Entry: "Expense History Entry",
        Allow_Accounting_Module: "Allow Accounting Module",

        Report_Sellers_View: "Management Report View",
        Report_General_Inventory_Summary_View: "General Inventory Summary View",
        Report_General_Inventory_Detail_View: "General Inventory Detail View",
        Report_Accounts_Receivable_View: "Accounts Receivable View",
        Report_Accounts_Payable_View: "Accounts Payable View",
        Report_Sales_View: "Sales View",
        Report_Purchases_View: "Purchases View",
        Report_General_Inventory_Summary: "General Inventory Summary",
        Report_General_Inventory_Detail: "General Inventory Detail",
        Report_Accounts_Receivable: "Accounts Receivable",
        Report_Accounts_Payable: "Accounts Payable",
        
        Management_Report: "Management Report",
        Credit_Config: "Credit Config",
        Limit: "Limit credit",
        Deadlines: "Credit Deadlines",
        State: "State of Credit",

        Modulo_Reportes: "Reporting Module",
        Modulo_Reportes_Ver_Reportes_Ventas_Tab_Ganancias: "Reportes de Ventas: Ver reporte de ganancias general y detallado",
        Modulo_Reportes_Ver_Reportes_Inventario_Tab_Existencias: "Reportes de Inventario: Ver reporte de existencias con costos",
        Modulo_Reportes_Ver_Reportes_Inventario_Tab_Existencias_Sin_Costos: "Reportes de Inventario: Ver reporte de existencias sin costos",
        Modulo_Reportes_Ver_Reportes_Inventario_Tab_Ajuste_De_Inventario: "Reportes de Inventario: Realizar ajustes de inventario",
        Modulo_Reportes_Ver_Reportes_Inventario_Tab_Promedio_Ponderado: "Reportes de Inventario: Ver reporte de promedio ponderado",
        Modulo_Reportes_Ver_Reportes_Inventario_Tab_PEPS: "Reportes de Inventario: Ver reporte PEPS",
        Modulo_Reportes_Ver_Reportes_Inventario_Tab_UEPS: "Reportes de Inventario: Ver reporte UEPS",
        Billing_History_Ver_Facturas_Sujeto_Excluido: "Ver Facturas Sujeto Excluido",
    },








    ///////////////////////////////////////ESPAÑOL/////////////////////////////////////////
    'es': {
        //Internationalization for Drawer.vue
        customize: 'Personalización',
        dashboard: 'Tablero',
        customers: 'Clientes',
        addnewcustomer: 'Añadir Nuevo Cliente',
        searchcustomer: 'Buscar Cliente',
        notifications: 'Notificaciones',
        advertisement: 'Avisos',
        patients: 'Pacientes',
        Edit:'Editar',
        Delete:'Eliminar',
        searchpatient: 'Buscar Paciente',
        searchveterinarypatient: 'Buscar Paciente de Veterinaría',
        inventory: 'Inventario',
        addprovider: 'Añadir Proveedor',
        searchproviders: 'Buscar Proveedores',
        searchpurchareorders: 'Buscar ordenes de compras',
        addproducts: 'Añadir Productos',
        searchproducts: 'Buscar Productos',
        services: 'Servicios',
        pricelist: 'Lista de precios',
        settings: 'Configuración',
        adduser: 'Añadir Usuario',
        searchuser: 'Buscar Usuario',
        addbusiness: 'Añadir Negocio',
        searchbusiness: 'Buscar Negocio',
        managetemplate: 'Administrar Plantillas de Laboratorio',
        logout: 'Cerrar Sesión',
        businesstype: 'Tipo de Negocio',
        branchname: 'Nombre de Sucursal',
        numberofusersinthebranch: 'Cantidad de usuarios en la sucursal',
        titlemodalselectbranch: 'Eres un gerente del negocio',
        textmodalselectbranch: 'Por favor seleccione la sucursal a la que desea ingresar (En caso de no haber sucursales disponible, contactar al administrador).',
        selectbranch: 'Seleccionar sucursal',
        continueselectbranch: 'Continuar',
        required: 'Campo requerido',
        managevaccineplans: 'Administrar planes de vacunación',
        AddEmail: 'Agregar correo electrónico',
        EmailList: 'Lista de correo electrónico',


        //Internationalization for dashboard
        vwdashboard_dashboard: 'Tablero',
        vwdashboard_totalcustomers: 'Total de clientes',
        vwdashboard_totalpatients: 'Total de pacientes',
        vwdashboard_todaytotalcustomers: 'Clientes totales de hoy',
        vwdashboard_todaytotalpatients: 'Pacientes totales de hoy',
        vwdashboard_totalsalesmonth: 'Ventas totales del mes',
        vwdashboard_comparesalesmonth: 'Comparación de ventas del último mes',
        vwdashboard_topproductssales: 'Mejores productos vendidos',
        vwdashboard_todayvisits: 'Visitas de hoy',

        //Internationalization for invoices
        vwinvoices_invoices: 'Facturas',
        vwinvoices_generateinvoices: 'GENERAR FACTURAS',
        vwinvoices_invoiceshistory: 'HISTORIAL DE FACTURAS',
        vwinvoices_readytobill: 'LISTO PARA FACTURAR',
        vwinvoices_cfromdate: 'Fecha de Inicio',
        vwinvoices_ctodate: 'Fecha de Final',
        vwinvoices_cidclient: 'ID:',
        vwinvoices_cidname: 'Cliente:',
        vwinvoices_cinvoicetype: 'Tipo de Factura',
        vwinvoices_clegalidinvoice: 'ID de Factura Legal',
        vwinvoices_cnewinvoice: 'Nueva Factura',
        vwinvoices_csearchcustomer: 'Buscar Cliente',
        vwinvoices_clegalname: 'Nombre Legal',
        vwinvoices_clegalentity: 'Entidad Legal',
        vwinvoices_clegalnit: 'NIT Legal',
        vwinvoices_clegaladdress: 'Dirección Legal',
        vwinvoices_clegaltaxpayersize: 'Tamaño Legal del Contribuyente',
        vwinvoices_clegalnrc: 'NRC Legal',
        vwinvoices_clegalsector: 'Sector Legal',
        vwinvoices_clegalinformation: 'Información Legal',
        vwinvoices_cclientisnontaxable: 'Cliente no sujeto a impuestos',
        vwinvoices_csaveasnewclient: 'GUARDAR NUEVO CLIENTE',
        vwinvoices_cselectssbill: 'Seleccione los productos o servicios a facturar',
        vwinvoices_cfilter: 'Filtrar',
        vwinvoices_cpricelist: 'Lista de precios',
        vwinvoices_citemstype: 'Tipo de items',
        vwinvoices_cwarehouse: 'Almacén',
        vwinvoices_ccurrentinvoiceitems: 'Elementos de la factura actual',
        vwinvoices_capplydiscount: 'Aplicar Descuento',
        vwinvoices_cactualprice: 'Precio actual',
        vwinvoices_cdiscountporcentage: 'Porcentaje de descuento',
        vwinvoices_cdiscountfixed: 'Descuento fijo',
        vwinvoices_cbtnapply: 'Aplicar',
        vwinvoices_cbtncancel: 'Cancelar',
        vwinvoices_cgeneratepdf: 'Generar PDF',
        vwinvoices_cnontaxable: 'Sin Impuestos',
        vwinvoices_ctotal: 'Venta Total',
        vwinvoices_csendtobill: 'Enviar a facturar',
        vwinvoices_csaveasdraft: 'Guardar borrador',
        vwinvoices_cnullinvoice: 'Factura Nula',
        vwinvoices_cadmincredentials: 'Por favor ingrese las credenciales del administrador',
        vwinvoices_close: 'Cerrar',
        vwinvoices_onlyshowpendingtobill: 'Solo mostrar pendinetes de facturar',
        vwinvoices_onlyshowpendingtopay: 'Solo mostrar pendientes de pago',
        vwinvoices_onlyshownullfied: 'Solo mostrar anuladas',
        vwinvoices_selectedinvoiceitem: 'Elemento de factura seleccionado',
        vwinvoices_creationdate: 'Fecha de Creación',
        vwinvoices_invoicename: 'Nombre',
        vwinvoices_invoiceaddress: 'Dirección',
        vwinvoices_invoicenrc: 'NRC',
        vwinvoices_invoicenit: 'NIT',
        vwinvoices_invoiceentity: 'Entidad',
        vwinvoices_invoicetaxpayersize: 'Tamaño del Contribuyente',
        vwinvoices_invoicesector: 'Sector',
        vwinvoices_currentinvoiceitems: 'Elementos de la factura actual',
        vwinvoices_totalinletters: 'TOTAL EN LETRAS',
        vwinvoices_deleteinvoice: 'ELIMINAR FACTURA',
        vwinvoices_billthisnow: 'FACTURAR AHORA',


        //Internacionalization for user module
        vwuser_username: 'Nombre de usuario*',
        vwuser_chosephoto: 'Seleccionar foto de perfil',
        vwuser_oldpassword: 'Contraseña antigua*',
        vwuser_newpassword: 'Nueva contraseña*',
        vwuser_password: 'Contraseña*',



        //Internacionalization for provider module



        //Settings Module
        VaccinePlansAvailable: 'Planes de vacunas disponibles',
        No_Selected_Vaccine: 'Sin vacuna seleccionada',
        ManageMedicationPlans: "Administrar planes de medicamentos",
        Medication_Comment: "Comentario sobre medicamentos",
        No_selected_Medication: "Sin medicación seleccionada",
        Add_Medication_Plan: "Agregar plan de medicamentos",
        Save: "Guardar",
        Medication: "Medicamentos",
        Do_you_want_to_add: "¿Quieres añadir",
        Do_you_want_to_remove: "¿Desea eliminar",
        Yes: "Sí",
        No: "No",

        //vetertinaryMedication
        New_Medications:"Nuevos medicamentos",
        Clear_Data: "Limpiar datos",
        Med_Save: "Guardar",
        Add_Medications: "Añadir medicamentos",
        ID_Patient: "Identificación del paciente",
        Breed: "Raza",
        Patient_Name: "Nombre del paciente",
        Species: "Especie",
        Medication_List: "Lista de medicamentos",
        Medication_Plans: "Planes de medicamentos",

        //veterinary vaccine
        New_Vaccine: "Nueva vacuna",
        vac_Clear_Data : "Limpiar datos",
        vac_Save: "Guardar",
        Show_all_vaccines: "Mostrar todas las vacunas",
        vac_ID_Patient: "Identificación del paciente",
        vac_Breed: "Raza",
        vac_Patient_Name: "Nombre del paciente",
        vac_Species: "Especie",
        Vaccine_List: "Lista de vacunas",
        Vaccine_Plans: "Planes de vacunación",
        Add_Vaccine_Plan: "Agregar plan de vacunas",
        Vaccine_Comment: "Comentario sobre la vacuna",
        Vaccines:"Vacunas",

        //deletepopup
        Are_you_sure_you_want_to_delete: "¿Está seguro de que desea eliminar",
        del_Yes: "Sí",
        del_No: "No",

        //manageroles
        Feature: "Permisos",
        Capabilities: "Estado",
        View: "Ver",
        view: "Ver",
        send: "Enviar",
        Update: "Actualizar",
        category: "Categoría",
        Create: "Crear",
        Advertisement: "Clientes: Anuncios",
        Generate_Invoice: "POS: Generar factura",
        Invoice_History: "Historial de facturas",
        Ready_to_Bill: "POS: Listo para facturar",
        Manage_Customer: "Clientes: Administrar clientes",
        Manage_Patients: "Pacientes: Administrar pacientes",
        Billing_History: "POS: Historial de facturación",
        Laboratory_Test: "Paciente: Exámenes de laboratorio",
        Notifications: "Clientes: Notificaciones",
        AdvancePayment: "Anticipos",
        Manage_Providers: "Inventario-Compras: Administrar proveedores",
        Manage_Products: "Inventario-Productos: Administrar productos",
        Manage_Services: "Inventario: Administrar servicios",
        Category: "Categoría",
        Manage_user_roles: "Administrar Roles de Usuario",
        Create_user_roles: "Crear Nuevo Perfil de Usuario",
        Search_user_roles: "Permisos de Roles de Usuario",
        Select_profile_def:"Selecionar perfil predefinido",
        Disable_all_features: "Deshabilitar todas las funciones",
        Admin_for_business: "Admin para negocio",
        User_for_business: "Usuario para negocio",
        Manage_Transfers: "Inventario-Traspasos: Administrar transferencias",
        Transfer_Request: "Inventario-Traspasos: Solicitud de transferencia",
        Billing_Template: "POS: Plantilla de facturación",
        Cut_Box: "POS: Corte de caja",
        Change_Price_Product: "Cambiar precio de producto",
        Invoice_Save: "Guardar factura",
        Send: "Enviar",
        Billing_History_Invalidate:  "Invalidar factura",
        Billing_History_Make_Contingencies: "Hacer contingencias",
        Print : "Imprimir",
        Quotation: "POS: Cotizaciónes",
        Quotation_Billing: "POS: Facturación de cotizaciónes",
        Export_Excel: "Exportar a Excel",
        Export_PDF: "Exportar a PDF",
        Generate_Invoice_Save: "Guardar Factura",
        Create_Template: "Crear Plantilla",
        Add_Customer: "Cliente: Añadir Cliente",
        Create_Patient: "Cliente: Añadir Paciente",
        Search_Customer: "Cliente: Buscar Cliente",
        Accounts_Receivable: "Cliente: Cuentas por cobrar",
        Select_Price_List: "Seleccionar Lista de Precios",
        Select_Warehouse: "Seleccionar Almacen",
        View_List: "Ver listado",
        View_List_D: "Ver información detallada",
        Make_Credit_Memos: "Realizar abonos a crédito",
        View_Credit_History_And_Credit_Invoices : "Ver historial de abonos y facturas a crédito",
        Customer_Patient: "Cliente: Paciente",
        Billed_Services: "Pacientes: Servicios facturados",
        Add: "Añadir",
        View_Assigned: "Ver Asignados",
        Assign_Test: "Asignar Prueba",
        Credits: "Créditos",
        Warehouse: "Inventario: Almacén",
        Search_Provider: "Inventario-Compras: Buscar Proveedor",
        Purchase_Order: "Inventario-Compras: Orden de Compra",
        Billing: "Facturar",
        Add_Products: "Inventario-Productos: Añadir Productos",
        Select_W_Principal: "Seleccionar Almacén Principal",
        View_Products: "Ver Productos",
        Search_Products: "Inventario-Productos: Buscar Productos",
        Add_Providers: "Inventario-Compras: Añadir Proveedores",
        Add_Transfers: "Inventario-Traspasos: Añadir Traspasos",
        Add_Transfers_Request: "Inventario-Traspasos: Añadir Solicitud de Traspasos",
        Search_Transfers: "Inventario-Traspasos: Buscar Transferencias",
        List_Details: "Listar Detalles",
        Search_Transfers_Request: "Inventario-Traspasos: Buscar Solicitud de Transferencias",
        Import_Json: "Importar JSON",
        Accounts_Payable: "Inventario-Compras: Cuentas por pagar",
        History_of_Withholding_Vouchers: "Inventario: Historial de Comprobantes de Retención",
        Products_Of_A_Supplier: "Inventario-Compras: Productos de un Proveedor",
        View_Global_Withholding_Voucher_History: "Ver Historial de Comprobantes de Retención Global",
        Create_Manual_Withholding_Tax_Receipt: "Crear Comprobantes de Retención Manual",
        Print_Withholding_Tax_Receipts: "Imprimir Comprobantes de Retención",
        Invalidating_Withholding_Vouchers: "Invalidar Comprobantes de Retención",
        Make_a_Credit_Memo: "Realizar un Abono a Crédito",
        Credit_Memo_and_Credit_Purchase_Order_History: "Historial de Abonos a Crédito y Órdenes de Compra a Crédito",
        View_Product_Information: "Ver Información de Producto",
        Add_Products_to_Supplier: "Añadir Productos a Proveedor",
        Make_Contingencies: "Hacer Contingencias",
        Search_Patient: "Pacientes: Buscar Pacientes",
        Add_Tests: "Crear Pruebas",
        Save_Registry: "Guardar Registro de Exámenes",
        Previous_Tests: "Ver Exámenes Previos",
        Grooming: "Pacientes: Peluquería",
        Medical_History: "Pacientes: Historial Médico",
        Prescription: "Pacientes: Prescripción",
        Open_And_Close_Month_Operations: "Apertura y Cierre de Mes",
        Medications: "Pacientes: Medicamentos y Vacunas",
        Surgery: "Pacientes: Cirugía",
        Documents: "Pacientes: Documentos y Imagenes",
        Medical_Reports: "Pacientes: Reportes Médicos",
        Breeds: "Pacientes: Reproducción",
        View_History: "Ver Historial",
        Register_visit: "Pacientes: Registrar Visita",
        Expiring: "Ver Registro de Vacunas Próximas a expirar",
        Print_Health_Certificate: "Imprimir Constancia Médica",
        Print_Medical_Record: "Imprimir Certificado de Salud",
        Create_Medical_Record: "Añadir Constancia Médica",
        Create_Health_Certificate: "Añadir Certificado de Salud",


       
        Accounts_View_Other_Accounts_General_Parameters: "Ver Parámetros Generales Otras Cuentas",
        Accounts_Update_Other_Accounts_General_Parameters: "Actualizar Parámetros Generales Otras Cuentas",
        Accounts_Save_General_Parameters: "Guardar Parámetros Generales",

        Assignment_of_Accounts_Products: "Contabilidad-Asignación: Asignación de Cuentas de Productos",
        Assignment_of_Accounts_Services: "Contabilidad-Asignación: Asignación de Cuentas de Servicios",
        Assignment_of_Accounts_General_Parameters: "Contabilidad-Asignación: Asignación de Cuentas de Parámetrización General",
        Products_History: " Historial de Productos",
        Services_History: " Historial de Servicios",
        Search_Account: "Buscar Cuenta",
        Assign_Cost_Of_Sale: "Asignar Costo de Venta",
        Assign_Inventory : "Asignar Inventario",
        Assign_Account : "Asignar Cuenta",
        View_Accounts_Purchases : "Ver Cuentas de Compras",
        Update_Accounts_Purchases : "Actualizar Cuentas de Compras",
        View_Sales_Accounts : "Ver Cuentas de Ventas",
        Update_Sales_Accounts : "Actualizar Cuentas de Ventas",
        View_Other_Accounts : "Ver Otras Cuentas",
        Update_Other_Accounts : "Actualizar Otras Cuentas",
        Search_Account_Global: "Asignar Cuenta Global",
        Changue_Name_Of_Product: "Cambiar Descripción de Producto",
        Add_Accounting_Entry: "Contabilidad-Partidas-Contables: Agregar Partida Contable",
        Accounting_Search_Entry: "Contabilidad-Partidas-Contables: Buscar Partida contable",
        Expense_Management: "Contabilidad-Adiministracion-Gastos: Administración de Gastos",
        Expense_Providers: "Contabilidad-Adiministracion-Gastos: Administración de Proveedores de Gastos",
        Expense_Entry: "Contabilidad-Adiministracion-Gastos:: Ingreso de gastos",
        Expense_History_Entry: "Contabilidad-Adiministracion-Gastos: Historial de Ingreso de gastos",
        Allow_Accounting_Module: "Contabilidad: Activar Módulo de Contabilidad" ,
        Report_Sellers_View: "Ver Reportes Administrativos",
        Report_Past_Accounts_Receivable_View: "Ver Reporte de Cuentas por Cobrar Vencidas",
        Report_General_Inventory_Summary_View: "Ver Resumen de Inventario General",
        Report_Summary_Expired_Vendor_Credit_View: "Ver resumen de Abonos Expirados a Vendedor",
        Report_Movement_Sales_Summary_View: "Ver Resumen de Ventas de Movimiento",
        Report_Statement_Accounts_View: "Ver Estado de Cuenta",
        Report_Cash_Cut_Summary_View: "Ver Resumen de Corte de Caja",
        Report_Accounts_Receivable_Summary_View: "Ver Resumen de Cuentas por Cobrar",
        Management_Report: "Reportes Administrativos",
        Credit_Config: "Cliente: Configuración de Crédito",
        Limit: "Límite de Créditos Permitido",
        Deadlines: "Plazo de Créditos",
        State: "Estado de Créditos",

        //Roles del modulo de reportes
        Modulo_Reportes: "Módulo de Reportes",
        Modulo_Reportes_Ver_Reportes_Ventas_Tab_Ganancias: "Reportes de Ventas: Ver reporte de ganancias general y detallado",
        Modulo_Reportes_Ver_Reportes_Inventario_Tab_Existencias: "Reportes de Inventario: Ver reporte de existencias con costos",
        Modulo_Reportes_Ver_Reportes_Inventario_Tab_Existencias_Sin_Costos: "Reportes de Inventario: Ver reporte de existencias sin costos",
        Modulo_Reportes_Ver_Reportes_Inventario_Tab_Ajuste_De_Inventario: "Reportes de Inventario: Realizar ajustes de inventario",
        Modulo_Reportes_Ver_Reportes_Inventario_Tab_Promedio_Ponderado: "Reportes de Inventario: Ver reporte de promedio ponderado",
        Modulo_Reportes_Ver_Reportes_Inventario_Tab_PEPS: "Reportes de Inventario: Ver reporte PEPS",
        Modulo_Reportes_Ver_Reportes_Inventario_Tab_UEPS: "Reportes de Inventario: Ver reporte UEPS",
        Modulo_Reportes_Ver_Reportes_Contables_Ventas_y_Compras: "Reportes Contables: Ver Reporte de Ventas y Compras",
        Modulo_Reportes_Ver_Reportes_Contables_Exportar_CSV: "Reportes Contables: Ver Exportar CSV",
        Modulo_Reportes_Ver_Reportes_Contables_Libros_Contables: "Reportes Contables: Ver Libros Contables",
        Modulo_Reportes_Ver_Reportes_Contables_Balances: "Reportes Contables: Ver Balances",
        Billing_History_Ver_Facturas_Sujeto_Excluido: "Ver Facturas Sujeto Excluido",
        Generate_Invoice_Editar_Descuento_en_POS: "Editar Descuento en POS",
        Lista_De_Precios_Editar_Precios_Originales: "Cambiar Precios Originales",
        Lista_De_Precios_Ver_Modulo: "Ver",
        Lista_De_Precios: "Inventario-Productos: Lista de Precios",
    },
};

const i18n = new VueI18n({ locale: 'es', fallbackLocale: 'en', messages, });

export default i18n
