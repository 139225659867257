<template>
  <div class="white pa-4">
    <v-row>
      <v-col cols="12" md="6">
        <v-radio-group v-model="dates.typeData" class="ma-3">
          <v-radio label="Ver reporte con datos de prueba" value="00"></v-radio>
          <v-radio
            label="Ver reporte con datos de producción"
            value="01"
          ></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>

    <template v-if="reportModule && isCarsem">
      <v-row class="my-2">
        <v-col cols="12" class="d-flex justify-end">
          <v-btn class="btn-add mr-3" @click="printReport" small>
            <v-icon>mdi-printer</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </template>
    <block v-if="loading"></block>
    <div>
      <v-tabs v-model="tab">
        <v-tab href="#1">Ver reporte general</v-tab>
        <v-tab href="#2">Ver reporte detallado</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab" touchless>
        <v-tab-item value="1">
          <br />
          <date-filter
            @datesSelected="setDates"
            :search="{ typeData: dates.typeData, filterByUserID: userToSearch }"
            class="item"
            @filtered-data="dateFilterHandler"
            :dataToFilter="cashierCut"
            :filterEndpoint="$API.invoices.getCashierCutFilteredByDates"
            :isFilteredByOutsource="false"
          />
          <br /><br /><br />
          <GeneralFilter
            class="backgroundGlobal border-boxGlobal"
            :search="dates"
            :breakline="true"
            :filterEndpoint="$API.invoices.getCashierCutFiltered"
            @emptyFilter="getCashierCut"
            @filtered="filterHandler"
          >
            <v-row class="mt-3 ml-3">
              <v-col cols="6" md="3">
                <v-autocomplete
                  attach
                  clearable
                  item-value="ID_User"
                  item-text="FullName"
                  label="Filtrar por usuario"
                  :items="users"
                  @change="getCashierCutFilteredByUser"
                />
              </v-col>
            </v-row>
            <DxDataGrid
              class="isScrolledByGeneralFilter"
              :data-source="cashierCut"
              @exporting="onExporting"
              :paging="{ enabled: false }"
              style="max-height: 600px"
            >
              <DxExport :enabled="true" />
              <!--DxPaging :page-size="10" /-->
              <DxColumn data-field="InvoiceDate" caption="Fecha"></DxColumn>
              <DxColumn data-field="UserName" caption="Usuario"></DxColumn>
              <DxColumn
                data-field="FullName"
                caption="Nombre de usuario"
              ></DxColumn>
              <DxColumn
                data-field="DescPaymentType"
                caption="Tipo de pago"
              ></DxColumn>
              <!--template #desc-paymenttype="{data}">
                                {{ data.data.DescPaymentType != null ? data.data.DescPaymentType : "-" }}
                            </template-->
              <DxColumn
                data-field="InvoiceStatusDescription"
                caption="Estado"
              ></DxColumn>
              <DxColumn data-field="Total" caption="Total"></DxColumn>
              <DxColumn data-field="Ambiente" caption="Ambiente"></DxColumn>
            </DxDataGrid>
          </GeneralFilter>

          <div class="black--text">
            <v-card
              rounded="lg"
              class="mx-6 mb-10 py-2"
              color="white"
              elevation="5"
            >
              <v-row no-gutters justify="center">
                <v-col cols="12" sm="4" lg="2" class="py-4 px-2">
                  <div class="text-center font-weight-bold">
                    Total Venta:
                  </div>
                  <div class="text-center text-h5 font-weight-light">
                    $ {{ totalContadoGeneral }}
                  </div>
                </v-col>
                <v-col cols="12" sm="4" lg="2" class="py-4 px-2">
                  <div class="text-center font-weight-bold">
                    Total billetes y monedas:
                  </div>
                  <div class="text-center text-h5 font-weight-light">
                    $ {{ totalBillsAndCoinsGeneral }}
                  </div>
                </v-col>
                <v-col cols="12" sm="4" lg="2" class="py-4 px-2">
                  <div class="text-center font-weight-bold">
                    Total tarjeta:
                  </div>
                  <div class="text-center text-h5 font-weight-light">
                    $ {{ totalCardsGeneral }}
                  </div>
                </v-col>
                <v-col cols="12" sm="4" lg="2" class="py-4 px-2">
                  <div class="text-center font-weight-bold">
                    Total cheque:
                  </div>
                  <div class="text-center text-h5 font-weight-light">
                    $ {{ totalCheckGeneral }}
                  </div>
                </v-col>
                <v-col cols="12" sm="4" lg="2" class="py-4 px-2">
                  <div class="text-center font-weight-bold">
                    Total transferencia:
                  </div>
                  <div class="text-center text-h5 font-weight-light">
                    $ {{ totalTransferGeneral }}
                  </div>
                </v-col>
                <v-col cols="12" sm="4" lg="2" class="py-4 px-2">
                  <div class="text-center font-weight-bold">
                    Total otros:
                  </div>
                  <div class="text-center text-h5 font-weight-light">
                    $ {{ totalOthersGeneral }}
                  </div>
                </v-col>
              </v-row>

              <v-row no-gutters justify="center">
                <v-col cols="12" sm="4" lg="4" class="py-4 px-2">
                  <div class="text-center font-weight-bold">
                    Total a crédito:
                  </div>
                  <div class="text-center text-h5 font-weight-light">
                    $ {{ totalCreditoGeneral }}
                  </div>
                </v-col>
                <v-col cols="12" sm="4" lg="4" class="py-4 px-2">
                  <div class="text-center font-weight-bold">Total abonos:</div>
                  <div class="text-center text-h5 font-weight-light">
                    $ {{ totalAbonoGeneral }}
                  </div>
                </v-col>
                <v-col cols="12" sm="4" lg="4" class="py-4 px-2">
                  <div class="text-center font-weight-bold">
                    Total invalidados, notas de crédito y débito:
                  </div>
                  <div class="text-center text-h5 font-weight-light">
                    $ {{ totalInvalidadoGeneral }}
                  </div>
                </v-col>                
              </v-row>
            </v-card>
          </div>
        </v-tab-item>
        <v-tab-item value="2">
          <v-row>
            <v-col cols="12" md="12" class="d-flex justify-end">
              <v-btn
                class="btn-add"
                @click="
                  print2 = {
                    show: true,
                    data: { body: cashierCutDetails, dates: dates2 },
                  }
                "
              >
                <v-icon>mdi-printer</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <date-filter
            @datesSelected="setDates2"
            :search="{ typeData: dates.typeData, filterByUserID: userToSearch }"
            class="item"
            @filtered-data="dateFilterHandler2"
            :dataToFilter="getCashierCutDetails"
            :filterEndpoint="$API.invoices.getCashierCutDetails"
            :isFilteredByOutsource="false"
          />
          <br /><br /><br />
          <GeneralFilter
            class="background border-box"
            :filterEndpoint="$API.invoices.getCashierCutDetails"
            :search="dates2"
            @emptyFilter="getCashierCutDetails"
            @filtered="filterHandler2"
          >
            <v-row class="mt-3 ml-3">
              <v-col cols="6" md="3">
                <v-autocomplete
                  attach
                  clearable
                  item-value="ID_User"
                  item-text="FullName"
                  label="Filtrar por usuario"
                  :items="users"
                  @change="getCashierCutDetailsFilteredByUser"
                />
              </v-col>
            </v-row>
            <DxDataGrid
              :dataSource="cashierCutDetails"
              :showBorders="true"
              ref="dataGrid"
              :scrolling="{ useNative: true }"
              @exporting="onExporting2"
              :paging="{ enabled: false }"
              style="max-height: 600px"
            >
              <DxExport :enabled="true" />
              <DxColumn dataField="InvoiceDate" caption="Fecha" />
              <DxColumn dataField="Total" caption="Total" />
              <DxColumn dataField="ID_Invoice" caption="ID Factura" />
              <DxColumn dataField="InvoiceName" caption="Cliente" />
              <DxColumn
                dataField="CodigoGeneracion"
                caption="Código Generación"
              />
              <DxColumn dataField="NumeroControl" caption="Número de control" />
              <DxColumn dataField="DescPaymentType" caption="Tipo de pago" />
              <DxColumn dataField="InvoiceStatusDescription" caption="Estado" />
              <DxColumn
                :group-index="0"
                data-field="FormattedDate"
                caption="Fecha"
                :calculate-display-value="getCustomCell"
                :sortOrder="'desc'"
              />

              <DxPager
                :visible="true"
                :show-page-size-selector="false"
                :show-info="false"
                :show-navigation-buttons="true"
              />
              <!--DxPaging :page-size="25" /-->
              <DxPager :showPageSizeSelector="true" />
            </DxDataGrid>
          </GeneralFilter>
          <div class="black--text">
            <v-card
              rounded="lg"
              class="mx-6 mb-10 py-2"
              color="white"
              elevation="5"
            >
              <v-row no-gutters justify="center">
                <v-col cols="12" sm="4" lg="2" class="py-4 px-2">
                  <div class="text-center font-weight-bold">
                    Total Venta:
                  </div>
                  <div class="text-center text-h5 font-weight-light">
                    $ {{ totalContado }}
                  </div>
                </v-col>
                <v-col cols="12" sm="4" lg="2" class="py-4 px-2">
                  <div class="text-center font-weight-bold">
                    Total billetes y monedas:
                  </div>
                  <div class="text-center text-h5 font-weight-light">
                    $ {{ totalBillsAndCoins }}
                  </div>
                </v-col>
                <v-col cols="12" sm="4" lg="2" class="py-4 px-2">
                  <div class="text-center font-weight-bold">
                    Total tarjeta:
                  </div>
                  <div class="text-center text-h5 font-weight-light">
                    $ {{ totalCards }}
                  </div>
                </v-col>
                <v-col cols="12" sm="4" lg="2" class="py-4 px-2">
                  <div class="text-center font-weight-bold">
                    Total cheque:
                  </div>
                  <div class="text-center text-h5 font-weight-light">
                    $ {{ totalCheck }}
                  </div>
                </v-col>
                <v-col cols="12" sm="4" lg="2" class="py-4 px-2">
                  <div class="text-center font-weight-bold">
                    Total transferencia:
                  </div>
                  <div class="text-center text-h5 font-weight-light">
                    $ {{ totalTransfer }}
                  </div>
                </v-col>
                <v-col cols="12" sm="4" lg="2" class="py-4 px-2">
                  <div class="text-center font-weight-bold">
                    Total otros:
                  </div>
                  <div class="text-center text-h5 font-weight-light">
                    $ {{ totalOthers }}
                  </div>
                </v-col>
              </v-row>

              <v-row no-gutters justify="center">
                <v-col cols="12" sm="4" lg="3" class="py-4 px-2">
                  <div class="text-center font-weight-bold">
                    Total a crédito:
                  </div>
                  <div class="text-center text-h5 font-weight-light">
                    $ {{ totalCredito }}
                  </div>
                </v-col>
                <v-col cols="12" sm="4" lg="3" class="py-4 px-2">
                  <div class="text-center font-weight-bold">Total abonos:</div>
                  <div class="text-center text-h5 font-weight-light">
                    $ {{ totalAbonos }}
                  </div>
                </v-col>
                <v-col cols="12" sm="4" lg="3" class="py-4 px-2">
                  <div class="text-center font-weight-bold">
                    Total invalidados, notas de crédito y débito:
                  </div>
                  <div class="text-center text-h5 font-weight-light">
                    $ {{ totalInvalidado }}
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </div>
        </v-tab-item>
      </v-tabs-items>
    </div>
    <div>
      <component
        v-for="(item, index) in printList"
        :key="index"
        :is="item.component"
        :dataProps="item.data"
      />
    </div>

    <PrintCashierCutDetails :dataProps="print2"></PrintCashierCutDetails>
  </div>
</template>

<script>
import {
  DxDataGrid,
  DxColumn,
  DxExport,
  DxPager,
} from "devextreme-vue/data-grid";
import GeneralFilter from "@/components/GeneralFilter.vue";
import { printToExcel } from "@/helpers/printToexcel";
import Block from "@/components/Block";
import DateFilter from "@/components/DateFilter";
import PrintCutBox from "@/components/ReportsAndPrintings/AccountigReports/PrintCutBox";
import PrintCashierCutDetails from "../../components/ReportsAndPrintings/PrintCashierCutDetails.vue";
import { numberWithCommas } from "@/helpers/money";

export default {
  props: {
    reportModule: {
      type: Boolean,
      default: false,
    },
    isCarsem: {
      type: Boolean,
      default: false,
    },
  },
  name: "CashierCut",
  components: {
    DxColumn,
    DxDataGrid,
    GeneralFilter,
    Block,
    DxExport,
    DateFilter,
    PrintCutBox,
    DxPager,
    PrintCashierCutDetails,
  },
  data() {
    return {
      loading: false,
      firstLoadTabs: {
        '1': false,
        '2': false,
      },
      cashierCut: [],
      cashierCutDetails: [],
      Currency: JSON.parse(localStorage.getItem("branch")).Currency,
      printList: [],
      dates: {
        start: null,
        end: null,
        typeData: "01",
      },
      dates2: {
        start: null,
        end: null,
        typeData: "01",
      },
      print: {
        show: false,
        header: {},
        data: {
          body: [],
        },
        footer: {},
      },
      print2: {
        show: false,
        header: {},
        data: {
          body: [],
        },
        footer: {},
      },
      tab: null,
      totalContado: 0,
      totalInvalidado: 0,
      totalCredito: 0,
      totalAbonos: 0,
      totalContadoGeneral: 0,
      totalInvalidadoGeneral: 0,
      totalCreditoGeneral: 0,
      totalAbonoGeneral: 0,
      totalBillsAndCoins: 0,
      totalCards: 0,
      totalCheck: 0,
      totalTransfer: 0,
      totalOthers: 0,
      totalBillsAndCoinsGeneral: 0,
      totalCardsGeneral: 0,
      totalCheckGeneral: 0,
      totalTransferGeneral: 0,
      totalOthersGeneral: 0,
      userToSearch: null,
      users: [],
      businessUser: null,
    };
  },
  mounted() {
    const date = new Date();
    this.dates.start =
      date.getFullYear() +
      "-" +
      this.padTo2Digits(date.getMonth() + 1) +
      "-" +
      this.padTo2Digits(date.getDate());
    this.dates.end =
      date.getFullYear() +
      "-" +
      this.padTo2Digits(date.getMonth() + 1) +
      "-" +
      this.padTo2Digits(date.getDate());
    this.dates2.start =
      date.getFullYear() +
      "-" +
      this.padTo2Digits(date.getMonth() + 1) +
      "-" +
      this.padTo2Digits(date.getDate());
    this.dates2.end =
      date.getFullYear() +
      "-" +
      this.padTo2Digits(date.getMonth() + 1) +
      "-" +
      this.padTo2Digits(date.getDate());

    this.loadData(this.tab);
  },
  watch: {
    "dates.typeData"() {
      this.getCashierCut();
      this.dates2.typeData = this.dates.typeData;
    },
    "dates2.typeData"() {
      this.getCashierCutDetails();
    },
    tab(val) {
      this.loadData(val);
    },
  },
  computed: {
    formatDateToDDMMYYYYEnd() {
      if (/^\d{2}\/\d{2}\/\d{4}$/.test(this.dates2.end)) {
        return this.dates2.end;
      }
      const d = new Date(this.dates2.end);
      if (isNaN(d.getTime())) {
        return this.dates2.end;
      }
      const day = d.getDate().toString().padStart(2, "0");
      const month = (d.getMonth() + 1).toString().padStart(2, "0"); // Los meses van de 0 (enero) a 11 (diciembre)
      const year = d.getFullYear();

      return `${day}/${month}/${year}`;
    },
    formatDateToDDMMYYYYStart() {
      if (/^\d{2}\/\d{2}\/\d{4}$/.test(this.dates2.start)) {
        return this.dates2.start;
      }
      const d = new Date(this.dates2.start);
      if (isNaN(d.getTime())) {
        return this.dates2.start;
      }
      const day = d.getDate().toString().padStart(2, "0");
      const month = (d.getMonth() + 1).toString().padStart(2, "0"); // Los meses van de 0 (enero) a 11 (diciembre)
      const year = d.getFullYear();

      return `${day}/${month}/${year}`;
    },
  },
  methods: {
    loadData(tab) {
      if (tab === "1" && !this.firstLoadTabs[tab]) {
        this.getCashierCut();
        this.firstLoadTabs[tab] = true
      } else if (tab === "2" && !this.firstLoadTabs[tab]) {
        this.getCashierCutDetails();
        this.firstLoadTabs[tab] = true
      }
      this.getUsers()
    },
    getUsers() {
      this.businessUser = JSON.parse(localStorage.getItem("user")).business;
      this.$API.users.getUsers(this.businessUser).then((response) => {
        this.users = response.map((user) => {
          return {
            ID_User: user.ID_User,
            FullName: user.FirstName + " " + user.LastName,
          };
        });
      });
    },
    getCashierCutFilteredByUser(ID_User){
      this.$API.invoices.getCashierCutFiltered({
        ...this.dates,
        filterByUserID: ID_User
      }).then((response) => {
        this.userToSearch = ID_User
        this.cashierCut = response;
        this.dates.filterByUserID = ID_User;
        this.calculateTotalsCashierCut();
      });
    },
    getCashierCutDetailsFilteredByUser(ID_User){
      this.$API.invoices.getCashierCutDetails({
        ...this.dates2,
        filterByUserID: ID_User
      }).then((response) => {
        this.userToSearch = ID_User
        this.cashierCutDetails = response;
        this.dates2.filterByUserID = ID_User;
        this.calculateTotalsCashierCutDetails();
      });
    },
    padTo2Digits(num) {
      return num.toString().padStart(2, "0");
    },
    setDates(dates) {      
      dates.typeData = this.dates.typeData;
      dates.filterByUserID = this.userToSearch;
      this.dates = dates;
      this.loading = true;
    },
    setDates2(dates) {
      dates.typeData = this.dates2.typeData;
      dates.filterByUserID = this.userToSearch;
      this.dates2 = dates;
      this.loading = true;
    },
    printReport() {
      this.print.show = true;
      this.print.data.body = this.cashierCut;
      if (isNaN(this.print.footer.tsales)) {
        const currenTcheff = this.print.footer.ting - this.print.footer.vbot;
        this.print.footer = {
          tsales:
            this.Currency +
              " " +
              numberWithCommas(this.print.footer.tsales.toFixed(2)) || 0.0,
          tcredit:
            this.Currency +
            " " +
            numberWithCommas(this.print.footer.tcredit.toFixed(2)),
          tdev:
            this.Currency +
            " " +
            numberWithCommas(this.print.footer.tdev.toFixed(2)),
          tsalesCredit:
            this.Currency +
            " " +
            numberWithCommas(this.print.footer.tsalesCredit.toFixed(2)),
          ting:
            this.Currency +
            " " +
            numberWithCommas(this.print.footer.ting.toFixed(2)),
          vbot:
            this.Currency +
            " " +
            numberWithCommas(this.print.footer.vbot.toFixed(2)),
          abt:
            this.Currency +
            " " +
            numberWithCommas(this.print.footer.abt.toFixed(2)),
          tcheef:
            this.Currency + " " + numberWithCommas(currenTcheff.toFixed(2)),
        };
      }
      this.print.header.title = "Corte de caja";
      this.print.header.cutDate = ` ${this.dates.start} - ${this.dates.end}`;
      this.printList.push({ component: PrintCutBox, data: this.print });
    },
    onExporting(e) {
      printToExcel(e, "Corte de caja");
    },
    setInvoiceType(inv) {
      switch (inv) {
        case "FCF":
          return "CONSUMIDOR FINAL";
        case "CCF":
          return "CRÉDITO FISCAL";
        case "FNS":
          return "SUJETO EXCLUIDO";
        case "FEX":
          return "EXPORTACIÓN";
        case "DNC":
          return "NOTA DE CRÉDITO";
        case "DND":
          return "NOTA DE DÉBITO";
        default:
          return "Tipo de factura desconocido";
      }
    },
    setupData(data) {
      this.print.footer = {
        tsales: 0,
        tcredit: 0,
        tdev: 0,
        tsalesCredit: 0,
        ting: 0,
        vbot: 0,
        abt: 0,
        tcheef: 0,
      };

      return data.map((item) => {
        if (
          item.InvoiceType === "CCF" ||
          item.InvoiceType === "FCF" ||
          item.InvoiceType === "FEX"
        ) {
          this.print.footer.tsales += parseFloat(item.TotalSale);
        }
        if (item.InvoiceType === "FCF" || item.InvoiceType === "DNC") {
          this.print.footer.tsalesCredit += parseFloat(item.TotalSale);
        }

        if (
          (item.InvoiceType === "CCF" ||
            item.InvoiceType === "FCF" ||
            item.InvoiceType === "FEX") &&
          (item.InvoicePaymentType == "Billetes y monedas" ||
            item.InvoicePaymentType == "Cheque")
        ) {
          this.print.footer.ting += parseFloat(item.TotalSale);
        } else if (
          (item.InvoiceType === "CCF" ||
            item.InvoiceType === "FCF" ||
            item.InvoiceType === "FEX") &&
          item.InvoicePaymentType != "Billetes y monedas" &&
          item.InvoicePaymentType != "Cheque"
        ) {
          this.print.footer.vbot += parseFloat(item.TotalSale);
        }

        return {
          ...item,
          InvoiceType: this.setInvoiceType(item.InvoiceType),
          InvoiceTotalGravada:
            this.Currency + " " + item.InvoiceTotalGravada.toFixed(2),
          InvoiceIvaRete1:
            this.Currency + " " + item.InvoiceIvaRete1.toFixed(2),
          InvoiceTotalIva:
            this.Currency + " " + item.InvoiceTotalIva.toFixed(2),
        };
      });
    },
    getCashierCut() {
      this.loading = true;
      this.$API.invoices.getCashierCut(this.dates).then((response) => {
        if (response.length === 0) {
          this.loading = false;
          this.totalContadoGeneral = 0;
          this.totalAbonoGeneral = 0;
          this.totalCreditoGeneral = 0;
          this.totalInvalidadoGeneral = 0;
          this.totalBillsAndCoinsGeneral = 0
          this.totalCardsGeneral = 0
          this.totalCheckGeneral = 0
          this.totalTransferGeneral = 0
          this.totalOthersGeneral = 0
          return (this.cashierCut = []);
        }
        if (this.reportModule) {
          this.cashierCut = this.setupData(response);
          this.calculateTotalsCashierCut();
          this.loading = false;
        } else {
          this.cashierCut = response;
          this.calculateTotalsCashierCut();
          this.loading = false;
        }
      });
    },
    getCashierCutDetails() {
      this.print2 = {
        show: false,
        header: {},
        data: {
          body: [],
        },
        footer: {},
      };

      this.loading = true;
      this.$API.invoices.getCashierCutDetails(this.dates2).then((response) => {
        if (response.length === 0) {
          this.loading = false;
          this.totalContado = 0;
          this.totalAbonos = 0;
          this.totalInvalidado = 0;
          this.totalCredito = 0;
          this.totalBillsAndCoins = 0
          this.totalCards = 0
          this.totalCheck = 0
          this.totalTransfer = 0
          this.totalOthers = 0
          return (this.cashierCutDetails = []);
        } else {
          this.cashierCutDetails = response;
          this.calculateTotalsCashierCutDetails();
          this.loading = false;
        }
      });
    },
    filterHandler(response) {
      if (this.reportModule) {
        this.cashierCut = this.setupData(response);
        this.calculateTotalsCashierCut();
        this.loading = false;
      } else {
        this.cashierCut = response;
        this.calculateTotalsCashierCut();
        this.loading = false;
      }
    },
    filterHandler2(response) {
      this.print2 = {
        show: false,
        header: {},
        data: {
          body: [],
        },
        footer: {},
      };

      this.cashierCutDetails = response;
      this.calculateTotalsCashierCutDetails();
      this.loading = false;
    },
    dateFilterHandler(filteredData) {
      if (this.reportModule) {
        this.cashierCut = this.setupData(filteredData);
        this.calculateTotalsCashierCut();
        this.loading = false;
      } else {
        this.cashierCut = filteredData;
        this.calculateTotalsCashierCut();
        this.loading = false;
      }
    },
    dateFilterHandler2(filteredData) {
      this.print2 = {
        show: false,
        header: {},
        data: {
          body: [],
        },
        footer: {},
      };

      this.cashierCutDetails = filteredData;
      this.calculateTotalsCashierCutDetails();
      this.loading = false;
    },
    getCustomCell(rowData) {
      return ` ${rowData.FormattedDate} - Tipo de pago: ${rowData.DescPaymentType} - Estado: ${rowData.InvoiceStatusDescription} - Usuario: ${rowData.FullName} - Total: ${rowData.TotalGlobal}`;
    },
    onExporting2(e) {
      printToExcel(e, "Reporte corte de caja detallado");
    },
    calculateTotalsCashierCutDetails() {
      this.totalContado = 0;
      this.totalInvalidado = 0;
      this.totalCredito = 0;
      this.totalAbonos = 0;
      this.totalBillsAndCoins = 0
      this.totalCards = 0
      this.totalCheck = 0
      this.totalTransfer = 0
      this.totalOthers = 0

      this.cashierCutDetails.forEach((item) => {
        if (
          (item.ID_Invoice.includes("FCF") ||
            item.ID_Invoice.includes("CCF") ||
            item.ID_Invoice.includes("FEX")) &&
          item.InvoiceStatusDescription == "Cancelada"
        ) {
          this.totalContado += parseFloat(item.Total.replace(/[$,]/g, ""));
        } else if (
          (item.ID_Invoice.includes("FCF") ||
            item.ID_Invoice.includes("CCF") ||
            item.ID_Invoice.includes("FEX") ||
            item.ID_Invoice.includes("RCIB")) &&
          item.InvoiceStatusDescription != "Cancelada" &&
          item.InvoiceStatusDescription != "INVALIDADO" &&
          item.InvoiceStatusDescription != "Crédito"
        ) {
          this.totalAbonos += parseFloat(item.Total.replace(/[$,]/g, ""));
        } else if (
          item.ID_Invoice.includes("FNS") ||
          item.ID_Invoice.includes("DNC") ||
          item.ID_Invoice.includes("DND") ||
          item.InvoiceStatusDescription == "INVALIDADO"
        ) {
          this.totalInvalidado += Math.abs(
            parseFloat(item.Total.replace(/[$,]/g, ""))
          );
        } else if (
          (item.ID_Invoice.includes("FCF") ||
            item.ID_Invoice.includes("CCF") ||
            item.ID_Invoice.includes("FEX") ||
            item.ID_Invoice.includes("RCIB")) &&
          item.InvoiceStatusDescription == "Crédito"
        ) {
          this.totalCredito += parseFloat(item.Total.replace(/[$,]/g, ""));
        } 
        
        if( item.InvoiceStatusDescription != 'INVALIDADO' ) {
          if (item.DescPaymentType == "Billetes y monedas") {
            this.totalBillsAndCoins += parseFloat(item.TotalSale);
          } else if (["Tarjeta Débito", "Tarjeta Crédito"].includes(item.DescPaymentType)) {
            this.totalCards += parseFloat(item.TotalSale);
          } else if (item.DescPaymentType == "Cheque") {
            this.totalCheck += parseFloat(item.TotalSale);
          } else if (item.DescPaymentType == "Transferencia_ Depósito Bancario") {
            this.totalTransfer += parseFloat(item.TotalSale);
          } else {
            this.totalOthers += parseFloat(item.TotalSale);
          }
        }
      });

      this.totalContado = parseFloat(this.totalContado).toFixed(2);
      this.totalAbonos = parseFloat(this.totalAbonos).toFixed(2);
      this.totalInvalidado = parseFloat(this.totalInvalidado).toFixed(2);
      this.totalCredito = parseFloat(this.totalCredito).toFixed(2);
      this.totalBillsAndCoins = parseFloat(this.totalBillsAndCoins).toFixed(2);
      this.totalCards = parseFloat(this.totalCards).toFixed(2);
      this.totalCheck = parseFloat(this.totalCheck).toFixed(2);
      this.totalTransfer = parseFloat(this.totalTransfer).toFixed(2);
      this.totalOthers = parseFloat(this.totalOthers).toFixed(2);
    },
    calculateTotalsCashierCut() {
      this.totalContadoGeneral = 0;
      this.totalAbonoGeneral = 0;
      this.totalInvalidadoGeneral = 0;
      this.totalCreditoGeneral = 0;
      this.totalBillsAndCoinsGeneral = 0
      this.totalCardsGeneral = 0
      this.totalCheckGeneral = 0
      this.totalTransferGeneral = 0
      this.totalOthersGeneral = 0

      this.cashierCut.forEach((item) => {
        if (item.InvoiceStatusDescription == "Cancelada") {
          this.totalContadoGeneral += parseFloat(
            item.Total.replace(/[$,]/g, "")
          );
        } else if (item.InvoiceStatusDescription == "ABONO") {
          this.totalAbonoGeneral += parseFloat(item.Total.replace(/[$,]/g, ""));
        } else if (item.InvoiceStatusDescription == "Crédito") {
          this.totalCreditoGeneral += parseFloat(
            item.Total.replace(/[$,]/g, "")
          );
        } else if (
          item.InvoiceStatusDescription == "INVALIDADO" ||
          item.InvoiceStatusDescription == "NOTA DE CREDITO" ||
          item.InvoiceStatusDescription == "NOTA DE DEBITO"
        ) {
          this.totalInvalidadoGeneral += parseFloat(
            item.Total.replace(/[$,]/g, "")
          );
        }

        if( item.InvoiceStatusDescription != 'INVALIDADO' ) {
          if (item.DescPaymentType == "Billetes y monedas") {
            this.totalBillsAndCoinsGeneral += parseFloat(item.TotalSale);
          } else if (["Tarjeta Débito", "Tarjeta Crédito"].includes(item.DescPaymentType)) {
            this.totalCardsGeneral += parseFloat(item.TotalSale);
          } else if (item.DescPaymentType == "Cheque") {
            this.totalCheckGeneral += parseFloat(item.TotalSale);
          } else if (item.DescPaymentType == "Transferencia_ Depósito Bancario") {
            this.totalTransferGeneral += parseFloat(item.TotalSale);
          } else {
            this.totalOthersGeneral += parseFloat(item.TotalSale);
          }
        }
      });

      this.totalContadoGeneral = parseFloat(this.totalContadoGeneral).toFixed(2);
      this.totalAbonoGeneral = parseFloat(this.totalAbonoGeneral).toFixed(2);
      this.totalInvalidadoGeneral = parseFloat(
        this.totalInvalidadoGeneral
      ).toFixed(2);
      this.totalCreditoGeneral = parseFloat(this.totalCreditoGeneral).toFixed(2);
      this.totalBillsAndCoinsGeneral = parseFloat(this.totalBillsAndCoinsGeneral).toFixed(2); 
      this.totalCardsGeneral = parseFloat(this.totalCardsGeneral).toFixed(2);
      this.totalCheckGeneral = parseFloat(this.totalCheckGeneral).toFixed(2);
      this.totalTransferGeneral = parseFloat(this.totalTransferGeneral).toFixed(2);
      this.totalOthersGeneral = parseFloat(this.totalOthersGeneral).toFixed(2);
    },
  },
};
</script>

<style scoped>
.btn-add {
  background-color: #3498db !important;
  color: white;
}

.item {
  @media screen and (min-width: 1068px) {
    position: absolute;
    z-index: 1;
  }
  @media screen and (max-width: 900px) {
    padding-bottom: 20px;
  }
}
</style>
