<template>
  <div>
    <v-card class="white" flat>
      <v-card-text>

        <v-form v-model="valid">

          <v-row class="ml-2 mr-2">
            <v-col cols="12" class="tabsNh">

              <template v-if="business">
                <v-col cols="12" style="display: flex; justify-content: space-between;">
                  <v-btn small color="error" @click.prevent="confirmDelete">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                  <v-btn small class=" btnsave" @click.prevent="updateBusiness">
                    <v-icon>
                      mdi-content-save
                    </v-icon>
                  </v-btn>
                </v-col>
              </template>
              <v-col cols="12" v-else style="display: flex; justify-content: flex-end;">
                <v-btn small @click.prevent="addBusiness" class=" btnsave">
                  <v-icon>
                    mdi-content-save
                  </v-icon>
                </v-btn>
              </v-col>

            </v-col>

          </v-row>
          <v-row class="ml-2 mr-2" style="margin-top: 2rem;  margin-bottom: 1rem;">
            <v-col cols="12" md="2">

              <div class="box">
                <div style="display: flex; justify-content: center; width: 80%;margin-left: 10%;">
                  <v-img :src="url" class="preview-avatar" style="width: 80%; margin-left: 5%; "></v-img>
                </div>
                <v-file-input @change="Preview_image" v-model="image" label="Logo del negocio" prepend-icon="mdi-camera"
                  placeholder="Seleccionar una imágen" :rules="[maxFileSize]">
                </v-file-input>
              </div>
              <div class="box">
                <v-select outlined dense attach v-if="RoleUser == 'ROLE_ROOT'" :items="businessTypes" item-text="name"
                  item-value="value" label="Tipo de negocio *" v-model="form.type" required
                  :rules="requiredRule"></v-select>
              </div>

            </v-col>

            <v-col cols="12" md="10">
              <v-row>
                <v-col cols="12" sm="12" md="12" class="pb-3" style="margin-top: 1rem;">
                  <h3>Datos Principales</h3>
                </v-col>
                <v-col v-if="!business" cols="12" sm="12" md="12">
                  <label>Nota: los apartados con (*) son datos requeridos</label>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field outlined dense v-model="form.name" label="Nombre del negocio *"
                    placeholder="Escriba el nombre del negocio" required></v-text-field>
                </v-col>

                <v-col cols="12" md="3">
                  <v-text-field outlined dense v-model="form.phone" label="Télefono del negocio*" required @input="maskNum"
                    :rules="requiredRule" placeholder="Escriba el télefono del negocio"></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field outlined dense v-model="form.cellphone" label="Celular del negocio*" required @input="maskNum"
                    :rules="requiredRule" placeholder="Escriba el télefono celular del negocio"></v-text-field>
                </v-col>

                <v-col cols="12" md="3">
                  <v-text-field outlined dense v-model="form.email" label="Correo eléctronico del negocio*" required
                    :rules="requiredRule" placeholder="Escriba el correo eléctronico del negocio"></v-text-field>
                </v-col>
                <v-col cols="12" md="2">
                  <v-text-field outlined dense v-model="form.whatsapp" label="Whatsapp del negocio" @input="maskNum"
                    placeholder="Escriba whatsapp del negocio"></v-text-field>
                </v-col>


                <v-col cols="12" md="3">
                  <v-text-field outlined dense v-model="form.website" label="Sitio web del negocio"
                    placeholder="Escriba el sitio web del negocio"></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field outlined dense v-model="form.address" label="Dirección del negocio *"
                    placeholder="Escriba la dirección del negocio" required :rules="requiredRule"></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-autocomplete outlined dense :items="getcurrency" label="Moneda*" attach v-model="form.currency"
                    item-text="name" item-value="value" required :rules="requiredRule"></v-autocomplete>
                </v-col>

                <v-col cols="12" md="12" class="pb-3" style="margin-top: .6rem;">
                  <h3>Información de facturación</h3>

                </v-col>
                <v-col cols="12" md="2">
                  <v-text-field outlined dense v-model="form.billingplan" label="Plan del negocio*"
                    placeholder="Escriba el plan de facturación del negocio" required
                    :rules="requiredRule"></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field outlined dense v-model="form.billingname" label="Nombre del negocio*"
                    placeholder="Escriba el nombre de facturación del negocio" required
                    :rules="requiredRule"></v-text-field>
                </v-col>
                <v-col cols="12" md="2">
                  <v-text-field outlined dense v-model="form.billingnrc" label="NRC del negocio"
                    placeholder="Escriba el NRC de facturación del negocio*" @keyup="maskNRC"></v-text-field>
                </v-col>
                <v-col cols="12" md="2">
                  <v-text-field outlined dense v-model="form.billingnit" label="NIT del negocio*"
                    placeholder="Escriba el NIT de facturación del negocio" required :rules="requiredRule"
                    @keyup="maskNit"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field outlined dense v-model="form.billingaddress" label="Dirección del negocio*" required
                    :rules="requiredRule"></v-text-field>
                </v-col>


                <v-col cols="12" md="6">
                  <v-autocomplete outlined dense :items="activities" item-text="Valores" required :rules="requiredRule"
                    item-value="Codigo" v-model="form.codactividad" label="Descripción de la actividad económica*"
                    attach></v-autocomplete>
                </v-col>
                <v-col cols="12" md="3">
                  <v-autocomplete outlined dense :items="countryList" attach
                    @change="form.branchdepartment = null; form.branchmunicipality = null; getcurrencybyCountry(); getStates()"
                    item-text="name" v-model="form.country" required :rules="requiredRule" label="Pais*"></v-autocomplete>
                </v-col>
                <v-col cols="12" md="3">
                  <v-autocomplete outlined dense :items="departments" v-if="checkcountry" attach item-text="Valores"
                    item-value="Codigo" v-model="form.branchdepartment" label="Estado/Departamento"></v-autocomplete>
                  <v-autocomplete outlined dense attach v-else :items="stateList" item-text="name"
                    v-model="form.branchdepartment" @change="getCities" label="Estado/Departamento">
                    <template v-slot:no-data>
                      <div class="px-4">{{ noDataLabelStates }}</div>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" md="6">
                  <v-autocomplete outlined dense :items="municipalities" v-if="checkcountry" attach item-text="Valores"
                    item-value="Codigo" :readonly="!form.branchdepartment" v-model="form.branchmunicipality"
                    label="Ciudad"></v-autocomplete>
                  <v-autocomplete outlined dense v-else item-text="name" :items="cityList" attach
                    :readonly="!form.branchdepartment" v-model="form.branchmunicipality" label="Ciudad">
                    <template v-slot:no-data>
                      <div class="px-4">{{ noDataLabelCities }}</div>
                    </template>
                  </v-autocomplete>
                </v-col>
                <!--v-col cols="12" md="3">
                  <v-autocomplete outlined dense item-text="Valores" attach item-value="Codigo" :items="establisment"
                    v-model="form.establishmenttype" label="Tipo establecimiento*" required
                    :rules="requiredRule"></v-autocomplete>
                </v-col-->
                <v-col cols="12" md="6">
                  <v-autocomplete outlined dense attach :items="['MEDIANO', 'PEQUEÑO', 'GRANDE']"
                    v-model="form.taxpayersize" label="TAMAÑO LEGAL DE CONTRIBUYENTE*" required
                    :rules="requiredRule"></v-autocomplete>
                </v-col>
                <v-col cols="12" md="6">
                  <v-autocomplete outlined dense attach :items="tipoIngreso" item-text="Valores" item-value="Codigo"
                    v-model="form.tipoingresos" label="Tipo de Ingreso"></v-autocomplete>
                </v-col>
                <!--v-col cols="12" md="3">
                  <v-text-field outlined dense v-model="form.codestable" required :rules="requiredRule" :maxlength="4"
                    label="Codigo establecimiento* "></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field outlined dense v-model="form.codestablemh" required :rules="requiredRule" :maxlength="4"
                    label="Codigo establecimiento MH*"></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field outlined dense v-model="form.codpuntoventamh" required :rules="requiredRule"
                    :maxlength="4" label="Codigo punto venta MH*"></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field outlined dense v-model="form.codpuntoventa" required :rules="requiredRule" :maxlength="15"
                    label="Codigo punto venta*"></v-text-field>
                </v-col-->

              </v-row>
              <v-card  class="my-1">
                  <v-row class="pa-2 mx-1">
                  <v-col v-if="TipoPlan != 'Ecoanual'" cols="12" md="4">
                    <v-checkbox hide-details v-model="form.posrestaurant" label="POS Modo Restaurante" class="mt-1"></v-checkbox>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-checkbox  hide-details v-model="form.pospayments" label="POS Modo multipago" class="mt-1"></v-checkbox>
                  </v-col>
                  <v-col cols="12" md="4" v-if="TipoPlan != 'Ecoanual' && !hideTicketFormatFCF">
                    <v-checkbox hide-details v-model="form.ticketformatfcf" label="Formato de Ticket para Consumidores Finales y Créditos fiscales" class="mt-1"></v-checkbox>
                  </v-col>
                  <v-col v-if="TipoPlan != 'Ecoanual'" cols="12" md="4">
                    <v-checkbox hide-details v-model="form.dynamicpricelists" label="Listas Dinámicas con Detalles de Facturas" class="mt-1"></v-checkbox>
                  </v-col>
                  <v-col v-if="TipoPlan != 'Ecoanual'" cols="12" md="4">
                    <v-checkbox hide-details v-model="form.posrequiredidseller" label="Vendedor Requerido en Facturas" class="mt-1"></v-checkbox>
                  </v-col>
                  <v-col v-if="TipoPlan != 'Ecoanual'" cols="12" md="4">
                    <v-checkbox hide-details v-model="form.posproductsalternativebehavior" label="POS Funcionamiento Alternativo para Cantidades" class="mt-1"></v-checkbox>
                  </v-col>
                  <v-col v-if="TipoPlan != 'Ecoanual'" cols="12" md="4">
                    <v-checkbox hide-details v-model="form.posshowprincipalbarcode" label="Mostrar código alterno principal en POS" class="mt-1"></v-checkbox>
                  </v-col>
                  <v-col v-if="TipoPlan != 'Ecoanual'" cols="12" md="4">
                    <v-checkbox hide-details v-model="form.isexempt" label="Exento" class="mt-1"></v-checkbox>
                  </v-col>
                  <v-col v-if="TipoPlan != 'Ecoanual'" cols="12" md="4">
                    <v-checkbox hide-details v-model="form.enablezoneconfiguration" label="Configuración de Zonas para Clientes" class="mt-1"></v-checkbox>
                  </v-col>
                  <v-col v-if="TipoPlan != 'Ecoanual'" cols="12" md="4">
                    <v-checkbox hide-details v-model="form.enablenegativeitem" label="Permitir cantidades negativas de articulos" class="mt-1"></v-checkbox>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-checkbox hide-details v-if="$store.state.bussinessType === 'veterinary'" v-model="form.enableviewdoctorpet" label="Historial clinico vista DoctorPet" class="mt-1"></v-checkbox>
                  </v-col>
                  <v-col v-if="TipoPlan != 'Ecoanual'" cols="12" md="4">
                    <v-checkbox hide-details v-model="form.legalagentretain" label="Es agente de retención 1%" class="mt-1"></v-checkbox>
                  </v-col>
                  <v-col v-if="TipoPlan != 'Ecoanual'" cols="12" md="4">
                    <v-checkbox hide-details v-model="form.restivaposexentonosuj" label="Restar IVA en POS de Exentas y No Sujetas" class="mt-1"></v-checkbox>
                  </v-col>
                  <v-col v-if="TipoPlan != 'Ecoanual'" cols="12" md="4">
                    <v-checkbox hide-details v-model="form.isoptical" label="Es óptica" class="mt-1"></v-checkbox>
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-checkbox hide-details v-model="form.showbatchinfopos" label="Mostrar lote y fecha de expiración en facturas" class="mt-1"></v-checkbox>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <alerts v-if="alert.show" v-on:close_alert="closeAlert" v-on:accept_alert="acceptAlert" :properties="alert">
    </alerts>
  </div>
</template>

<script>
import Vue from 'vue'
import Alerts from '@/components/Alerts'
import { mapGetters } from 'vuex'
import Vuex from '../../../../store';
import {  nitMaskHandlingHomologation, nrc as nrcMask,numberSV, applyNitMaskHandlingHomologationOutOfInput, applyDUIMaskOutOfInput } from "@/helpers/masks";
import { validateNITHomologation } from '../../../../helpers/object'

export default {
  name: "ManageBusiness",
  props: ['business', 'win'],
  components: {
    Alerts
  },
  data: () => ({
    currencies: [],
    alert: {
      type: "success",
      show: false,
      header: "",
      body: "",
    },
    valid: false,
    url: null,
    image: null,

    //departments: ['San salvador','Santa Ana', 'Soyapango'],
    //municipalities: ['San salvador','Santa Ana', 'Soyapango','San Miguel'],
    /* activities: [
      {
        Codigo: '01131',
        Valores: 'AGSP-PAPCASC : Cultivo de raíces y tubérculos',
      },
      {
        Codigo: '01191',
        Valores: 'AGSP-PAPCASC : Cultivo de plantas no perennes para la producción de semillas y flores',
      },
      {
        Codigo: '01619',
        Valores: 'AGSP-PAPCASC : Servicios agrícolas ncp'
      },
      {
        Codigo: '62020',
        Valores: 'IC-PICIAC : Consultorías y gestión de servicios informáticos'
      }
    ], */
    /*    establisment: [
         {
           Codigo: '01',
           Valores: 'Sucursal / Agencia',
         },
         {
           Codigo: '02',
           Valores: 'Casa matriz',
         }
       ], */
    form: {},
    RoleUser: JSON.parse(localStorage.getItem("user")).roles[0],
    businessTypes: [
      {
        name: 'Veterinary',
        value: 'veterinary',
      },
      {
        name: 'Laboratory',
        value: 'bloodtestlaboratory',
      },
      {
        name: 'Bill',
        value: 'accounting_and_bill',
      },
      {
        name: 'Medical Clinic',
        value: 'medicalclinic',
      }
    
    ],
    requiredRule: [
      value => !!value || 'El dato es requerido.',
    ],
    stateList: [],
    cityList: [],
    noDataLabelStates: 'Seleccione un país',     
    noDataLabelCities: 'No hay ciudades disponibles',
    validImageSize: true,
    TipoPlan: JSON.parse(localStorage.getItem("user")).TipoPlan,
    PreferentialPlan: JSON.parse(localStorage.getItem("user")).preferentialplan
  }),
  computed: {
    ...mapGetters(['getCatalogMH']),
    departments() {

      return this.getCatalogMH('Departamento')
    },
    municipalities() {
      const municipalities = this.getCatalogMH('Municipio')
      if (this.form.branchdepartment) {

        const department = municipalities.filter(mun => mun.Departamento == this.form.branchdepartment)

        return department
      }
      return []
    },
    hideTicketFormatFCF(){
      return ( this.TipoPlan === 'Emprendedor' && this.PreferentialPlan === 0 ) ||  ( this.TipoPlan === 'Ecoanual' && this.PreferentialPlan === 0 ) || ( this.TipoPlan === 'Basic' && this.PreferentialPlan === 0 )
    },
    establisment() {
      return this.getCatalogMH('TipoEstablecimiento')
    },
    activities() {
      return this.getCatalogMH('ActividadEconomica')
    },
    countryList() {
      return this.$store.getters.getCountries;
    },
    checkcountry() {
      if (this.form.country == 'El Salvador') return true
      else return false
    },
    getcurrency() {
      const countries = this.$store.getters.getCountries;
      const currencies = countries.map(country => {
        return {
          name: country.currency_name,
          value: country.currency_symbol,
          currentcountry: country.name
        }
      })

      return currencies
    },
    tipoIngreso() {
      return this.getCatalogMH('TipoIngreso')
    }

  },
  watch: {
    "form.taxpayersize"() {
      if(this.form.taxpayersize == "GRANDE") {
        this.form.legalagentretain = true
      }
      else {
        if (!this.business.LegalAgentRetain) {
          this.form.legalagentretain = false
        }
      }
    }
  },
  async mounted() {


    //this.getcurrency();
    
    if (this.business) {
      Object.keys(this.business).map(key => {
        let k = key.replace('Business', '');
        Vue.set(this.form, k.toLowerCase(), this.business[key])
      });
      this.getProfileImage();
    }
    
    this.form.billingnrc && (this.form.billingnrc = applyDUIMaskOutOfInput(this.form.billingnrc));
    this.form.billingnit && (this.form.billingnit = applyNitMaskHandlingHomologationOutOfInput(this.form.billingnit));
    if(this.form.country != 'El Salvador'){
      this.getStates()
      this.getCities()
    }
  },
  methods: {
    async getProfileImage() {
      if(!this.business.BusinessLogoPath) return
      //const { imageBase64: logoImageBase64 } = await this.$API.utilities.getImageInBase64({ "file": this.business.BusinessLogoPath })  
      this.url = this.business.BusinessLogoPath;
    },
    async getStates(){
      if(this.form.country == 'El Salvador') return [];
      if(this.form.country){
        this.noDataLabelStates = 'Cargando...'
        this.stateList = []
        
        this.stateList = await this.$API.general.getStates(this.form.country)

        this.noDataLabelStates = 'No hay Estados disponibles';
      }
    },
    async getCities(){
      if(this.form.country == 'El Salvador') return [];
      console.log(this.form.branchdepartment);
      if(this.form.branchdepartment){
        this.noDataLabelCities = 'Cargando...'
        this.cityList = []
        
        this.cityList = await this.$API.general.getCities(this.form.branchdepartment);
        this.noDataLabelCities = 'No hay Ciudades disponibles';
      }
    },
    async maskNRC(e) { 
      if(e.which === 8) return false;
      if(this.form.billingnrc) {
        this.form.billingnrc?.length > 1 && (this.form.billingnrc = await nrcMask(this, this.form.billingnrc));
      }
    },
    async maskNit(e) {
      if(e.which === 8) return false;
      if(this.form.billingnit){
        this.form.billingnit = await nitMaskHandlingHomologation(this, this.form.billingnit);
      }
    },
    async maskNum() {
      if(this.form.phone && this.form.country == 'El Salvador' ){
        this.form.phone = await numberSV(this, this.form.phone);
      } 
      if(this.form.cellphone && this.form.country == 'El Salvador'){
        this.form.cellphone = await numberSV(this, this.form.cellphone);
      }
      if(this.form.whatsapp && this.form.country == 'El Salvador'){
        this.form.whatsapp = await numberSV(this, this.form.whatsapp);
      }

    },
    logout() {
      // this.delete_cookie('details')
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      localStorage.removeItem("customizeOptions");
      localStorage.removeItem("branch");

      Vuex.commit("setToken", null);
      Vuex.commit("setUser", null);
      Vuex.commit("setCustomizeOptions", null);
      Vuex.commit("RESTART_STATE", null);
      localStorage.clear();
      this.$router.push("/login");
    },

    getcurrencybyCountry() {

      this.countryList.forEach(country => {
        if (country.name == this.form.country) {
          this.form.currency = country.currency_symbol
        }
      })

    },
    asignDescription() {
      this.activities.forEach(element => {
        if (element.Codigo == this.form.codactividad) {
          this.form.descactividad = element.Valores
        }
      });
    },
    closeAlert() {
      this.alert.show = false;
      if (this.alert.options == "reload") {
        this.$store.dispatch('reloadData', 'business')
      }
    },
    acceptAlert() {
      this.alert.show = false;
      if (this.alert.options == "Deletebussiness") {
        this.deleteBusiness();
      }
      else if (this.alert.options == "reload") {
        this.$store.dispatch('reloadData', 'business')
      }
      else if (this.alert.options == "newWindow") {
        this.$store.dispatch('deleteWindowByUuid', { uuid: this.win.uuid });
        this.$store.dispatch('reloadData', 'business')
      }
      if (this.alert.options == "closeSesion") {
        this.logout();
      }
    },
    showAlert(type, header, body, options = null) {
      type = type == null ? "danger" : type;
      this.alert.type = type;
      this.alert.header = header;
      this.alert.body = body;
      this.alert.show = true;
      this.alert.options = options != null ? options : null;
    },
    confirmDelete() {
      this.showAlert("question", "Confirmación", "¿Está seguro de eliminar el negocio?", "Deletebussiness");
    },
    Preview_image() {
      this.url = URL.createObjectURL(this.image)
    },
    addBusiness() {
      if (this.checkImageSize()) return;

      if (!this.valid) { this.showAlert("warning", "Error", "Debe completar los campos requeridos"); return; }

      if( !validateNITHomologation( 'form', this, 'billingnit', 'Número de NIT' ) ) {
        return;
      }

      if (this.RoleUser != 'ROLE_ROOT')
        this.form.type = JSON.parse(localStorage.getItem("user")).businessType;

      this.asignDescription();
      let formdata = new FormData();
      this.form.billingnit = this.form.billingnit.replace(/-/g, '');
      this.form.billingnrc = this.form.billingnrc?.replace(/-/g, '');
      formdata.append('logofile', this.image);
      formdata.append('business', JSON.stringify(this.form));
      this.$API.business.addBusiness(formdata)
        .then(() => {
          this.showAlert(
            "success",
            "Ingreso correcto",
            "Negocio registrado correctamente",
          );
          this.$store.dispatch('reloadData', 'business');
          //this.$store.dispatch('addWindow',{'name':'BusinessList',component:'BusinessList',unique:false})

        })
    },
    updateBusiness() {
      if (this.checkImageSize()) return;

      if (!this.valid) { this.showAlert("warning", "Error", "Debe completar los campos requeridos"); return; }

      if( !validateNITHomologation( 'form', this, 'billingnit', 'Número de NIT' ) ) {
        return;
      }

      this.asignDescription();
      let formdata = new FormData();
      this.form.billingnit = this.form.billingnit.replace(/-/g, '');
      this.form.billingnrc = this.form.billingnrc?.replace(/-/g, '');
      if (this.image) formdata.append('logofile', this.image);
      formdata.append('business', JSON.stringify(this.form));

      this.$API.business.updateBusiness(formdata, this.business.ID_Business)
        .then(() => {
          this.showAlert("closeeSesion", "exito ", "Se han modificado datos al negocio.Para que los cambios surtan efecto debe cerrar sesion", 'closeSesion');


          //this.$store.dispatch('addWindow',{'name':'BusinessList',component:'BusinessList',unique:false})

        })
        .catch(() => {
          this.showAlert("danger", "Error", "Error al actualizar los datos", "reload");
        })
    },
    deleteBusiness() {
      this.$API.business.deleteBusiness(this.business.ID_Business)
        .then(() => {
          this.showAlert("success", "Success", "Negocio eliminado correctamente", "newWindow");

        })
        .catch(() => {
          this.showAlert("danger", "Error", "Error al eliminar el negocio", "reload");
        })
    },
    maxFileSize(event) {
      const allowedSize = 2 * 1024 * 1024; // 2 MB in bytes
      const fileSize = event.size;

      if (fileSize > allowedSize) {
        this.validImageSize = false;
        return `El tamaño del archivo debe ser menor a 2 Mb`;
      }

      this.validImageSize = true;
      return true;
    },
    checkImageSize() {
      if (this.validImageSize == false) {
        this.showAlert("danger", "Error", "El tamaño de la imagen debe ser menor a 2 Mb");
        return true;
      }
      return false;
    },
  }
}
</script>

<style scoped>
.btndelete {
  background-color: #E3191E !important;
  color: white;
}

.btnsave {
  background-color: #00A178 !important;
  color: white;
}

.tabs:hover {
  background-color: rgba(80, 80, 80, 1);
  cursor: pointer;
}

.tabs {
  width: 100%;
  padding: 10px;
  background-color: rgba(60, 60, 60, 1);
  color: white !important;
  border-style: solid;
  border-color: black;
  border-width: 1px;
  border-radius: 10px;

}

.tabsNh {
  width: 100%;
  padding: 10px;
  background-color: rgba(60, 60, 60, 1);
  color: white !important;
  border-style: solid;
  border-color: black;
  border-width: 1px;
  border-radius: 10px;

}

.tabsB {
  width: 100%;
  padding: 10px;
  background-color: #1976d2;
  color: white;
  border-radius: 15px;
  border-style: solid;
  border-color: black;
  border-width: 1px;
}

.tabsB:hover {
  background-color: #0d47a1;
  cursor: pointer;
}
</style>
