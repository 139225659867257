import { render, staticRenderFns } from "./PrintPosFacturaRegular.vue?vue&type=template&id=4c1b1efc&scoped=true"
import script from "./PrintPosFacturaRegular.vue?vue&type=script&lang=js"
export * from "./PrintPosFacturaRegular.vue?vue&type=script&lang=js"
import style0 from "./PrintPosFacturaRegular.vue?vue&type=style&index=0&id=4c1b1efc&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c1b1efc",
  null
  
)

export default component.exports