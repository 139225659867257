<template>
        <v-expansion-panel>
            <v-expansion-panel-header class="mt-2">
                AGREGAR NUEVO PRODUCTO
                <template v-slot:actions>
                    <v-btn class="mr-5" @click.native.stop="reloadp++" icon small>
                        <v-icon color="primary">mdi-reload</v-icon>
                    </v-btn>
                    <v-btn @click="$store.dispatch('deleteWindowByUuid', { uuid: win.uuid })" icon small>
                        <v-icon color="error">mdi-close</v-icon>
                    </v-btn>
                </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <v-card>
                    <v-tabs v-if="rolesStatus.findIndex((role) => role.RoleName == 'admin') > -1 ||rolesStatus.findIndex((role) => role.RoleName == 'root') > -1 || rolesStatus.findIndex((role) => role.ID_Role == 43) > -1
" v-model="tab" dark background-color="primary" icons-and-text>
                        <v-tabs-slider color="red"></v-tabs-slider>
                        <v-tab href="#tab-1">
                            AGREGAR NUEVO PRODUCTO
                            <v-icon>mdi-plus</v-icon>
                        </v-tab>
                        <v-tab href="#tab-2">
                            IMPORTAR PRODUCTOS
                            <v-icon>mdi-import</v-icon>
                        </v-tab>
                    </v-tabs>

                    <v-tabs-items v-if="rolesStatus.findIndex((role) => role.RoleName == 'admin') > -1 ||rolesStatus.findIndex((role) => role.RoleName == 'root') > -1 || rolesStatus.findIndex((role) => role.ID_Role == 43) > -1" v-model="tab" touchless>
                        <v-tab-item value="tab-1">
                            <v-card flat>
                                <add-product-tab :key="reloadp" :win="win"></add-product-tab>
                            </v-card>
                        </v-tab-item>
                        <v-tab-item value="tab-2">
                            <ManageProductImport></ManageProductImport>
                        </v-tab-item>
                        <v-tab-item value="tab-3">
                            <v-card flat>
                                <Stock></Stock>
                            </v-card>
                        </v-tab-item>
<!--                        <v-tab-item value="tab-3">-->
<!--                            <v-card flat>-->
<!--                                <ProductHistory></ProductHistory>-->
<!--                            </v-card>-->
<!--                        </v-tab-item>-->
                    </v-tabs-items>
                </v-card>
            </v-expansion-panel-content>
        </v-expansion-panel>
</template>

<script>
    import AddProduct from "../tabs/AddProduct";
    import ManageProductImport from "../tabs/ManageProductImport";
    import Stock from "../tabs/Stock";
    import {mapState} from "vuex";
    // import ProductHistory from "../tabs/ProductHistory";
    export default {
        name: "AddProduct",
        props:['win'],
        data() {
            return {
                panel: 0,
                tab:null,
                reloadp:0
            }
        },
        components: {
            'add-product-tab': AddProduct,
            ManageProductImport,
            Stock,

        },
        computed: {
            ...mapState(
      "rolesUser",
      {
        rolesStatus: (state) => state.roles,
      })
        },
        mounted(){
            this.$store.dispatch('rolesUser/getAllRoles')

        }

    }
</script>

<style scoped>

</style>
