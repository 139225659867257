import axios from "./axios";

export default {
  async findAllAdvancePayments(filters) {
    const options = {
      method: "GET",
      url: "customer-advance-payments",
      headers: { "content-type": "application/json" },
      params: filters,
    };
    try {
      const response = await axios(options);
      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  },

  async findByIdAdvancePayment(id) {
    const options = {
      method: "GET",
      url: `customer-advance-payments/${id}`,
      headers: { "content-type": "application/json" },
    };
    try {
      const response = await axios(options);
      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  },

  async findAdvancePaymentsByCustomerId(customerId) {
    const options = {
      method: "GET",
      url: `customer-advance-payments/customer/${customerId}`,
      headers: { "content-type": "application/json" },
    };
    try {
      const response = await axios(options);
      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  },

  async findCustomerWithAdvancePayments(customerId) {
    const options = {
      method: "GET",
      url: `customer-advance-payments/customer/${customerId}`,
      headers: { "content-type": "application/json" },
    };
    try {
      const response = await axios(options);
      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  },

  async createAdvancePayment(data) {
    const options = {
      method: "POST",
      url: "customer-advance-payments",
      headers: { "content-type": "application/json" },
      data: data,
    };
    try {
      const response = await axios(options);
      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  },

  async updateAdvancePayment(id, data) {
    const options = {
      method: "PUT",
      url: `customer-advance-payments/${id}`,
      headers: { "content-type": "application/json" },
      data: data,
    };
    try {
      const response = await axios(options);
      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  },

  async findAdvancePaymentByCustomerForPos(data) {
    const options = {
      method: "GET",
      url: `customer-advance-payments/findAdvancePaymentByCustomerForPos`,
      headers: { "content-type": "application/json" },
      params: data,
    };
    try {
      const response = await axios(options);
      return response.data;
    } catch (error) {
      throw new Error(error.message);
    }
  },
};
