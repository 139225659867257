import axios from "./axios";
import { userData } from "./Currentuserdata";

export default {
    /**
     * Get All providers
     * @api {Get} manageprovider
     */
    getProviders(data) {
        const options = {
            method: 'GET',
            url: 'manageprovider/provider-list',
            headers: { 'content-type': 'application/json' },
            params: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    getAllpaymentsByInvoice(data) {
        const options = {
            method: 'GET',
            url: 'manageprovider/providerpayment',
            headers: { 'content-type': 'application/json' },
            params: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    getFilterpaymentsByInvoice(data) {
        const options = {
            method: 'GET',
            url: 'manageprovider/providerpayment/filtered',
            headers: { 'content-type': 'application/json' },
            params: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    getProvidersHistory(data) {
        const options = {
            method: 'GET',
            url: 'manageprovider/provider/manage/history',
            headers: { 'content-type': 'application/json' },
            params: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    getOneProvider(id) {
        const user = userData();
        const options = {
            method: "GET",
            url: `manageprovider/${id}`,
            headers: { 'content-type': 'application/json' },
            data: {
                ID_Branch: user.branch,
                ID_Business: user.businessid,
            }
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error.message)
                });
        });
    },

    filterProviders(data) {
        const options = {
            method: 'GET',
            url: 'manageprovider/provider/manage/filtered',
            headers: { 'content-type': 'application/json' },
            params: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    getCheckedProvidersOfProduct(data) {
        const options = {
            method: 'GET',
            url: 'manageprovider/getCheckedProvidersOfProduct',
            headers: { 'content-type': 'application/json' },
            params: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    /**
     * Add provider
     * @api {POST} manageprovider
     */
    addProvider(data) {

        const options = {
            method: 'POST',
            url: 'manageprovider',
            headers: { 'content-type': 'application/json' },
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },


    /**
     * Update provider
     * @api {PUT} manageprovider
     */
    updateProvider(id, data) {
        const options = {
            method: 'PUT',
            url: `manageprovider/${id}`,
            headers: { 'content-type': 'application/json' },
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    logicalDeleteProvider(id) {
        const options = {
            method: "PUT",
            url: `manageprovider/logicaldelete/${id}`,
            headers: { "content-type": "application/json" },
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error.message)
                });
        });
    },

    /**
     * Delete provider
     * @api {DELETE} manageprovider/:id
     */
    deleteProvider(id) {
        const user = userData();
        const options = {
            method: 'DELETE',
            url: `manageprovider/${id}`,
            headers: { 'content-type': 'application/json' },
            data: {
                ID_Branch: user.branch,
                ID_Business: user.businessid
            }
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },

    getAcountsPayable(data) {
        const options = {
            method: 'POST',
            url: `manageprovider/getAcountsPayable`,
            headers: { 'content-type': 'application/json' },
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    getAllPaymentsByPurchase(data) {
        const options = {
            method: "POST",
            url: "manageprovider/getAllPaymentsByPurchase",
            headers: { "content-type": "application/json" },
            data: data,
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error.message)
                });
        });
    },
    getFilterPaymentsByPurchase(data) {
        const options = {
            method: "POST",
            url: "manageprovider/getFilterPaymentsByPurchase",
            headers: { "content-type": "application/json" },
            data: data,
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error.message)
                });
        });
    },
    getPurchasePendingToPay(data) {
        const options = {
            method: "POST",
            url: "manageprovider/getPurchasePendingToPay",
            headers: { "content-type": "application/json" },
            data: data,
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error.message)
                });
        });
    },
    getPurchaseWithCredit(data) {
        const options = {
            method: "POST",
            url: "manageprovider/getPurchaseWithCredit",
            headers: { "content-type": "application/json" },
            data: data,
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error.message)
                });
        });
    },
    getFilterPurchaseWithCredit(data) {
        const options = {
            method: "POST",
            url: "manageprovider/getFilterPurchaseWithCredit",
            headers: { "content-type": "application/json" },
            data: data,
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error.message)
                });
        });
    },
    addPaymentToAccountPayable(data) {
        const options = {
            method: "POST",
            url: "manageprovider/addPaymentToAccountPayable",
            headers: { "content-type": "application/json" },
            data: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error) => {
                    reject(error.message);
                });
        });
    },
    importProviders(data){
        const options = {
          method: "POST",
          url: "manageprovider/importProviders",
          headers: {'content-type': 'multipart/form-data'},
          data: data
        };
        return axios(options)
    },

    getProvidersAllWithAccounts(data) {
        const options = {
            method: 'GET',
            url: 'manageprovider/provider/manage/allWithAccounts',
            headers: {'content-type': 'application/json'},
            params: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    getProvidersFilteredWithAccounts(data) {
        const options = {
            method: 'GET',
            url: 'manageprovider/provider/manage/filteredWithAccounts',
            headers: {'content-type': 'application/json'},
            params: data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    getProvidersFilteredColumnWithAccounts(data) {
        const options = {
            method: 'POST',
            url: 'manageprovider/provider/manage/filteredColumnWithAccounts',
            headers: {'content-type': 'application/json'},
            data
        };
        return new Promise((resolve, reject) => {
            axios(options)
                .then(response => {
                    resolve(response.data);
                })
                .catch(error => {
                    reject(error.message)
                })
        })
    },
    getSpecifiedProvider(id) {    
        const options = {
            method: "GET",
            url: `manageprovider/${id}`,
            headers: {'content-type': 'application/json'},
        };
        return new Promise((resolve, reject) => {
        axios(options)
            .then((response) => {
            resolve(response.data);
            })
            .catch((error) => {
            reject(error.message)
            });
        });
    },
    deleteReceipt(data) {
        const options = {
          method: "POST",
          url: "manageprovider/deleteReceipt",
          headers: { "content-type": "application/json" },
          data: data
        };
        return new Promise((resolve, reject) => {
          axios(options)
            .then((response) => {
              resolve(response.data);
            })
            .catch((error) => {
              reject(error.message);
            });
        });
    },
}
