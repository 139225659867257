<template>
    <window-portal :open="dataProps.show" @close="() => {
        dataProps.show = false
        if(dataProps?.closeCallback){
            dataProps?.closeCallback()
        };
    }
        ">
        <div style="font-family: arial !important; margin-top: 10px; margin-bottom: 10px;">
            <center><div><p style="font-size: 30px;">Vista Previa Reporte de Corte de Caja</p></div></center>
            <br/>
            <center>
                <button class="btnclean" style="font-size: 18px;" @click="Print()">
                    Imprimir
                </button>
                &nbsp;&nbsp;
                <button class="btnclean" style="font-size: 18px;" @click="Download()">
                    Descargar
                </button>
            </center>
            <center><div v-if="loading"><p>Cargando...</p></div></center>
        </div>
        <div style="font-family: arial !important; margin-top: 10px; margin-bottom: 10px;">
            <center v-if="mailLabel">
                {{ mailLabel }}
            </center>
        </div>
        <div class="book" style="margin: 0; padding: 0; background-color: rgb(105, 105, 105); font-family: arial !important; padding-top: 20px; padding-bottom: 20px;">
                <div style="margin: 0 auto; width: 1000px;">
                    <div ref="dataprint" style="margin: 0 !important; padding: 0 !important;">
                        <!--div style="position: relative; width: 1000px; min-height: 1294px; background: white;"-->
                        <div v-for="(page, indexPage) in pages" :key="indexPage" style="position: relative; width: 1000px; min-height: 1293px; background: white;">
                            <hr/>
                            <div class="subpage" style="height: 1250px; padding-top: 20px; overflow: hidden;">
                                <div style="margin-left: 80px; margin-right: 50px;">
                                    <div>
                                        <div style="text-align: center; float: left; font-size: 15px;">
                                            REPORTE DE CORTE DE CAJA
                                        </div>
                                        <div style="text-align: center; float: right; font-size: 15px;">
                                            DEL "{{ formatDateToDDMMYYYYStart }}" AL "{{ formatDateToDDMMYYYYEnd }}"
                                        </div>
                                    </div>
                                    <br>
                                    
                                    <div v-if="hasTypeItem('CONTADO', indexPage)" style="text-align: left; font-size: 14px; margin-top: 20px;">
                                        INGRESOS POR VENTA AL CONTADO
                                    </div>
                                    <table v-if="hasTypeItem('CONTADO', indexPage)" style="font-size: 10px; border-collapse: collapse; width: 100%; margin-top: 10px;">
                                        <tr>
                                            <td style="border-top: 1px solid; border-bottom: 1px solid; padding: 5px;">FECHA</td>
                                            <td style="border-top: 1px solid; border-bottom: 1px solid; padding: 5px;">ID FACTURA</td>
                                            <td style="border-top: 1px solid; border-bottom: 1px solid; padding: 5px; width: 230px;">CLIENTE</td>
                                            <td style="border-top: 1px solid; border-bottom: 1px solid; padding: 5px;">CÓDIGO GENERACIÓN</td>
                                            <td style="border-top: 1px solid; border-bottom: 1px solid; padding: 5px;">TIPO DE PAGO</td>
                                            <td style="border-top: 1px solid; border-bottom: 1px solid; padding: 5px; text-align: right;">VENTA</td>
                                        </tr>
                                        <tr v-for="(item, index) in filterByCategory('CONTADO', valuesRows[indexPage])" :key="index">
                                            <td style="padding: 5px;">{{ item.InvoiceDate }}</td>
                                            <td style="padding: 5px;">{{ item.ID_Invoice }}</td>
                                            <td style="padding: 5px;">{{ item.InvoiceName }}</td>
                                            <td style="padding: 5px;">{{ item.CodigoGeneracion }}</td>
                                            <td style="padding: 5px;">{{ item.DescPaymentType }}</td>
                                            <td style="padding: 5px; text-align: right;">{{ item.Total }}</td>
                                        </tr>
                                    </table>

                                    <div v-if="hasTypeItem('INGRESOS', indexPage)" style="text-align: left; font-size: 14px; margin-top: 20px;">
                                        INGRESOS
                                    </div>
                                    <table v-if="hasTypeItem('INGRESOS', indexPage)" style="font-size: 10px; border-collapse: collapse; width: 100%; margin-top: 10px;">
                                        <tr>
                                            <td style="border-top: 1px solid; border-bottom: 1px solid; padding: 5px;">FECHA</td>
                                            <td style="border-top: 1px solid; border-bottom: 1px solid; padding: 5px;">ID FACTURA</td>
                                            <td style="border-top: 1px solid; border-bottom: 1px solid; padding: 5px;">CLIENTE</td>
                                            <td style="border-top: 1px solid; border-bottom: 1px solid; padding: 5px;">CÓDIGO GENERACIÓN</td>
                                            <td style="border-top: 1px solid; border-bottom: 1px solid; padding: 5px;">TIPO DE PAGO</td>
                                            <td style="border-top: 1px solid; border-bottom: 1px solid; padding: 5px;">DETALLE</td>
                                            <td style="border-top: 1px solid; border-bottom: 1px solid; padding: 5px; text-align: right;">VENTA</td>
                                        </tr>
                                        <tr v-for="(item, index) in filterByCategory('INGRESOS', valuesRows[indexPage])" :key="index">
                                            <td style="padding: 5px;">{{ item.InvoiceDate }}</td>
                                            <td style="padding: 5px;">{{ item.ID_Invoice }}</td>
                                            <td style="padding: 5px;">{{ item.InvoiceName }}</td>
                                            <td style="padding: 5px;">{{ item.CodigoGeneracion }}</td>
                                            <td style="padding: 5px;">{{ item.DescPaymentType }}</td>
                                            <td style="padding: 5px;">{{ item.InvoiceStatusDescription }}</td>
                                            <td style="padding: 5px; text-align: right;">{{ item.Total }}</td>
                                        </tr>
                                    </table>

                                    <div v-if="hasTypeItem('CREDITO', indexPage)" style="text-align: left; font-size: 14px; margin-top: 20px;">
                                        CRÉDITO
                                    </div>
                                    <table v-if="hasTypeItem('CREDITO', indexPage)" style="font-size: 10px; border-collapse: collapse; width: 100%; margin-top: 10px;">
                                        <tr>
                                            <td style="border-top: 1px solid; border-bottom: 1px solid; padding: 5px;">FECHA</td>
                                            <td style="border-top: 1px solid; border-bottom: 1px solid; padding: 5px;">ID FACTURA</td>
                                            <td style="border-top: 1px solid; border-bottom: 1px solid; padding: 5px;">CLIENTE</td>
                                            <td style="border-top: 1px solid; border-bottom: 1px solid; padding: 5px;">CÓDIGO GENERACIÓN</td>
                                            <td style="border-top: 1px solid; border-bottom: 1px solid; padding: 5px;">TIPO DE PAGO</td>
                                            <td style="border-top: 1px solid; border-bottom: 1px solid; padding: 5px;">DETALLE</td>
                                            <td style="border-top: 1px solid; border-bottom: 1px solid; padding: 5px; text-align: right;">VENTA</td>
                                        </tr>
                                        <tr v-for="(item, index) in filterByCategory('CREDITO', valuesRows[indexPage])" :key="index">
                                            <td style="padding: 5px;">{{ item.InvoiceDate }}</td>
                                            <td style="padding: 5px;">{{ item.ID_Invoice }}</td>
                                            <td style="padding: 5px;">{{ item.InvoiceName }}</td>
                                            <td style="padding: 5px;">{{ item.CodigoGeneracion }}</td>
                                            <td style="padding: 5px;">{{ item.DescPaymentType }}</td>
                                            <td style="padding: 5px;">{{ item.InvoiceStatusDescription }}</td>
                                            <td style="padding: 5px; text-align: right;">{{ item.Total }}</td>
                                        </tr>
                                    </table>

                                    <div v-if="hasTypeItem('EGRESOS', indexPage)" style="text-align: left; font-size: 14px; margin-top: 20px;">
                                        EGRESOS
                                    </div>
                                    <table v-if="hasTypeItem('EGRESOS', indexPage)" style="font-size: 10px; border-collapse: collapse; width: 100%; margin-top: 10px;">
                                        <tr>
                                            <td style="border-top: 1px solid; border-bottom: 1px solid; padding: 5px;">FECHA</td>
                                            <td style="border-top: 1px solid; border-bottom: 1px solid; padding: 5px;">ID FACTURA</td>
                                            <td style="border-top: 1px solid; border-bottom: 1px solid; padding: 5px;">CLIENTE</td>
                                            <td style="border-top: 1px solid; border-bottom: 1px solid; padding: 5px;">CÓDIGO GENERACIÓN</td>
                                            <td style="border-top: 1px solid; border-bottom: 1px solid; padding: 5px;">TIPO DE PAGO</td>
                                            <td style="border-top: 1px solid; border-bottom: 1px solid; padding: 5px;">DETALLE</td>
                                            <td style="border-top: 1px solid; border-bottom: 1px solid; padding: 5px; text-align: right;">VENTA</td>
                                        </tr>
                                        <tr v-for="(item, index) in filterByCategory('EGRESOS', valuesRows[indexPage])" :key="index">
                                            <td style="padding: 5px;">{{ item.InvoiceDate }}</td>
                                            <td style="padding: 5px;">{{ item.ID_Invoice }}</td>
                                            <td style="padding: 5px;">{{ item.InvoiceName }}</td>
                                            <td style="padding: 5px;">{{ item.CodigoGeneracion }}</td>
                                            <td style="padding: 5px;">{{ item.DescPaymentType }}</td>
                                            <td style="padding: 5px;">{{ item.InvoiceStatusDescription }}</td>
                                            <td style="padding: 5px; text-align: right;">{{ item.Total }}</td>
                                        </tr>
                                    </table>
                                    <div v-if="hasTypeItem('INVALIDACIONES', indexPage)" style="text-align: left; font-size: 14px; margin-top: 20px;">
                                        INVALIDACIONES
                                    </div>
                                    <table v-if="hasTypeItem('INVALIDACIONES', indexPage)" style="font-size: 10px; border-collapse: collapse; width: 100%; margin-top: 10px;">
                                        <tr>
                                            <td style="border-top: 1px solid; border-bottom: 1px solid; padding: 5px;">FECHA</td>
                                            <td style="border-top: 1px solid; border-bottom: 1px solid; padding: 5px;">ID FACTURA</td>
                                            <td style="border-top: 1px solid; border-bottom: 1px solid; padding: 5px;">CLIENTE</td>
                                            <td style="border-top: 1px solid; border-bottom: 1px solid; padding: 5px;">CÓDIGO GENERACIÓN</td>
                                            <td style="border-top: 1px solid; border-bottom: 1px solid; padding: 5px;">TIPO DE PAGO</td>
                                            <td style="border-top: 1px solid; border-bottom: 1px solid; padding: 5px;">DETALLE</td>
                                            <td style="border-top: 1px solid; border-bottom: 1px solid; padding: 5px; text-align: right;">VENTA</td>
                                        </tr>
                                        <tr v-for="(item, index) in filterByCategory('INVALIDACIONES', valuesRows[indexPage])" :key="index">
                                            <td style="padding: 5px;">{{ item.InvoiceDate }}</td>
                                            <td style="padding: 5px;">{{ item.ID_Invoice }}</td>
                                            <td style="padding: 5px;">{{ item.InvoiceName }}</td>
                                            <td style="padding: 5px;">{{ item.CodigoGeneracion }}</td>
                                            <td style="padding: 5px;">{{ item.DescPaymentType }}</td>
                                            <td style="padding: 5px;">{{ item.InvoiceStatusDescription }}</td>
                                            <td style="padding: 5px; text-align: right;">{{ item.Total }}</td>
                                        </tr>
                                    </table>

                                    <div v-if="indexPage == (numPages - 1)" style="float: right; margin-top: 20px;">
                                        <table style="font-size: 13px;">
                                            <tr>
                                                <td>
                                                    (+)
                                                </td>
                                                <td style="padding-right: 20px;" colspan="2"> Ingresos por venta contado</td>
                                                <td style="text-align: right;">${{ totalBillsAndCoinsGeneral }}</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    
                                                </td>
                                                <td style="padding-right: 20px;"> Billetes y monedas</td>
                                                <td>

                                                </td>
                                                <td style="text-align: right;">${{ totalBillsAndCoinsGeneral }}</td>
                                            </tr>
                                            <br />
                                            <tr>
                                                <td>
                                                    (+)
                                                </td>
                                                <td style="padding-right: 20px;" colspan="2">Total por tarjetas de crédito/débito</td>
                                                <td style="text-align: right;" colspan="2">${{ totalCardsGeneral }}</td>
                                            </tr>
                                            <tr>
                                                <td>

                                                </td>
                                                <td style="padding-right: 20px;">Total de crédito</td>
                                                <td>

                                                </td>
                                                <td style="text-align: right;">${{ totalCreditCard }}</td>
                                            </tr>
                                            <tr>
                                                <td>

                                                </td>
                                                <td style="padding-right: 20px;">Total de débito</td>
                                                <td>

                                                </td>
                                                <td style="text-align: right;">${{ totalDebitCard }}</td>
                                            </tr>
                                            <br />
                                            <tr>
                                                <td>
                                                    (+)
                                                </td>
                                                <td style="padding-right: 20px;" colspan="2">Transferencias Bancarias</td>
                                                <td style="text-align: right;" colspan="2">${{ totalTransferGeneral }}</td>
                                            </tr>
                                            <br />
                                            <tr>
                                                <td>
                                                    (+)
                                                </td>
                                                <td style="padding-right: 20px;" colspan="2">Cheques</td>
                                                <td style="text-align: right;" colspan="2">${{ totalCheckGeneral }}</td>
                                            </tr>
                                            <br />
                                            <tr>
                                                <td>(=)</td>
                                                <td style="padding-right: 20px;" colspan="2">Sub. Total</td>
                                                <td style="text-align: right;" colspan="2">${{ subTotal }}</td>
                                            </tr>
                                            <br/>
                                            <tr>
                                                <td>(-)</td>
                                                <td style="padding-right: 20px;" colspan="2">Egresos</td>
                                                <td style="text-align: right;" colspan="2">${{ totalEgresos }}</td>
                                            </tr>
                                            <br/>
                                            <tr>
                                                <td>(=)</td>
                                                <td style="padding-right: 20px;" colspan="2">Total</td>
                                                <td style="text-align: right;" colspan="2">${{ total }}</td>
                                            </tr>
                                            <tr>
                                                <td>(=)</td>
                                                <td style="padding-right: 20px;" colspan="2">Total al crédito</td>
                                                <td style="text-align: right;" colspan="2">${{ totalCredito }}</td>
                                            </tr>
                                        </table>
                                    </div>
                                    
                                </div>
                                
                                <div style="position: absolute; bottom: 0; width: 100%; left: 80px">
                                    <p style="font-size:10px;">Pagina {{ indexPage+1 }} de {{ numPages }}</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
        </div>
    </window-portal>
</template>

<script>

import jsPDF from "jspdf"
import WindowPortal from "@/components/ReportsAndPrintings/WindowPortal.vue"

export default {
    name: "PrintCashierCutDetails",
    props: {
        dataProps: {
            type: Object,
        },
        hideQr: {
            type: Boolean,
            default: false
        }
    },
    components: {WindowPortal},
    data() {
        return {
            loading: false,
            numPages: 0,
            valuesRows: [],
            subTotal: 0,
            totalIngresos: 0,
            totalCredito: 0,
            totalEgresos: 0,
            total: 0,
            totalInvalidado: 0,
            totalAbonos: 0,
            totalContadoGeneral: 0,
            totalInvalidadoGeneral: 0,
            totalCreditoGeneral: 0,
            totalAbonoGeneral: 0,
            totalBillsAndCoins: 0,
            totalCards: 0,
            totalCheck: 0,
            totalTransfer: 0,
            totalOthers: 0,
            totalBillsAndCoinsGeneral: 0,
            totalCardsGeneral: 0,
            totalCheckGeneral: 0,
            totalTransferGeneral: 0,
            totalOthersGeneral: 0,
            totalDebitCard: 0,
            totalCreditCard: 0
        }
    },
    mounted() {
    },
    computed: {
        pages() {
            // Generar un arreglo con `numPages` elementos
            return Array.from({ length: this.numPages });
        },
        formatDateToDDMMYYYYStart() {
        if (/^\d{2}\/\d{2}\/\d{4}$/.test(this.dataProps.data.dates.start)) {
            return this.dataProps.data.dates.start;
        }
        const d = new Date(this.dataProps.data.dates.start);
        if (isNaN(d.getTime())) {
            return 'Fecha inválida'; 
        }
        const day = d.getDate().toString().padStart(2, '0');
        const month = (d.getMonth() + 1).toString().padStart(2, '0'); // Los meses van de 0 (enero) a 11 (diciembre)
        const year = d.getFullYear();

        return `${day}/${month}/${year}`;
        },
        formatDateToDDMMYYYYEnd() {
        if (/^\d{2}\/\d{2}\/\d{4}$/.test(this.dataProps.data.dates.end)) {
            return this.dataProps.data.dates.end;
        }
        const d = new Date(this.dataProps.data.dates.end);
        if (isNaN(d.getTime())) {
            return 'Fecha inválida'; 
        }
        const day = d.getDate().toString().padStart(2, '0');
        const month = (d.getMonth() + 1).toString().padStart(2, '0'); // Los meses van de 0 (enero) a 11 (diciembre)
        const year = d.getFullYear();

        return `${day}/${month}/${year}`;
        },
    },
    watch: {
        'dataProps.show'() {
            if(this.dataProps.show){
                setTimeout(async () => {
                    await this.calculatePagesOfDoc()
                }, 0);
            }
        }
    },
    methods: {
        calculateTotalsCashierCut(body) {
            try {
                
                this.totalContadoGeneral = 0;
                this.totalAbonoGeneral = 0;
                this.totalInvalidadoGeneral = 0;
                this.totalCreditoGeneral = 0;
                this.totalBillsAndCoinsGeneral = 0
                this.totalCardsGeneral = 0
                this.totalCheckGeneral = 0
                this.totalTransferGeneral = 0
                this.totalOthersGeneral = 0
                this.totalCreditCard = 0;
                this.totalDebitCard = 0;
    
                body.forEach((item) => {
                    if (item.InvoiceStatusDescription == "Cancelada") {
                    this.totalContadoGeneral += parseFloat(
                        item.Total.replace(/[$,]/g, "")
                    );
                    } else if (item.InvoiceStatusDescription == "ABONO") {
                    this.totalAbonoGeneral += parseFloat(item.Total.replace(/[$,]/g, ""));
                    } else if (item.InvoiceStatusDescription == "Crédito") {
                    this.totalCreditoGeneral += parseFloat(
                        item.Total.replace(/[$,]/g, "")
                    );
                    } else if (
                    item.InvoiceStatusDescription == "INVALIDADO" ||
                    item.InvoiceStatusDescription == "NOTA DE CREDITO" ||
                    item.InvoiceStatusDescription == "NOTA DE DEBITO"
                    ) {
                    this.totalInvalidadoGeneral += parseFloat(
                        item.Total.replace(/[$,]/g, "")
                    );
                    }
    
                    if (item.DescPaymentType == "Billetes y monedas") {
                        this.totalBillsAndCoinsGeneral += parseFloat(item.TotalSale);
                    } else if (["Tarjeta Débito", "Tarjeta Crédito"].includes(item.DescPaymentType)) {
                        if( item.DescPaymentType === "Tarjeta Débito" ) {
                            this.totalDebitCard += parseFloat( item.TotalSale );
                        } else {
                            this.totalCreditCard += parseFloat( item.TotalSale );
                        }
                        this.totalCardsGeneral += parseFloat(item.TotalSale);
                    } else if (item.DescPaymentType == "Cheque") {
                        this.totalCheckGeneral += parseFloat(item.TotalSale);
                    } else if (item.DescPaymentType == "Transferencia_ Depósito Bancario") {
                        this.totalTransferGeneral += parseFloat(item.TotalSale);
                    } else {
                        this.totalOthersGeneral += parseFloat(item.TotalSale);
                    }
                });

                this.subTotal = parseFloat( this.totalTransferGeneral + this.totalCardsGeneral + this.totalBillsAndCoinsGeneral + this.totalCheckGeneral ).toFixed( 2 );
                this.totalCreditCard = parseFloat( this.totalCreditCard ).toFixed(2)
                this.totalDebitCard = parseFloat( this.totalDebitCard ).toFixed(2)
                this.totalContadoGeneral = parseFloat(this.totalContadoGeneral).toFixed(2);
                this.totalAbonoGeneral = parseFloat(this.totalAbonoGeneral).toFixed(2);
                this.totalInvalidadoGeneral = parseFloat(this.totalInvalidadoGeneral).toFixed(2);
                this.totalCreditoGeneral = parseFloat(this.totalCreditoGeneral).toFixed(2);
                this.totalBillsAndCoinsGeneral = parseFloat(this.totalBillsAndCoinsGeneral).toFixed(2); 
                this.totalCardsGeneral = parseFloat(this.totalCardsGeneral).toFixed(2);
                this.totalCheckGeneral = parseFloat(this.totalCheckGeneral).toFixed(2);
                this.totalTransferGeneral = parseFloat(this.totalTransferGeneral).toFixed(2);
                this.totalOthersGeneral = parseFloat(this.totalOthersGeneral).toFixed(2);
            } catch (error) {
                console.log( 'calculateTotalsCashierCut ', error.message );
            }
        },
        hasTypeItem(type, indexPage) {
            // Verificar si existe una página en el índice dado y si contiene la categoría deseada
            return this.valuesRows[indexPage] && this.valuesRows[indexPage].some(item => 
                item.Categoria === type
            );
        },
        filterByCategory(type, items) {
            return items.filter(item => item.Categoria === type);
        },
        calculatePagesOfDoc() {
            let pages = 0
            let rows = 0
            const maxRowsPerPage = 39
            this.valuesRows[pages] = []
            this.subTotal = 0
            this.totalIngresos = 0
            this.totalCredito = 0
            this.totalEgresos = 0
            this.total = 0

            try{
                const body = this.dataProps.data.body
                const categories = {
                    "CONTADO": [],
                    "INGRESOS": [],
                    "EGRESOS": [],
                    "INVALIDACIONES": [],
                    "CREDITO": [],
                }

                body.forEach(item => {
                    if ((item.ID_Invoice.includes("FCF") || item.ID_Invoice.includes("CCF") || item.ID_Invoice.includes("FEX"))
                        && item.InvoiceStatusDescription == "Cancelada") {
                            categories["CONTADO"].push(item)
                    } else if ((item.ID_Invoice.includes("FCF") || item.ID_Invoice.includes("CCF") || item.ID_Invoice.includes("FEX") || item.ID_Invoice.includes("RCIB")) 
                        && item.InvoiceStatusDescription != "Cancelada" && item.InvoiceStatusDescription != "INVALIDADO" && item.InvoiceStatusDescription != 'Crédito') {
                            this.totalIngresos += parseFloat(item.Total.replace(/[$,]/g, ''))
                            categories["INGRESOS"].push(item)
                    } else if ((item.ID_Invoice.includes("FCF") || item.ID_Invoice.includes("CCF") || item.ID_Invoice.includes("FEX") || item.ID_Invoice.includes("RCIB")) 
                        && item.InvoiceStatusDescription == 'Crédito') {
                            this.totalCredito += parseFloat(item.Total.replace(/[$,]/g, ''))
                            categories["CREDITO"].push(item)
                    } else if ((item.ID_Invoice.includes("FNS") || item.ID_Invoice.includes("DNC") || item.ID_Invoice.includes("DND"))) {
                        this.totalEgresos += Math.abs(parseFloat(item.Total.replace(/[$,]/g, '')))
                        categories["EGRESOS"].push(item)
                    } else if( item.InvoiceStatusDescription == "INVALIDADO" ) {
                        categories["INVALIDACIONES"].push(item)
                    }
                })

                const processCategory = (group, type) => {
                    for (const item of group) {
                        if (rows >= maxRowsPerPage) {
                            pages++;
                            rows = 0;
                            this.valuesRows[pages] = [];
                        }

                        // Añadir el registro al arreglo de la página actual
                        if (type == "CONTADO") {
                            item.Categoria = "CONTADO"
                        }

                        if (type == "INGRESOS") {
                            item.Categoria = "INGRESOS"
                        }

                        if (type == "EGRESOS") {
                            item.Categoria = "EGRESOS"
                        }

                        if (type == "INVALIDACIONES") {
                            item.Categoria = "INVALIDACIONES"
                        }

                        if (type == "CREDITO") {
                            item.Categoria = "CREDITO"
                        }

                        this.valuesRows[pages].push(item);
                        rows++;
                    }
                }

                processCategory(categories["CONTADO"], "CONTADO");
                processCategory(categories["INGRESOS"], "INGRESOS");
                processCategory(categories["CREDITO"], "CREDITO");
                processCategory(categories["EGRESOS"], "EGRESOS");
                processCategory(categories["INVALIDACIONES"], "INVALIDACIONES");
                
                if( this.valuesRows[ this.valuesRows.length - 1 ].length > 20 ) {
                    pages++;
                    this.valuesRows.push( [] );
                }

                this.calculateTotalsCashierCut( body );

                this.total = parseFloat(parseFloat( this.subTotal )- this.totalEgresos).toFixed(2)
                this.subTotal = parseFloat(this.subTotal).toFixed(2)
                this.totalIngresos = parseFloat(this.totalIngresos).toFixed(2)
                this.totalCredito = parseFloat(this.totalCredito).toFixed(2)
                this.totalEgresos = parseFloat(this.totalEgresos).toFixed(2)
            }
            catch(err) {
                alert(err)
            }
            pages = pages + 1
            this.numPages = pages
        },

        async Print(){
            this.loading = true
            var doc = new jsPDF('p', 'pt', 'letter')
            await doc.html(this.$refs.dataprint, {
                callback: function(doc){
                    doc.output()
                },
                x: 0,
                y: 0,
                margin: [0, 0, 0, 0],
                html2canvas:{ 
                    scale: 0.612,
                    useCORS: true,
                    logging: false,
                    allowTaint: true
                },
            })
            
            //doc.deletePage(2)

            doc.autoPrint({variant: 'non-conform'});
            const blob = doc.output("bloburl")
            window.open(blob)

            this.loading = false
        },

        async Download(){
            this.loading = true
            var doc = new jsPDF('p', 'pt', 'letter')
            await doc.html(this.$refs.dataprint, {
                callback: function(doc){
                    doc.output()
                },
                x: 0,
                y: 0,
                margin: [0, 0, 0, 0],
                html2canvas:{ 
                    scale: 0.612,
                    useCORS: true,
                    logging: false,
                    allowTaint: true
                },
            });
            
            //doc.deletePage(2)
            doc.save("CorteDeCajaDiario.pdf"); 

            this.loading = false
        },
    }
}
</script>

<style scoped>
    .u-row .u-col-100{
        width: 100% !important;
    }
    .btnclean{
        border-radius: 15px;
        border: 1px solid white;
  background-color: #F29D35 !important;
  color: white !important;
}
    .btnclose{
        border-radius: 15px;
        border: 1px solid white;
        background-color: rgb(255, 38, 38) !important;
        color: white !important;
       
    }

    * {
        font-family: Arial, Helvetica, sans-serif !important;
    }

 

</style>
