<template>
  <v-form v-model="valid" class="position-relative">
    <block v-if="loading"></block>
    <v-card flat color="white">
      <v-card-text>
        <v-row class="pa-5">
          <v-col cols="12" class="tabsNh">
           
              <v-col v-if="data.ID_Patient " cols="12"  style="display: flex; justify-content: space-between;">
              <template>
                <v-btn small color="error" dark @click.prevent="confirmDeletePatient()" v-if="$store.getters['rolesUser/getCustomRole'](23)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
                <v-btn v-if="businesstype == 'medicalclinic'" small color="primary" dark 
                  @click="getServicesToBeBilled(); showBillPatient = true">
                  <v-icon class="mr-3">mdi-account-details</v-icon> Facturar paciente
                </v-btn>
                <v-btn v-else small color="primary" dark @click="
                  $store.dispatch('expanedWindow', {uuid: 0 }).then(() => {
                    $store.dispatch('expanedWindow', {uuid: 1})
                    $store.commit('posData/setCustomer', win.customerToShow)
                    $store.commit('posData/changeView','tab-1')
                    $store.commit('posData/setReloaders', 'reloadCreate')
                  })
                  ">
                  Agregar examenes
                </v-btn>
                <v-btn small color="save" dark @click.prevent.stop="updatePatient" v-if="$store.getters['rolesUser/getCustomRole'](22)">
                  <v-icon>mdi-content-save</v-icon>
                </v-btn>
              </template>
              </v-col>
              <v-col v-else cols="12" md="12" style="display: flex;justify-content: flex-end;">
                <v-btn   small  color="save" dark @click.prevent.stop="addPatient" v-if="$store.getters['rolesUser/getCustomRole'](21)">
                  <v-icon>mdi-content-save</v-icon> 

                </v-btn>
              </v-col>
           
          </v-col>
          
          <v-col cols="12" md="2" style="margin-top: 2rem;" >
            <center><v-img :src="url" class="preview-avatar"></v-img></center>
            <br />
            <v-file-input @change="Preview_image" v-model="image" label="Foto paciente" prepend-icon="mdi-camera"
              placeholder="Selecciona la foto del paciente" outlined :rules="[maxFileSize]">
            </v-file-input>
            <div   class="patientdiv" >
              <div style="text-align: left; color: black;" class="ml-2 mt-1" >
              </div>
              
              <div style="text-align: left; color: black;" class="ml-2 mb-2" v-if="customer">
                <b>ID Cliente: </b>
                {{ customer.ID_Customer }}
                <div v-if="customer">
                  <b>Cliente: </b>
                  {{ customer.Is_a_Business ? customer.BusinessName : customer.FirstName + " " + customer.LastName }}
                </div>
                <div v-if="customer">
                  <div class="mt-2" style="text-align: center">
                    <v-btn small class="ma-2" color="primary" dark @click="
                    $store.dispatch('deleteWindowByUuid', { uuid: win.uuid }).then(() => {
                      $store.commit('customerData/changeView', 'tab-1')
                      $store.dispatch('addWindow', {
                        'name': 'customerData',
                        component: 'CustomerData',
                        unique: false,
                        meta: { customerToShow: customer }
                      })
                    })">
                      Ver cliente
                    </v-btn>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div :style="!showInclinic ? ' ' : ''">
              <h3 class="tabs" style="display: flex; justify-content: center" @click="showInclinic = !showInclinic">
                Paciente actualmente en clínica
              </h3>
            </div>
          </v-col>

          <v-col cols="12" sm="10" style="margin-top: 2rem;" >
            <v-row>
              <v-col cols="12" md="9" style="display: flex;" v-if="data.ID_Patient">
                <v-row>
                  <v-col cols="12" class="pb-2">
                    <h4>ID Paciente: </h4>
                    <div style=" margin-left: .3rem; color: black;">
                      <label>{{ data.ID_Patient ?  data.ID_Patient : "" }}</label>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="3">
                 <v-checkbox v-model="form.status" color="primary"
                 :label="form.status ? 'Estado actual: Activo' : 'Estado actual: Inactivo'"></v-checkbox>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field outlined dense v-model="form.firstname" :rules="nameRules" label="Nombre *"
                  placeholder="Ingrese el nombre" required @input="uppercaseInput('firstname')"></v-text-field>
                  
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field outlined dense v-model="form.lastname" :rules="nameRules" label="Apellido *"
                  placeholder="Ingrese el apellido" required @input="uppercaseInput('lastname')"></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-select outlined dense  attach v-model="form.gender" :items="['Femenino', 'Masculino']" label="Sexo"></v-select>
              </v-col>
              <v-col cols="12" md="2">
                <date-picker label="Fecha de nacimiento" @dateSelected="(param) => date = param" v-model="date"
                  :value="date"></date-picker>
              </v-col>
              <v-col cols="12" md="1">
                <v-text-field outlined dense v-model="form.age" label="Edad" placeholder="Ingrese la edad"
                  @keyup="calculateDOBwithAge()"></v-text-field>
              </v-col>
              <v-col cols="12" md="5">
                <v-text-field outlined dense v-model="form.address" label="Dirección"
                  placeholder="Ingrese la dirección"></v-text-field>
              </v-col>
              <v-col cols="12" md="2">
                <v-autocomplete outlined dense   :items="countryList" 
                item-text="name" 
                attach
                @change="getStates"
                v-model="form.country" required :rules="required"
                  label="Pais*"></v-autocomplete>
              </v-col>
            <v-col cols="12" md="3">
                    <v-autocomplete outlined dense  v-if="form.country == 'El Salvador'" :items="departments" v-model="form.state" label="Estado/Departamento"
                    item-text="Valores" item-value="Codigo" attach
                    ></v-autocomplete>
                    <v-autocomplete outlined dense  v-else v-model="form.state" label="Estado/Departamento" @change="getCities" attach
                    :items="stateList" item-text="name" 
                    >
                      <template v-slot:no-data>
                        <div class="px-4">{{ noDataLabelStates }}</div>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="3">
                    <v-autocomplete outlined dense  :items="municipalities" v-if="form.country == 'El Salvador'" :readonly="!form.state"  v-model="form.city" label="Ciudad"
                    item-text="Valores" item-value="Codigo" attach
                     ></v-autocomplete>
                    <v-autocomplete outlined dense  v-else v-model="form.city" label="Ciudad" attach
                    item-text="name"  :items="cityList"  :readonly="!form.state"
                    >
                      <template v-slot:no-data>
                        <div class="px-4">{{ noDataLabelCities }}</div>
                      </template>
                    </v-autocomplete>

                  </v-col>
              <v-col cols="12" md="3">
                <v-text-field outlined dense v-model="form.notificationmobile" label="Celular"
                  placeholder="Ingrese el celular móvil"></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field outlined dense v-model="form.notificationemail" label="Correo eléctronico"
                  placeholder="Ingrese el correo" ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field outlined dense v-model="form.oldid" label="Código alterno"
                  placeholder="Código alterno" ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-checkbox v-model="form.sendemail" label="Enviar correo"></v-checkbox>
              </v-col>
              <v-col cols="12" md="3">
                <v-checkbox v-model="form.sendsms" label="Enviar SMS"></v-checkbox>
              </v-col>
              <v-col cols="12" md="3">
                <v-checkbox v-model="form.sendwhatsapp" label="Enviar Whatsapp"></v-checkbox>
              </v-col>
              <v-col cols="12" md="3">
                <v-checkbox v-model="form.sendpushnotification" label="Enviar push up"></v-checkbox>
              </v-col>

              <v-col cols="12" sm="12">
                <h3  :class="showBgcolorNotes" @click.prevent="showNotes = !showNotes" style="text-align: center;" >Comentarios</h3>
                <v-dialog
                  v-model="showNotes"
                  max-width="60%"
                >
                  <v-card class="pa-4">
                    <div style="margin-top: .3rem;margin-bottom: .3rem; display: flex;justify-content: flex-end;">
                      <v-btn small color="error" dark @click.prevent="showNotes = false" >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </div>
                    <quill-editor :options="quillOptions"
                      v-model="form.comment" @change="onEditorChange($event)"></quill-editor>
                  </v-card>
                </v-dialog>
              </v-col>

            </v-row>
          </v-col>
        </v-row>
        
       


      </v-card-text>

    </v-card>

    <alerts v-if="alert.show" v-on:close_alert="closeAlert" v-on:accept_alert="acceptAlert" :properties="alert">
    </alerts>

    <!-- Dialog bill patient -->
    <v-dialog v-model="showBillPatient" width="90%">
      <v-card class="py-3 px-3">
        <v-card-title class="text-h5">
          Facturar paciente 
        </v-card-title>

        <v-card-actions>
          <v-row>
            <v-col cols="12" class="tabsNh" style="padding: 14px">
              <v-col style="display: flex; justify-content: space-between; min-height: 50px">
                <div>
                </div>
                <div>
                  <v-btn small color="primary" dark @click.prevent="saveServicesToBeBilled()">
                    <v-icon>mdi-content-save</v-icon>
                    Guardar
                  </v-btn>
                </div>

                <div>
                  <v-btn small color="save" dark @click.prevent="billedPatientsOfCustomer()">
                    <v-icon>mdi-cash-register</v-icon>
                    Facturar cliente
                  </v-btn>
                </div>
              </v-col>
            </v-col>
          </v-row>
        </v-card-actions>

        <div style="height: 500px; overflow-y: auto; margin-top: 20px; margin-bottom: 20px; padding: 0 20px;">

          <!-- Services to select -->
          <v-row style="margin-top: 2rem">
            <v-col cols="12">
              <GeneralFilter 
                :filterEndpoint="$API.services.getAllServicesFiltered" 
                :search="search"
                @emptyFilter="getServices" 
                @filtered="servicesFilterHandler"
              >
                <DxDataGrid 
                  class="isScrolledByGeneralFilter dataGridService" 
                  :data-source="servicesToBill"
                  key-expr="CodeService" 
                  :allow-column-reordering="true" 
                  :row-alternation-enabled="true"
                  :show-borders="true"
                  :column-auto-width="true"
                  style="width: fit-content;"
                >
                  <DxScrolling row-rendering-mode="virtual" />
                  <DxPaging :page-size="5" />
                  <DxColumn :width="130" data-field="CodeService" caption="ID"></DxColumn>
                  <DxColumn data-field="NameService" header-cell-template="headerNameService"></DxColumn>
                  <template #headerNameService="{ }">
                    <div>Nombre de<br />servicio</div>
                  </template>
                  <DxColumn data-field="Name" header-cell-template="headerNameLab"></DxColumn>
                  <template #headerNameLab="{ }">
                    <div>Nombre a<br />facturar</div>
                  </template>
                  <DxColumn :width="80" data-field="TypeService" header-cell-template="headerTypeservice">
                  </DxColumn>
                  <template #headerTypeservice="{ }">
                    <div>Tipo de<br />servicio</div>
                  </template>
                  <DxColumn :width="65" data-field="Price" caption="Precio"></DxColumn>
                  <DxColumn :width="45" cell-template="show-template" caption=""></DxColumn>
                  <template #show-template="{ data }">
                    <a href="#" class="mx-1" @click.prevent.stop="addService(data.data)">
                      <v-icon>mdi-plus</v-icon>
                    </a>
                  </template>
                </DxDataGrid>
              </GeneralFilter>
            </v-col>
          </v-row>

          <!-- ServiceToAdd -->
          <v-row v-if="showServicesToAdd" style="margin-top: 2rem">
            <v-col cols="12">
              <h4 style="padding: 16px;">Servicios seleccionados</h4>
              <div style="overflow-x: scroll; padding: 0 12px;">
                <DxDataGrid 
                  class="isScrolledByGeneralFilter dataGridService" 
                  :data-source="servicesToAdd"
                  key-expr="CodeService" 
                  :allow-column-reordering="true" 
                  :row-alternation-enabled="true"
                  :show-borders="true"    
                  :column-auto-width="true"
                  style="width: fit-content;"
                >
                  <DxEditing :allow-updating="true" :select-text-on-edit-start="true" start-edit-action="click" mode="cell" />
                  <DxScrolling row-rendering-mode="virtual" />
                  <DxPaging :page-size="5" />
                  <DxColumn data-field="CodeService" :allow-editing="false" caption="ID"></DxColumn>
                  <DxColumn data-field="NameService" :allow-editing="false" header-cell-template="headerNameService"></DxColumn>
                  <template #headerNameService="{ }">
                    <div>Nombre de<br />servicio</div>
                  </template>
                  <DxColumn data-field="Name" :allow-editing="false" header-cell-template="headerNameLab"></DxColumn>
                  <template #headerNameLab="{ }">
                    <div>Nombre a<br />facturar</div>
                  </template>
                  <DxColumn data-field="TypeService" :allow-editing="false" header-cell-template="headerTypeservice">
                  </DxColumn>
                  <template #headerTypeservice="{ }">
                    <div>Tipo de<br />servicio</div>
                  </template>
                  <DxColumn data-field="quantity" caption="Cantidad">
                    <DxFormat typed="fixedPoint" :precision="0" />
                    <DxRequiredRule message="La cantidad del servicio a facturar es requerida" />
                    <DxPatternRule :pattern="itemQtyPattern"
                      message="La cantidad del servicio facturado solo puede contener numeros enteros mayores a 0" />
                  </DxColumn>
                  <DxColumn data-field="unitPrice" :allow-editing="false" caption="Precio"></DxColumn>
                  <DxColumn cell-template="total-template" :allow-editing="false" caption="Total"></DxColumn>
                  <DxColumn cell-template="show-template" caption=""></DxColumn>
                  <template #show-template="{ data }">
                    <a href="#" class="mx-1" @click.prevent.stop="deleteService(data.data)">
                      <v-icon color="error">mdi-delete</v-icon>
                    </a>
                  </template>
                  <template #total-template="{ data }">
                    <div>
                      {{ totalXQuantity( data.data ) }}
                    </div>
                  </template>
                </DxDataGrid>
              </div>
            </v-col>
          </v-row>

        </div>
      </v-card>
    </v-dialog>
    <!-- Dialog bill patient -->
    <v-dialog v-model="showInclinic" width="60%">
      <div class="white pa-4">
        <div style="
            margin-top: 0.3rem;
            margin-bottom: 0.3rem;
            display: flex;
            justify-content: flex-end;
          ">
          <v-btn small color="error" dark @click.prevent="showInclinic = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <v-row style="margin-top: 0.3rem">
          <v-col cols="12">
            <v-autocomplete outlined dense attach v-model="clinicPatientsForm.VisitData" :items="clinicItems"
              label="Motivo"></v-autocomplete>
          </v-col>
          <v-col cols="12">
            <v-autocomplete outlined dense attach v-model="clinicPatientsForm.AsignedTo" :items="users"
              label="Asignado a"></v-autocomplete>
          </v-col>
          <v-col cols="12">
            <div class="d-flex">
              <h4>Estado:</h4>
              <p style="margin-left: 0.6rem">{{ clinicPatientsForm.Status }}</p>
            </div>
          </v-col>
          <v-col cols="12">
            <div>
              <div style="display: flex; flex-wrap: wrap; justify-content: space-between">
                <div>
                  <input type="radio" id="awaiting" value="Espera" v-model="clinicPatientsForm.Status" />
                  <label class="spacing" for="awaiting"><b>Espera</b></label>
                </div>
                <div>
                  <input type="radio" id="Attending" value="Atendido" v-model="clinicPatientsForm.Status" />
                  <label class="spacing" for="Attending"><b>Atendido</b></label>
                </div>
                <div>
                  <input type="radio" id="Attended" value="Recuperación" v-model="clinicPatientsForm.Status" />
                  <label class="spacing" for="Attended"><b>Recuperación</b></label>
                </div>
                <div>
                  <input type="radio" id="recovering" value="Hospitalizado" v-model="clinicPatientsForm.Status" />
                  <label class="spacing" for="recovering"><b>Hospitalizado</b></label>
                </div>
              </div>
              <div style="display: flex; justify-content: center; flex-wrap: wrap"></div>
            </div>
            <br />
          </v-col>
        </v-row>
        <div class="d-flex" style="justify-content: center">
          <v-btn color="btnsave" dark @click="
            updatePatient();
            createClinicPatient();
            showInclinic = false;
          ">
            Guardar Visita
          </v-btn>
        </div>
      </div>
    </v-dialog>
  </v-form>
</template>

<script>
import GeneralFilter from "@/components/GeneralFilter.vue";
import Vue from "vue";
import Block from "@/components/Block";
import DatePicker from "../../../components/DatePicker";
import Alerts from "../../../components/Alerts";
import { quillConfig } from "../../../helpers/quillconfig";
import { mapGetters } from "vuex";
import { DxDataGrid, DxColumn, DxPaging, DxScrolling, DxEditing, DxFormat, DxPatternRule, DxRequiredRule } from "devextreme-vue/data-grid";
import { numberWithCommas } from "@/helpers/money";

export default {
  name: "ManagePatient",
  components: { DatePicker, Block, Alerts, GeneralFilter, DxDataGrid, DxColumn, DxPaging, DxScrolling, DxEditing, DxFormat, DxPatternRule, DxRequiredRule },
  props: ['data', 'win'],
  data: () => ({
    showInclinic: false,
    showpatientsform: false,
    customer: undefined,
    clinicPatientsForm: {},
    showBgcolorNotes: "tabs",
    quillOptions: quillConfig,
    showNotes: false,
    businessType: '',
    alert: {
      type: null,
      show: false,
      header: "",
      body: "",

    },
    valid: false,
    required: [
      v => !!v || 'Este campo es requerido',
    ],
    nameRules: [
      v => !!v || 'El nombre es requerido',
    ],
    mobileRules: [
      v => !!v || 'El celular es requerido',
    ],
    email: '',
    emailRules: [
      v => !!v || 'El correo eléctronico es requerido',
      v => /.+@.+/.test(v) || 'El correo eléctronico debe de ser valido',
    ],
    date: null,
    menu: false,
    modal: false,
    menu2: false,
    url: null,
    image: null,
    form: {},
    patient: {},
    loading: false,
    typebusiness: '',
    stateList: [],
    cityList: [],
    noDataLabelStates: 'Seleccione un país',     
    noDataLabelCities: 'No hay ciudades disponibles',
    dataCreated: {},
    validImageSize: true,
    businesstype: JSON.parse(localStorage.getItem("user")).businesstype,
    servicesToBill: [],
    servicesToAdd: [],
    showBillPatient: false,
    showServicesToAdd: false,
    search: {
      filter: null
    },
    itemQtyPattern: /^(0*[1-9][0-9]*(\.[0-9]+)?|0+\.[0-9]*[1-9][0-9]*)$/i,
    currency: JSON.parse(localStorage.getItem('branch')).Currency,
    clinicItems: [
      "Consulta",
      "Control",
      "Cirugia",
      "Otro",
    ],
    users: [],
  }),
  watch: {
    date: {
      handler: function () {
        this.form.birthday = this.date
        this.calculateAgeWithDOB();
      },
      immediate: true
    },
    servicesToAdd: {
      deep: true,
      immediate: true,
      handler() {
        if(this.servicesToAdd.length !== 0) {
          this.showServicesToAdd = true;
        }
        else {
          this.showServicesToAdd = false;
        }
      }
    }
  },
    computed:{
    ...mapGetters(['getCatalogMH']),
    departments(){
     
      return  this.getCatalogMH('Departamento')
    },
    municipalities(){
      const municipalities =  this.getCatalogMH('Municipio')
      if(this.form.state){
        const department = municipalities.filter(mun => mun.Departamento == this.form.state)
        return department
      }
      return []
    },
   
    countryList(){
      return this.$store.getters.getCountries;
    },
    checkcountry(){
      if(this.form.country == 'El Salvador') return true
      else return false
    }
  },
  
  async mounted() {
    this.loading = true
    if (this.data) {
      try {
        this.patient = await this.$API.patients.getOne({
          id: this.data["ID_Patient"],
          customer_id: this.data.ID_Customer
        });
      } catch(ex) {
        this.showAlert(
          "danger",
          "Error!",
          `Ha ocurrido el siguiente error al cargar los datos del cliente: ${ex.message}`
        );
        return;
      } finally {
        this.loading = false
      }

      Object.keys(this.patient).map(el => {
        if (el == 'DateOfBirth') {
          //Vue.set(this.form, 'birthday', this.data[el])
          this.date = this.patient[el]
        } else if (el == 'Is_a_Business') {
          Vue.set(this.form, 'isBusiness', this.patient[el])
        } else {
          Vue.set(this.form, el.toLowerCase(), this.patient[el]);
        }
      });

      this.url = this.patient.ProfilePicPath;

      this.form.status == "Activo"  ? this.form.status = "Activo" : this.form.status = false


    }
    else{
      this.data = {}
      this.form.status = "Activo"
    }

    this.getOneCustomer(this.data && this.data.ID_Customer ? this.data.ID_Customer :  this.win.meta.customerToShow.ID_Customer)
    this.typebusiness = JSON.parse(localStorage.getItem("user")).businesstype || ""

    this.getBusinessType();
    this.getCurrentlyInClinic();
    this.id_user_login = JSON.parse(localStorage.getItem("user")).id;
    this.form.idbusiness = JSON.parse(localStorage.getItem("user")).businessid || ""
    this.form.idbranch = JSON.parse(localStorage.getItem("user")).branch || ""

    this.$API.customers.getOneCustomer(this.data && this.data.ID_Customer ? this.data.ID_Customer :  this.win.meta.customerToShow.ID_Customer)
      .then(response => {
        this.win.customerToShow = response
      })
    //Vue.set(this.form, 'customer', this.win.meta.customerToShow.ID_Customer)
    
    this.getServices();
  },
  methods: {
    addService(data) {
      const isRepetitive = this.servicesToAdd.find(
        (service) => service.ID_Service === data.ID_Service
      );

      if (!isRepetitive) {
        this.servicesToAdd.push({ ...data, quantity: 1, UnitPrice: parseFloat(data.Price), unitPrice: `${this.currency}${numberWithCommas(parseFloat(data.Price).toFixed(4))}` });
      } else {
        const services = this.servicesToAdd.find(item => item.ID_Service === data.ID_Service)

        if(services) {
          services.quantity++
        }
      }
    },
    totalXQuantity( { Price, quantity } ) {
      return `${this.currency}${ numberWithCommas( parseFloat( Price * quantity ).toFixed( 2 ) ) }`
    },
    deleteService(data) {
      const serviceIndex = this.servicesToAdd.findIndex(
        (service) => service.CodeService === data.CodeService
      );

      if (serviceIndex !== -1) {
        this.servicesToAdd.splice(serviceIndex, 1);
      }
    },
    servicesFilterHandler(response) {
      this.servicesToBill = response;
    },
    getServices() {
      this.$API.services.getAllServices(this.filterServices).then((response) => {
        this.servicesToBill = response;
      });
    },
    async billedPatientsOfCustomer() {
      this.showBillPatient = false
      this.loading = true

      try{
        await this.$API.veterinarypatients.saveServicesToBeBilled({'customer': this.customer.ID_Customer, 'services': this.servicesToAdd})

        await this.$API.veterinarypatients.billedPatientsOfCustomer({ 'customer': this.customer.ID_Customer})

        this.loading = false

        this.showAlert("success", "Exito", "Se ha realizado la factura de los servicios seleccionados, podrá observarla en el módulo de POS en listo para facturar.")
        await this.getServicesToBeBilled()        
      }
      catch(error) {
        this.loading = false
        this.showAlert("warning", "Advertencia", "Ha ocurrido un error, por favor intentarlo nuevamente.")
      }
    },
    saveServicesToBeBilled() {
      this.showBillPatient = false
      this.loading = true

      this.$API.veterinarypatients.saveServicesToBeBilled({ 'customer': this.customer.ID_Customer, 'services': this.servicesToAdd})
      .then(async (response) => {
        this.loading = false
        this.showAlert("success", "Exito", response.message)
        await this.getServicesToBeBilled()
      })
      .catch(() => {
        this.loading = false
        this.showAlert("warning", "Advertencia", "Ha ocurrido un error, por favor intentarlo nuevamente.")
      })
    },
    getServicesToBeBilled() {
      this.$API.veterinarypatients.getServicesToBeBilled({ 'customer': this.data.ID_Customer })
      .then((response) => {
        this.servicesToAdd = []
        for(let i = 0; i < response.length; i++){
          this.servicesToAdd.push({ ...response[i], unitPrice: `${this.currency}${numberWithCommas(parseFloat(response[i].Price).toFixed(4))}` });
        }
        
      })
    },
    async getStates(){
      if(this.form.country == 'El Salvador') return [];
      if(this.form.country){
        this.noDataLabelStates = 'Cargando...'
        this.stateList = []
        
        this.stateList = await this.$API.general.getStates(this.form.country)

        this.noDataLabelStates = 'No hay Estados disponibles';
      }
    },
    async getCities(){
      if(this.form.country == 'El Salvador') return [];
      if(this.form.state){
        this.noDataLabelCities = 'Cargando...'
        this.cityList = []
        
        this.cityList = await this.$API.general.getCities(this.form.state);
        this.noDataLabelCities = 'No hay Ciudades disponibles';
      }
    },
    uppercaseInput(fieldName) {
      this.form[fieldName] = this.form[fieldName].toUpperCase();
    },
    getBusinessType() {
      let user = JSON.parse(localStorage.getItem("user"))
      if (user && user.businesstype) {
        this.businessType = user.businesstype
      }
    },
    getOneCustomer(id) {
      this.$API.customers.getOneCustomer(id)
        .then(response => {
          this.customer = response;
          this.form.country = this.customer.Country;
          this.getStates();
          this.form.state = this.customer.Department;
          this.getCities();
          this.form.city = this.customer.City;          
        })
    },


    createClinicPatient() {
      this.showUserClinic = false;

      this.clinicPatientsForm.ID_Patient = (this.clinicPatientsForm?.ID_Patient) ? this.clinicPatientsForm.ID_Patient : this.data.ID_Patient
      this.clinicPatientsForm.ID_Customer = (this.clinicPatientsForm?.ID_Customer) ? this.clinicPatientsForm.ID_Customer : this.data.ID_Customer

      this.$API.patientslists
        .registerPatient(this.clinicPatientsForm)
        .then(() => {
          this.showAlert("success", "exito", "Actualmente en clínica actualizada");
          //EventBus.$emit("createPatient");
        })
        .catch(() => {
          this.showAlert(
            "danger",
            "Advertencia",
            "Ocurrio un problema al actualizar la información, por favor contactese con el administrador."
          );
        });
    },
    closeAlert() {
      this.alert.show = false
      if (this.alert.options == 'redirectPatient') {
        this.$store.dispatch('addWindow', {
          name: 'patientData',
          component: 'PatientData',
          unique: false,
          meta: {patient: this.dataCreated   }
      })
        return;
      }

      if (this.alert.options == 'redirectcustomer') {
        this.$store.dispatch('deleteWindowByUuid', { uuid: this.win.uuid }).then(() => {

          this.$store.dispatch('addWindow', {
            'name': 'customerData',
            component: 'CustomerData',
            unique: false,
            meta: { customerToShow: this.win.meta.customerToShow }
          })
          this.$store.commit('customerData/setReloaders','reloadpatientofcustomer')
          this.$store.dispatch('reloadData', 'patients')
        })

      }
      if (this.alert.options == 'redirectpatients') {
        this.loading = false;
        this.$store.dispatch('deleteWindowByUuid', { uuid: this.win.uuid });
        this.$store.commit('customerData/setReloaders','reloadpatientofcustomer')
        this.$store.dispatch('reloadData', 'patients')
      }
    },
    acceptAlert() {
      this.alert.show = false
      if (this.alert.options == "deletepatient") {
        this.deletePatient()
      }
    },
    showAlert(type, header, body, options = null) {
      type = (type == null) ? "danger" : type
      this.alert.type = type
      this.alert.header = header
      this.alert.body = body
      this.alert.show = true
      this.alert.options = (options != null) ? options : null
    },
    confirmDeletePatient() {
      this.showAlert("question", "Eliminar", "¿Seguro que desea eliminar el paciente seleccionado?", "deletepatient")
    },


    Preview_image() {
      this.url = URL.createObjectURL(this.image)
    },

    addPatient() {
      if (this.checkImageSize()) return;

      if(!this.valid) {
        this.showAlert("danger", "Error", "Debe completar todos los campos")
        return
      }

      this.loading = true;
      const formdata = new FormData();
      this.form.customer = this.customer.ID_Customer;
      formdata.append('file', this.image);
      formdata.append('patient', JSON.stringify(this.form));


      this.$API.patients.addPatient(formdata)
        .then((resp) => {
          this.loading = false;
          this.dataCreated = resp;
          this.showAlert("success", "Ingreso correcto", "Datos del paciente ingresados correctamente", "redirectPatient")



        }).catch(() => {
          this.loading = false
          this.showAlert("danger", "Ocurrio un problema", "Ha ocurrido un problema por favor contacte al administrador")
        })
    },

    updatePatient() {
      if (this.checkImageSize()) return;

      if(!this.valid) {
        this.showAlert("warning", "Error", "Debe completar todos los campos")
        return
      }
      this.loading = true;
      const formdata = new FormData();      

      this.form.status ? this.form.status = "Activo" : this.form.status = "Inactivo"

      formdata.append('file', this.image);
      formdata.append('patient', JSON.stringify(this.form));

     this.$API.patients.updatePatient(this.form.id_patient, formdata)
        .then(() => {
          this.loading = false;
          this.$emit('reloadData');
          this.showAlert("success", "Actualización Correcta", "Datos del paciente actualizados correctamente")
          this.$store.dispatch('reloadData', 'patients')
        }).catch(() => {
          this.loading = false
        })
    },

    deletePatient() {
      this.loading = true;
      this.$API.patients.logicalDeletePatient(this.form.id_patient)
        .then(() => {
          this.loading = false;
          this.showAlert("success", "Eliminación Correcta", "Datos del paciente eliminados correctamente.", "redirectpatients")

        }).catch(() => {
          this.loading = false;
          this.showAlert("danger", "Error", "Ocurrio un error al eliminar el paciente seleccionado.");
        })
    },

    onEditorChange(e) {
      this.form.comment = e.html;
      if (this.form.comment != "" ) {
        this.showBgcolorNotes = 'tabsContent'
      }
      else if (this.form.comment == "" ||this.form.comment == "<p><br></p>" ) {
        this.showBgcolorNotes = 'tabs'
      }
      else{
        this.showBgcolorNotes = 'tabs'
 }
    },

    showCustomer(data) {
      this.$store.dispatch('addWindow', {
        'name': 'customerData',
        component: 'CustomerData',
        unique: true,
        meta: { customerToShow: data }
      })
    },

    calculateAgeWithDOB() {
      var dateNow = new Date();

      var date01 = this.form.birthday
      var date02 = dateNow.getDate() + "/" + (dateNow.getMonth() + 1) + "/" + dateNow.getFullYear()
      if(date01 != null && date02 != null){
        var aDate01 = date01.split('/')
        var aDate02 = date02.split('/')

        var fDate01 = Date.UTC(aDate01[2], aDate01[1] - 1, aDate01[0]);
        var fDate02 = Date.UTC(aDate02[2], aDate02[1] - 1, aDate02[0]);

        var dif = fDate02 - fDate01;
        var age = Math.floor((dif / (1000 * 60 * 60 * 24)) / 365);
        this.form.age = age
      }

    },
    calculateDOBwithAge() {
      var dateNow = new Date();
      var years = dateNow.getFullYear();
      years = years - this.form.age;
      var dateDOB = dateNow.getDate() + "/" + (dateNow.getMonth() + 1) + "/" + years;
      this.form.birthday = dateDOB;
      this.date = dateDOB;
    },
    maxFileSize(event) {
      const allowedSize = 2 * 1024 * 1024; // 2 MB in bytes
      const fileSize = event.size;

      if (fileSize > allowedSize) {
        this.validImageSize = false;
        return `El tamaño del archivo debe ser menor a 2 Mb`;
      }

      this.validImageSize = true;
      return true;
    },
    checkImageSize() {
      if (this.validImageSize == false) {
        this.showAlert("danger", "Error", "El tamaño de la imagen debe ser menor a 2 Mb");
        return true;
      }
      return false;
    },
    async getCurrentlyInClinic() {
      await this.$API.users.getUsersByBranch().then(response => {
        this.users.push('')
        for (let data of response) {
          this.users.push(`${data.FirstName} ${data.LastName} (${data.UserName})`)
        }
      })

      await this.$API.patientslists.getCurrentlyInClinicByPatient({ID_Patient: this.data.ID_Patient, ID_Customer: this.data.ID_Customer}).then(response => {
        if (response.length > 0) {
          this.clinicPatientsForm = response[0]
        }
      })
    },
  }
}
</script>

<style scoped>
.patientdiv {
  border: 0.5px solid gray;
  box-shadow: 2px 2px 2px 2px gray;
  border-radius: 3%;
  text-align: center;
}

.spacing {
  margin-left: .6rem;
}

.d-flex {
  display: flex;
}
.tabs:hover {
  background-color: rgba(80, 80, 80, 1);
  cursor: pointer;
}

.tabs:hover {
  background-color: rgba(80, 80, 80, 1);
  cursor: pointer;
}
.tabsContent:hover {
  background-color:  #FFB266;
  cursor: pointer;
}

.tabs{
    width: 100%;
    padding: 10px;
    background-color: rgba(60, 60, 60, 1);
    color: white !important;
    border-style: solid;
    border-color: black;
    border-width: 1px;
    border-radius: 10px;
    
}

.tabsNh{
    width: 100%;
    padding: 10px;
    background-color: rgba(60, 60, 60, 1);
    color: white !important;
    border-style: solid;
    border-color: black;
    border-width: 1px;
    border-radius: 10px;
    
}
.tabsContent{
    width: 100%;
    padding: 10px;
    background-color: #F29D35;
    color: white !important;
    border-style: solid;
    border-color: black;
    border-width: 1px;
    border-radius: 10px;
}
.box {
  background-color: white;
  padding: 0.5rem;
  border-radius: 15px;
  margin: 0.5rem;
}
.tabsw {
  padding: 10px;

  border-radius: 5px;
}
.dataGridService {
  min-width: 500px !important;
}
</style>

<style>
  .left-align {
    text-align-last: start !important;
  }
</style>
