import auth from "./auth";
import customers from "./customers";
import customerAdvancePayment from "./customerAdvancePayment.js";
import users from "./users";
import sellers from "./sellers";
import email from "./email";
import patients from "./patients";
import providers from "./providers";
import invoices from "./invoices";
import products from "./products.js"
import business from "./business";
import branches from "./branches";
import warehouse from "./warehouse";
import orders from "./orders";
import roles from "./roles";
import services from "./services";
import general from "./general";
import priceList from "./priceList";
import laboratory from "./laboratory";
import dashboard from "./dashboard";
import veterinarypatients from "./veterinarypatients";
import veterinarypatientgrooming from "./veterinarypatientgrooming";
import veterinarylaboratorytest from "./veterinarylaboratorytest";
import veterinaryprescription from "./veterinaryprescription";
import thirdapi from "./thirdapi";
import vaccines from "./vaccines";
import VeterinaryPatientVaccinePlan from "./veterinarypatientvaccine";
import medication from './medication';
import itemtypes from "./itemtypes";
import veterinarymedication from './veterinarymedication';
import surgery from './surgery';
import veterinaryImages from './veterinaryimages';
import veterinaryReproduction from './veterinaryreproduction';
import veterinarymedicalhistory from "./veterinarymedicalhistory";
import HF from "./header_footer";
import TM from "./template_module";
import notification from "./notification";
import remindersemailschedule from "./remindersemailschedule";
import vetLaboratory from "./vetLaboratory";
import token from "./token";
import documentTypes from "./documentTypes";
import catalogtypedocument from "./catalogtypedocument";
import veterinaryCalendar from "./veterinarycalendar";
import inventoryTransfer from "./inventoryTransfer";
import sysParams from "./sysParams";
import inventoryitemsbarcodes from "./inventoryitemsbarcodes";
import accountingperiodbatch from "./accountingperiodbatch.js";
import accountingperiod from "./accountingperiod.js";

//prescription
import medicalsurgery from "./medicalsurgery";
import medicalprescription from "./medicalprescription";
import medicalmedication from "./medicalmedication";
import medicalhistory from "./medicalhistory";
import medicalimages from "./medicalimages";
import medicalcalendar from "./medicalcalendar";
import inventoryReports from "./inventoryReports";
import branchaccountcatalog from "./branchaccountcatalog.js";
import accountingexpenses from "./accountingexpenses";
import accountingexpensesentry from "./accountingexpensesentry";
import accountingexpensesentrydetail from "./accountingexpensesentrydetail";
import accountlevel from "./accountlevel.js";
import accountingentrydetailtype from "./accountingentrydetailtype.js";
import accountingentryheader from "./accountingentryheader.js";
import accountingreports from "./accountingreports.js";
import branchaccountconfig from "./branchaccountconfig.js";
import utilities from "./utilities.js";
import medicalreports from "./medicalreports.js";
import veterinarypatientslists from "./veterinarypatientslists.js";
import patientslists from "./patientslists.js";
import veterinarypatientshospitalization from "./veterinarypatientshospitalization.js";
import medicalpatientshospitalization from "./medicalpatientshospitalization.js";

export default {
    vetLaboratory,
    auth,
    customers,
    customerAdvancePayment,
    users,
    sellers,
    patients,
    invoices,
    providers,
    products,
    business,
    branches,
    warehouse,
    orders,
    roles,
    services,
    general,
    priceList,
    laboratory,
    dashboard,
    veterinarypatients,
    veterinarypatientgrooming,
    veterinarylaboratorytest,
    veterinaryprescription,
    email,
    thirdapi,
    vaccines,
    VeterinaryPatientVaccinePlan,
    medication,
    veterinarymedication,
    surgery,
    veterinaryImages,
    veterinaryReproduction,
    veterinarymedicalhistory,
    HF,
    TM,
    notification,
    remindersemailschedule,
    itemtypes,
    token,
    documentTypes,
    catalogtypedocument,
    veterinaryCalendar,
    inventoryTransfer,
    inventoryitemsbarcodes,
    medicalsurgery,
    medicalprescription,
    medicalmedication,
    medicalhistory,
    medicalimages,
    medicalcalendar,
    inventoryReports,
    sysParams,
    branchaccountcatalog,
    accountingexpenses,
    accountlevel,
    accountingentrydetailtype,
    accountingentryheader,
    accountingreports,
    branchaccountconfig,
    utilities,
    accountingexpensesentry,
    accountingexpensesentrydetail,
    medicalreports,
    veterinarypatientslists,
    accountingperiodbatch,
    veterinarypatientshospitalization,
    medicalpatientshospitalization,
    patientslists,
    accountingperiod
}
