<template>
	<window-portal :open="data.show" @close="data.show = false">
		<!-- Header Preview -->
		<div style="font-family: arial !important; position: fixed; min-height: auto; top: 0; left: 0; right: 0; background-color: white; z-index: 100; padding: 0 0 15px 0; box-shadow: 0px 7px 5px 0px rgba(0, 0, 0, 0.3);">
			<center>
				<p style="font-size: 24px; font-weight: 500; margin-bottom: 10px">Vista Previa de Impresión de Prescripción</p>
				<div v-if="roleuser == 'ROLE_ADMIN' || roleuser == 'ROLE_ROOT'">
					<p style="font-size: 15px; line-height: 0.8;">Definir valores de márgenes y alturas de Vista Previa (todos en milímetros)</p>
					<form style="">
						<div style="display: flex; gap: 11px; padding: 0 20%;">
							<div style="display: flex; flex-direction: column; gap: 3px; width: 13%;">
								<label for="topMargin" style="font-size: 11px; text-align: left;">Superior</label>
								<input
								style="height: 30px; text-align: center;"
								id="topMargin"
								type="number"
								v-model="topMargin"
								/>
							</div>
							<div style="display: flex; flex-direction: column; gap: 3px; width: 13%;">
								<label for="bottomMargin" style="font-size: 11px; text-align: left;">Inferior</label>
								<input
								style="height: 30px; text-align: center;"
								id="bottomMargin"
								type="number"
								v-model="bottomMargin"
								/>
							</div>
							<div style="display: flex; flex-direction: column; gap: 3px; width: 13%;">
								<label for="rightMargin" style="font-size: 11px; text-align: left;">Derecho</label>
								<input
								style="height: 30px; text-align: center;"
								id="rightMargin"
								type="number"
								v-model="rightMargin"
								/>
							</div>
							<div style="display: flex; flex-direction: column; gap: 3px; width: 13%;">
								<label for="leftMargin" style="font-size: 11px; text-align: left;">Izquierdo</label>
								<input
								style="height: 30px; text-align: center;"
								id="leftMargin"
								type="number"
								v-model="leftMargin"
								/>
							</div>
							<div style="display: flex; flex-direction: column; gap: 3px; width: 13%;">
								<label for="headerHeight" style="font-size: 11px; text-align: left;">Encabezado</label>
								<input
								style="height: 30px; text-align: center;"
								id="headerHeight"
								type="number"
								v-model="headerHeight"
								/>
							</div>
							<div style="display: flex; flex-direction: column; gap: 3px; width: 13%;">
								<label for="contentHeight" style="font-size: 11px; text-align: left;">Contenido</label>
								<input
								style="height: 30px; text-align: center;"
								id="contentHeight"
								type="number"
								step="0.01"
								v-model="contentHeight"
								readonly
								disabled
								/>
							</div>
							<div style="display: flex; flex-direction: column; gap: 3px; width: 13%;">
								<label for="fontSize" style="font-size: 11px; text-align: left;">Letra (px)</label>
								<input
									style="height: 30px; text-align: center;"
									id="fontSize"
									type="number"
									v-model="fontSize"
									min="10" max="20" step="1"
								/>
							</div>
						</div>
					</form>
				</div>
					<button style="font-size: 16px; padding: 5px 15px; border: 1px solid grey; border-radius: 15px; background-color: #F29D35 !important; color: white !important; font-weight: 600; cursor: pointer;" @click="Print">
						Imprimir
					</button>

				<div v-if="loadingPrint"><p>Cargando impresión...</p></div>
			</center>
		</div>
		<!-- Preview Content -->
		<div :style="{ backgroundColor: 'rgb(105, 105, 105)', minHeight: '90vh', fontFamily: 'arial', padding: roleuser !== 'ROLE_ADMIN' && roleuser !== 'ROLE_ROOT' ? '125px 0' : '215px 0 125px 0'}">
			<div v-if="loading" style="text-align: center; padding: 150px;">
				<p style="color: white; font-size: 20px;">Cargando prescripción...</p>
			</div>
			<div v-else style="margin: 0 auto;">
				<div ref="dataprint" style="margin: 0; padding: 0">    
					<div 
						v-for="(page, indexPage) in pagesRender" 
						:key="indexPage" 
						id="page" 
						:style="{ boxSizing: 'border-box', width: '215.9mm', height: `${pageHeight}mm`, background: 'white', margin: '0 auto 20px auto', padding: `${topMargin}mm ${rightMargin}mm ${bottomMargin}mm ${leftMargin}mm` }"
					>
						<!-- Header Preview -->
						<div 
							id="header" 
							:style="{ height: `${headerHeight}mm`, margin: '0 auto', backgroundImage: headerImage ? `url(${headerImage})` : 'none', 
									backgroundSize: 'cover', backgroundPosition: '60% 0%' }"
						></div>
						<hr v-if="keyMaster === 'fa6646a6d6ef0c5f347a240270f49f38'" style="height:2.5px; color: black; background-color:black" />
						<!-- Content Preview -->
						<div id="content" :style="{height: `${contentHeight}mm`}">
							<table :style="{ fontSize: '13px', borderCollapse: 'collapse', width: '100%', height: '15mm', marginBottom: '3.97mm' }">
								<tr>
									<td style="padding-right: 20px">
										<span style="font-weight: 600;">Fecha: </span> 
										{{ data.data.prescription.prescriptiondate }}
									</td>
									<td style="padding-right: 20px">
										<span style="font-weight: 600;">ID de Paciente: </span> 
										{{ data.data.patientinfo.id_patient }}
									</td>
									<td style="padding-right: 20px">
										
										<span style="font-weight: 600;">
										{{ data.data.customer.Is_a_Business ? 'Empresa: ' : 'Dueño: ' }}
									</span>
										{{ data.data.customer.Is_a_Business 
											? data.data.customer.BusinessName 
											: `${data.data.customer.FirstName} ${data.data.customer.LastName}` }}
									</td>
								</tr>
								<tr>
									<td style="padding-right: 20px">
										<span style="font-weight: 600;">Nombre del paciente: </span> 
										{{ data.data.patientinfo.namepatient }}
									</td>
									<td style="padding-right: 20px">
										<span style="font-weight: 600;">Especie: </span> 
										{{ data.data.patientinfo.species }}
									</td>
									<td style="padding-right: 20px">
										<span style="font-weight: 600;">Raza: </span> 
										{{ data.data.patientinfo.breed }}
									</td>
								</tr>
								<tr>
									<td>
										<span style="font-weight: 600;">Próxima cita: </span>
										{{ data.data.nextappointment.length > 0 
											? data.data.prescription.nextappointment : ""}}
									</td>
								</tr>
							</table>

							<hr v-if="keyMaster === 'fa6646a6d6ef0c5f347a240270f49f38'" style="height:2.5px; color: black; background-color:black" />

							<div
								v-html="page" 
								media="all"
								:style="{ height: `${subpageHeight}mm` }" 
							></div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</window-portal>
</template>

<script>
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import WindowPortal from "@/components/ReportsAndPrintings/WindowPortal.vue"
export default {
	name: "PrescriptionShort",
	props: ['data'],
	components: {WindowPortal},
	data() {
		return {
			footer: null,
			headerHtml: null,
			headerImage: null,
			ID_Branch: JSON.parse(localStorage.getItem("user")).branch,
			ID_Business: JSON.parse(localStorage.getItem("user")).businessid,
			loading: false,
			loadingPrint: false,
			pagesRender: [],
			roleuser: JSON.parse(localStorage.getItem("user")).roles[0],

			bottomMargin: JSON.parse(localStorage.getItem('user')).prescriptionBottomMargin || 0,
			fontSize: JSON.parse(localStorage.getItem('user')).prescriptionFontSize,
			headerHeight: JSON.parse(localStorage.getItem('user')).prescriptionHeaderHeight,
			leftMargin: JSON.parse(localStorage.getItem('user')).prescriptionLeftMargin || 0,
			pageHeight: 139.7,
			rightMargin: JSON.parse(localStorage.getItem('user')).prescriptionRightMargin || 0,
			topMargin: JSON.parse(localStorage.getItem('user')).prescriptionTopMargin || 0,
			keyMaster: JSON.parse(localStorage.getItem("user")).keymaster,
		}
	},
	computed: {
		contentHeight() {
			return (((((this.pageHeight - this.headerHeight) - 0.7) - this.topMargin) - this.bottomMargin) - 1.33).toFixed(2)
		},
		subpageHeight() {
			return (this.contentHeight - 28).toFixed(2)
		},
	},
	mounted() {
		this.pagesRender = [];
		this.loadData();
	},
	watch: {
		data: {
			handler() {
				this.loading = true;
				this.pagesRender = [];
				this.calculatePages().then(() => {
					this.loading = false;
				})
				.catch(() => {
					this.loading = false;
				})
			},
			deep: true,
		},
		bottomMargin: 'calculatePages',
		fontSize: 'calculatePages',
		headerHeight: 'calculatePages',
		leftMargin: 'calculatePages',
		rightMargin: 'calculatePages',
		topMargin: 'calculatePages',
	},
	methods: {
		async loadData() {
			this.loading = true;
			this.pagesRender = [];
			try {
				await this.getHeaderFooter();
				//this.calculatePages();
			} catch (error) {
				console.error("Error loading data:", error);
			} finally {
				this.loading = false;
			}
		},
		async getHeaderFooter() {
			const search = {
				ID_business: this.ID_Business,
				ID_branch: this.ID_Branch,
			};
			const data = await this.$API.HF.getHF(search);
			const header = data.find(x => x.State == '1' && x.Type == 'HeaderPrescription');
			const footer = data.find(x => x.State == '1' && x.Type == 'FooterPrescription');

			this.headerHtml = header ? JSON.parse(header.Body).html.replace(/(\n)/gm, '') : '';
			this.footer = footer ? JSON.parse(footer.Body).html : '';

			await this.createHeaderImage();
		},
		async createHeaderImage() {
			if (!this.headerHtml) {
				this.headerImage = '';
				return;
			}

			const tempDiv = document.createElement('div');
			tempDiv.innerHTML = this.headerHtml;

			tempDiv.style.margin = '0';
			tempDiv.style.padding = '0';
			tempDiv.style.width = 'auto';
			tempDiv.style.display = 'inline-block';

			document.body.appendChild(tempDiv);

			const canvas = await html2canvas(tempDiv, {
				scale: 2,
				useCORS: true,
				backgroundColor: null,
				logging: true,
			});
			this.headerImage = canvas.toDataURL('image/png');

			document.body.removeChild(tempDiv);
		},
		async calculatePages() {
			const { prescription } = this.data.data.prescription;

			let cleanedPrescription = prescription
				.replace(/<ul[^>]*>/g, '')
				.replace(/<\/ul>/g, '')   
				.replace(/<ol[^>]*>/g, '')
				.replace(/<\/ol>/g, '');   

			cleanedPrescription = cleanedPrescription
				.replace(/<li([^>]*)>(.*?)<\/li>/g, '<ul><li$1>$2</li></ul>')
				.replace(/<p[^>]*>/g, '<p>').replace(/<\/p>/g, '</p>');

			let styledPrescription = cleanedPrescription
				.replace(/<p>/g, `<p style="font-size: ${this.fontSize}px; margin: 0; padding: 0;">`)
				.replace(/<ol>/g, `<ol style="margin-block-start: 0; margin-block-end: 0; padding-inline-start: 40px;">`)
				.replace(/<ul>/g, `<ul style="margin-block-start: 0; margin-block-end: 0; padding-inline-start: 40px;">`)
				.replace(/<li>/g, `<li style="font-size: ${this.fontSize}px;">`);

			const splitedPrescription = styledPrescription.split(/(<\/[^>]+>|<br\s*\/?>|\n|<p><br><\/p>|<p><br\/><\/p>|<p><br \/><\/p>|<p><\/p>)/g).filter(part => part !== undefined);

			let currentPageContent = '';
			const maxHeight = this.subpageHeight * 3.7795275591;
			const tempDiv = document.createElement('div');
			tempDiv.style.position = 'absolute';
			tempDiv.style.visibility = 'hidden';
			tempDiv.style.width = '200mm';
			document.body.appendChild(tempDiv);

			this.pagesRender = [];

			for (const string of splitedPrescription) {
				if (string.trim() === '') continue;

				const newContent = currentPageContent + string;
				tempDiv.innerHTML = newContent;

				let height = tempDiv.scrollHeight;

				if (height > maxHeight) {
					if (currentPageContent) {
						this.pagesRender.push(currentPageContent);
					}

					currentPageContent = '';

					const remainingHeight = maxHeight - height + tempDiv.scrollHeight;

					if (remainingHeight >= 20) {
						currentPageContent = string;
					} else {
						currentPageContent = string;
					}
				} else {
					currentPageContent = newContent;
				}
			}

			if (currentPageContent) {
				this.pagesRender.push(currentPageContent);
			}

			document.body.removeChild(tempDiv);
		},
		async Print(){
		this.loadingPrint = true;

			const doc = new jsPDF({ orientation: 'p', unit: 'mm', format: [215.9, 279.4] });

			for (let i = 0; i < this.pagesRender.length; i++) {
				if (i > 0) {
					doc.addPage();
				}

				const pageElement = this.$refs.dataprint.children[i];
				await this.$nextTick();

				const canvas = await html2canvas(pageElement, {
					scale: 3,
					useCORS: true,
					logging: true,
				});

				const imgData = canvas.toDataURL('image/png');
				doc.addImage(imgData, 'PNG', 0, 0, 215.9, 139.7);
			}

			this.data.show = false;

			window.open(doc.output('bloburl'));
			this.loadingPrint = false;
		},
	}
}
</script>
