<template>
  <div class="dialog-content">
    <block v-if="loading" style="z-index: 99999999 !important"></block>
    <!--div>
            
        </div-->
    <v-card-text>
      <div
        class="box mobile"
        v-if="hasDeleteBtn"
        style="
          display: flex;
          background: #41464c;
          color: white;
          padding: 10px;
          border-radius: 10px;
          justify-content: space-between;
          flex-wrap: wrap;
          z-index: 88888888 !important;
          text-align: center;
          position: sticky;
        "
      >
        <div v-if="hasDeleteBtn && !hasQoute">
          <div v-if="$store.getters['rolesUser/getCustomRole'](70)">
            <v-btn
              style="margin-top: 8px"
              class="btndelete"
              color="#E3191E"
              @click.prevent="showDelAlert = true"
            >
              <template v-if="invoice.IsTemplate">
                ELIMINAR <br />
                PLANTILLA
              </template>
              <template v-else-if="invoice.IsQuote">
                ELIMINAR <br />
                COTIZACIÓN
              </template>
              <template v-else>
                ELIMINAR <br />
                FACTURA
              </template>
            </v-btn>
          </div>
          <div v-else-if="$store.getters['rolesUser/getCustomRole'](15)">
            <v-btn
              style="margin-top: 8px"
              class="btndelete"
              color="#E3191E"
              @click.prevent="showDelAlert = true"
            >
              <template v-if="invoice.IsTemplate">
                ELIMINAR <br />
                PLANTILLA
              </template>
              <template v-if="invoice.IsQuote">
                ELIMINAR <br />
                COTIZACIÓN
              </template>
              <template v-else>
                ELIMINAR <br />
                FACTURA
              </template>
            </v-btn>
          </div>
        </div>
        <div>
          <v-btn
            style="margin-top: 8px"
            class="btndelete"
            color="#E3191E"
            v-if="
              hasDeleteBtn &&
              hasQoute &&
              $store.getters['rolesUser/getCustomRole'](74)
            "
            @click.prevent="showDelAlert = true"
          >
            <template v-if="invoice.IsTemplate">
              ELIMINAR <br />
              PLANTILLA
            </template>
            <template v-if="invoice.IsQuote">
              ELIMINAR <br />
              COTIZACIÓN
            </template>
            <template v-else>
              ELIMINAR <br />
              FACTURA
            </template>
          </v-btn>
        </div>

        <div
          style="
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          "
        >
          <label class="mx-2">SubTotal</label>

          <input
            type="text"
            class="form-control"
            :value="invoice.InvoiceSubTotal.toFixed(2)"
            disabled
            aria-describedby="subtotal"
          />
          <!-- <v-text-field outlined dense  outlined dense  class="form-control" hide-details :value="invoice.InvoiceSubTotal" readonly></v-text-field> -->
        </div>
        <div
          style="
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          "
        >
          <label>IVA</label>

          <input
            type="text"
            class="form-control"
            :value="invoice.InvoiceTotalIva.toFixed(2)"
            disabled
            aria-describedby="IVA"
          />
        </div>
        <div
          style="
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          "
        >
          <label class="">Exentas</label>

          <input
            type="text"
            class="form-control"
            :value="invoice.InvoiceTotalExenta.toFixed(2)"
            disabled
            aria-describedby="NON-TAXABLE"
          />
        </div>

        <div
          v-if="invoice.InvoiceType == 'FNS'"
          class=""
          style="
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          "
        >
          <label>Retención Renta</label>

          <input
            type="text"
            class="form-control"
            :value="invoice.InvoiceReteRenta.toFixed(2)"
            disabled
            aria-describedby="NON-TAXABLE"
          />
        </div>

        <div
          class=""
          style="
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
          "
        >
          <label class="">No Sujetas</label>

          <input
            type="text"
            class="form-control"
            :value="invoice.InvoiceTotalNoSuj.toFixed(2)"
            disabled
            aria-describedby="NON-TAXABLE"
          />
        </div>
        <!--v-row>
                        <v-col cols="12" md="6" sm="7" class="text-right align-self-center">
                            <label class="mx-2">IVA Ret 1%</label>
                        </v-col>

                        <v-col cols="12" md="6" sm="5">
                            <v-text-field outlined dense  outlined dense hide-details :value="invoice.InvoiceTotalRetTax" readonly></v-text-field>
                        </v-col>
                    </v-row-->
        <div
          class=""
          v-if="invoice.InvoiceIvaRete1 > 0 || invoice.InvoiceIvaPerce1 > 0"
          style="display: flex; flex-direction: column"
        >
          <label class="">
            {{ invoice.InvoiceIvaRete1 > 0 ? "IVA Rete" : "" }}
            {{ invoice.InvoiceIvaPerce1 > 0 ? "IVA Perce" : "" }}
          </label>

          <input
            v-if="invoice.InvoiceIvaRete1 > 0"
            type="text"
            class="form-control"
            :value="invoice.InvoiceIvaRete1.toFixed(2)"
            disabled
            aria-describedby="NON-TAXABLE"
          />

          <input
            v-if="invoice.InvoiceIvaPerce1 > 0"
            type="text"
            class="form-control"
            :value="invoice.InvoiceIvaPerce1.toFixed(2)"
            disabled
            aria-describedby="NON-TAXABLE"
          />
        </div>
        <div class="center" style="display: flex; flex-direction: column">
          <label class="">TOTAL</label>
          <input
            type="text"
            class="form-control"
            :value="invoice.InvoiceTotalPagar.toFixed(2)"
            disabled
            aria-describedby="VENTAS-TOTAL"
          />
        </div>

        <div>
          <v-btn
            style="margin-top: 8px"
            color="#00A178"
            width="fit-content"
            v-if="hasPrintButtom"
            dark
            @click.prevent="manageprint"
          >
            IMPRIMIR <br />FACTURA
          </v-btn>
        </div>

        <div>
          <div
            v-if="
              (hasDeleteBtn && !hasQoute) ||
              invoice.FeMhEstado == 'RECHAZADO' ||
              invoice.FeMhEstado == 'NO_PROCESADO'
            "
          >
            <div v-if="$store.getters['rolesUser/getCustomRole'](14)">
              <v-btn
                style="margin-top: 8px"
                color="#00A178"
                dark
                @click="editInvoice"
              >
                <template v-if="invoice.IsTemplate">
                  EDITAR <br />
                  PLANTILLA
                </template>
                <template v-else-if="invoice.IsQuote">
                  FACTURAR <br />
                  COTIZACIÓN
                </template>
                <template
                  v-else-if="
                    invoice.FeMhEstado == 'RECHAZADO' ||
                    invoice.FeMhEstado == 'NO_PROCESADO'
                  "
                >
                  CORREGIR <br />
                  DTE
                </template>
                <template v-else>
                  EDITAR <br />
                  FACTURA
                </template>
              </v-btn>
            </div>
            <div v-else-if="$store.getters['rolesUser/getCustomRole'](69)">
              <v-btn
                style="margin-top: 8px"
                color="#00A178"
                dark
                @click="editInvoice"
              >
                <template v-if="invoice.IsTemplate">
                  EDITAR <br />
                  PLANTILLA
                </template>
                <template v-else-if="invoice.IsQuote">
                  FACTURAR <br />
                  COTIZACIÓN
                </template>
                <template
                  v-else-if="
                    invoice.FeMhEstado == 'RECHAZADO' ||
                    invoice.FeMhEstado == 'NO_PROCESADO'
                  "
                >
                  CORREGIR <br />
                  DTE
                </template>
                <template v-else>
                  EDITAR <br />
                  FACTURA
                </template>
              </v-btn>
            </div>
          </div>
          <div>
            <v-btn
              style="margin-top: 8px"
              color="#00A178"
              dark
              v-if="
                ((hasDeleteBtn && hasQoute) ||
                  invoice.FeMhEstado == 'RECHAZADO' ||
                  invoice.FeMhEstado == 'NO_PROCESADO') &&
                $store.getters['rolesUser/getCustomRole'](73)
              "
              @click="editInvoice"
            >
              <template v-if="invoice.IsTemplate">
                EDITAR <br />
                PLANTILLA
              </template>
              <template v-else-if="invoice.IsQuote">
                FACTURAR <br />
                COTIZACIÓN
              </template>
              <template
                v-else-if="
                  invoice.FeMhEstado == 'RECHAZADO' ||
                  invoice.FeMhEstado == 'NO_PROCESADO'
                "
              >
                CORREGIR<br />
                DTE
              </template>
              <template v-else>
                EDITAR <br />
                FACTURA
              </template>
            </v-btn>
          </div>
        </div>
      </div>
      <div
        class="box summaryDesktop mobile"
        v-if="!hasDeleteBtn"
        style="
          display: flex;
          background: #41464c;
          color: white;
          padding: 10px;
          border-radius: 10px;

          z-index: 88888888 !important;
          justify-content: space-between;
          text-align: center;
          position: sticky;
        "
      >
        <div v-if="$store.getters['rolesUser/getCustomRole'](27)">
          <v-btn
            v-if="
              invoice.IsNDorNC == false &&
              invoice.FeMhEstado != 'INVALIDADO' &&
              invoice.FeMhEstado != 'CONTINGENCIA' &&
              invoice.FeMhEstado != 'RECHAZADO'
            "
            style="margin-top: 8px"
            class="btndelete"
            color="#E3191E"
            @click="showInvalidAlert = true"
          >
            INVALIDAR<br />FACTURA
          </v-btn>
        </div>

        <div class="" style="display: flex; flex-direction: column">
          <label class="">SubTotal</label>

          <input
            type="text"
            class="form-control"
            style="align-self: center"
            :value="invoice.InvoiceSubTotal.toFixed(2)"
            disabled
            aria-describedby="subtotal"
          />
          <!-- <v-text-field outlined dense  outlined dense  class="form-control" hide-details :value="invoice.InvoiceSubTotal" readonly></v-text-field> -->
        </div>
        <div class="" style="display: flex; flex-direction: column">
          <label>IVA</label>

          <input
            type="text"
            class="form-control"
            :value="invoice.InvoiceTotalIva.toFixed(2)"
            disabled
            aria-describedby="IVA"
          />
        </div>
        <div class="" style="display: flex; flex-direction: column">
          <label>Exentas</label>

          <input
            type="text"
            class="form-control"
            :value="invoice.InvoiceTotalExenta.toFixed(2)"
            disabled
            aria-describedby="NON-TAXABLE"
          />
        </div>

        <div
          v-if="invoice.InvoiceType == 'FNS'"
          class=""
          style="display: flex; flex-direction: column"
        >
          <label>Retención Renta</label>

          <input
            type="text"
            class="form-control"
            :value="invoice.InvoiceReteRenta.toFixed(2)"
            disabled
            aria-describedby="NON-TAXABLE"
          />
        </div>

        <div class="" style="display: flex; flex-direction: column">
          <label>No Sujetas</label>

          <input
            type="text"
            class="form-control"
            :value="invoice.InvoiceTotalNoSuj.toFixed(2)"
            disabled
            aria-describedby="NON-TAXABLE"
          />
        </div>
        <!--v-row>
                        <v-col cols="12" md="6" sm="7" class="text-right align-self-center">
                            <label class="mx-2">IVA Ret 1%</label>
                        </v-col>

                        <v-col cols="12" md="6" sm="5">
                            <v-text-field outlined dense  outlined dense hide-details :value="invoice.InvoiceTotalRetTax" readonly></v-text-field>
                        </v-col>
                    </v-row-->
        <div
          class=""
          style="display: flex; flex-direction: column"
          v-if="invoice.InvoiceIvaRete1 > 0 || invoice.InvoiceIvaPerce1 > 0"
        >
          <label>
            {{ invoice.InvoiceIvaRete1 > 0 ? "IVA Rete" : "" }}
            {{ invoice.InvoiceIvaPerce1 > 0 ? "IVA Perce" : "" }}
          </label>

          <input
            v-if="invoice.InvoiceIvaRete1 > 0"
            type="text"
            class="form-control"
            style="align-self: center"
            :value="invoice.InvoiceIvaRete1.toFixed(2)"
            disabled
            aria-describedby="NON-TAXABLE"
          />

          <input
            v-if="invoice.InvoiceIvaPerce1 > 0"
            type="text"
            class="form-control"
            style="align-self: center"
            :value="invoice.InvoiceIvaPerce1.toFixed(2)"
            disabled
            aria-describedby="NON-TAXABLE"
          />
        </div>
        <div class="" style="display: flex; flex-direction: column">
          <label>TOTAL</label>
          <input
            type="text"
            class="form-control totalDesignGlobal"
            style="align-self: center"
            :value="invoice.InvoiceTotalPagar.toFixed(2)"
            disabled
            aria-describedby="VENTAS-TOTAL"
          />
        </div>

        <div v-if="$store.getters['rolesUser/getCustomRole'](26)">
          <v-btn
            style="margin-top: 8px"
            color="#00A178"
            v-if="hasPrintButtom && invoice.IsNDorNC == false"
            dark
            @click.prevent="manageprint"
          >
            IMPRIMIR <br />FACTURA
          </v-btn>
        </div>

        <v-btn
          style="margin-top: 8px"
          color="#00A178"
          dark
          v-if="
            hasDeleteBtn ||
            invoice.FeMhEstado == 'RECHAZADO' ||
            invoice.FeMhEstado == 'NO_PROCESADO'
          "
          @click="editInvoice"
        >
          <template v-if="invoice.IsTemplate">
            EDITAR <br />
            PLANTILLA
          </template>
          <template
            v-else-if="
              invoice.FeMhEstado == 'RECHAZADO' ||
              invoice.FeMhEstado == 'NO_PROCESADO'
            "
          >
            CORREGIR <br />
            DTE
          </template>
          <template v-else>
            EDITAR <br />
            FACTURA
          </template>
        </v-btn>
      </div>
      <div
        class="summaryMobile mobile"
        v-if="!hasDeleteBtn"
        style="
          background: #41464c;
          color: white;
          padding: 10px;
          border-radius: 10px;

          z-index: 88888888 !important;

          text-align: center;
          position: sticky;
        "
      >
        <div style="overflow: hidden; display: flex; flex-direction: column">
          <div cl>
            <v-btn
              v-if="
                invoice.IsNDorNC == false &&
                invoice.FeMhEstado != 'INVALIDADO' &&
                invoice.FeMhEstado != 'CONTINGENCIA' &&
                invoice.FeMhEstado != 'RECHAZADO'
              "
              :style="`margin-top: 8px;${
                $vuetify.breakpoint.smAndDown ? '' : 'float: left;'
              }`"
              class="btndelete"
              color="#E3191E"
              @click="showInvalidAlert = true"
            >
              INVALIDAR<br />FACTURA
            </v-btn>
          </div>
          <div
            class=""
            style="
              float: left;
              align-items: center;
              display: flex;
              flex-direction: column;
            "
          >
            <label class="">SubTotal</label>

            <input
              type="text"
              class="form-control"
              :value="invoice.InvoiceSubTotal.toFixed(2)"
              disabled
              aria-describedby="subtotal"
            />
            <!-- <v-text-field outlined dense  outlined dense  class="form-control" hide-details :value="invoice.InvoiceSubTotal" readonly></v-text-field> -->
          </div>
          <div
            class=""
            style="
              float: left;
              align-items: center;
              display: flex;
              flex-direction: column;
            "
          >
            <label>IVA</label>

            <input
              type="text"
              class="form-control"
              :value="invoice.InvoiceTotalIva.toFixed(2)"
              disabled
              aria-describedby="IVA"
            />
          </div>
          <div
            class=""
            style="
              float: left;
              align-items: center;
              display: flex;
              flex-direction: column;
            "
          >
            <label>Exentas</label>

            <input
              type="text"
              class="form-control"
              :value="invoice.InvoiceTotalExenta.toFixed(2)"
              disabled
              aria-describedby="NON-TAXABLE"
            />
          </div>

          <div
            v-if="invoice.InvoiceType == 'FNS'"
            class=""
            style="
              float: left;
              align-items: center;
              display: flex;
              flex-direction: column;
            "
          >
            <label>Retención Renta</label>

            <input
              type="text"
              class="form-control"
              :value="invoice.InvoiceReteRenta.toFixed(2)"
              disabled
              aria-describedby="NON-TAXABLE"
            />
          </div>
        </div>

        <div style="overflow: hidden; display: flex; flex-direction: column">
          <div
            class=""
            style="
              float: left;
              align-items: center;
              display: flex;
              flex-direction: column;
            "
          >
            <label>No Sujetas</label>

            <input
              type="text"
              class="form-control"
              :value="invoice.InvoiceTotalNoSuj.toFixed(2)"
              disabled
              aria-describedby="NON-TAXABLE"
            />
          </div>
          <!--v-row>
                          <v-col cols="12" md="6" sm="7" class="text-right align-self-center">
                              <label class="mx-2">IVA Ret 1%</label>
                          </v-col>
  
                          <v-col cols="12" md="6" sm="5">
                              <v-text-field outlined dense  outlined dense hide-details :value="invoice.InvoiceTotalRetTax" readonly></v-text-field>
                          </v-col>
                      </v-row-->
          <div
            class=""
            style="display: flex; flex-direction: column"
            v-if="invoice.InvoiceIvaRete1 > 0 || invoice.InvoiceIvaPerce1 > 0"
          >
            <label>
              {{ invoice.InvoiceIvaRete1 > 0 ? "IVA Rete" : "" }}
              {{ invoice.InvoiceIvaPerce1 > 0 ? "IVA Perce" : "" }}
            </label>

            <input
              v-if="invoice.InvoiceIvaRete1 > 0"
              type="text"
              class="form-control"
              style="align-self: center"
              :value="invoice.InvoiceIvaRete1.toFixed(2)"
              disabled
              aria-describedby="NON-TAXABLE"
            />

            <input
              v-if="invoice.InvoiceIvaPerce1 > 0"
              type="text"
              class="form-control"
              style="align-self: center"
              :value="invoice.InvoiceIvaPerce1.toFixed(2)"
              disabled
              aria-describedby="NON-TAXABLE"
            />
          </div>
          <div
            class=""
            style="
              float: left;
              align-items: center;
              display: flex;
              flex-direction: column;
            "
          >
            <label>TOTAL</label>
            <input
              type="text"
              class="form-control totalDesignGlobal"
              style="align-self: center"
              :value="invoice.InvoiceTotalPagar.toFixed(2)"
              disabled
              aria-describedby="VENTAS-TOTAL"
            />
          </div>

          <v-btn
            style="margin-top: 8px; float: left; align-self: center"
            color="#00A178"
            v-if="hasPrintButtom && invoice.IsNDorNC == false"
            dark
            @click.prevent="manageprint"
          >
            IMPRIMIR <br />FACTURA
          </v-btn>
          <v-btn
            style="margin-top: 8px; float: left; width: 28%; align-self: center"
            color="save"
            dark
            v-if="
              hasDeleteBtn ||
              invoice.FeMhEstado == 'RECHAZADO' ||
              invoice.FeMhEstado == 'NO_PROCESADO'
            "
            @click="editInvoice"
          >
            <template v-if="invoice.IsTemplate">
              EDITAR <br />
              PLANTILLA
            </template>
            <template
              v-else-if="
                invoice.FeMhEstado == 'RECHAZADO' ||
                invoice.FeMhEstado == 'NO_PROCESADO'
              "
            >
              CORREGIR <br />
              DTE
            </template>
            <template v-else>
              EDITAR <br />
              FACTURA
            </template>
          </v-btn>
        </div>
      </div>
      <v-row class="mt-2 mb-2" v-if="invoice.Invoice_Status == 1">
        <v-col cols="12" md="6">
          <h3 v-if="invoice.FeMhEstado == 'PROCESADO'" style="color: green !important;"><b>Estado de la factura:</b> {{ invoice.FeMhEstado }}</h3>  
          <h3 v-else-if="invoice.FeMhEstado == 'INVALIDADO'" style="color: red !important;">
            <b>Estado de la factura:</b> {{ invoice.FeMhEstado }}
            <br/>
            <b>Fecha de invalidación:</b> {{ invalidDate }}
          </h3> 
          <h3 v-else><b>Estado de la factura:</b> {{ invoice.FeMhEstado }}</h3>  
        </v-col>
      </v-row>
      <v-card-title
        class="text-h5 d-flex mb-4 justify-space-between align-center"
      >
        <v-btn class="btnsave" @click="showCustomer = !showCustomer"
          ><v-icon>mdi-file-account</v-icon></v-btn
        >
        <p class="subtitle-1"><b>ID:</b> {{ invoice.ID_Invoice }}</p>
        <p
          class="subtitle-1"
          v-if="authFeActive == 1 && invoice.Invoice_Status == 1"
        >
          <b>Número de control: </b> {{ invoice.NumeroControl }}
          <br />
          <b>Código de generación: </b>
          {{ invoice.CodigoGeneracion || invoice.CodigoGeneracionInvalidacion }}
        </p>
        <!--p v-if="!hasDeleteBtn">
          <b>ID Legal:</b> {{ invoice.Legal_ID_Invoice }}
        </p-->
        <!--div>
          <v-btn text class="close-icon" @click="$emit('close')">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
        </div-->
      </v-card-title>
      <v-row style="text-align: right">
        <v-col cols="12">
          <v-btn
            style="margin: 1rem 0"
            color="#3498db"
            v-if="
              keyMaster == '22ddfc1b81d28286daec551efa268b0d' ||
              keyMaster == '1f4e233a5eef560814ecf400f0e4c642'
            "
            dark
            @click.prevent="managePrintDeliveryDoc"
          >
            IMPRIMIR DOCUMENTO DE ENTREGA
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="mt-4">
        <!-- <v-col cols="1">
                    <v-btn class="btnsave" @click="showCustomer = !showCustomer"><v-icon>mdi-file-account</v-icon></v-btn>
                  </v-col> -->
        <v-col
          :cols="12"
          :md="invoice.Legal_ID_Invoice === 'No hay correlativo' ? 6 : 4"
        >
          <date-picker
            :label="$t('vwinvoices_creationdate')"
            :value="date"
            v-model="date"
            :disbaled="true"
          ></date-picker>
        </v-col>
        <v-col
          :cols="12"
          :md="invoice.Legal_ID_Invoice === 'No hay correlativo' ? 6 : 4"
        >
          <v-select
            outlined
            dense
            attach
            v-model="invoice.InvoiceType"
            :items="filterType"
            item-text="name"
            item-value="key"
            label="Tipo de factura"
            readonly
          >
          </v-select>
        </v-col>
        <v-col cols="4">
          <v-text-field
            v-if="invoice.Legal_ID_Invoice != 'No hay correlativo'"
            outlined
            dense
            v-model="invoice.Legal_ID_Invoice"
            label="ID Legal"
            readonly
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          :md="invoice.InvoiceStatusDescription != 'Crédito' ? 6 : 4"
        >
          <!-- <v-select outlined dense v-model="invoice.InvoicePaymentType" attach :items="paymentTypes" label="Método de pago" readonly>
          </v-select> -->
          <v-select
            v-if="invoicePayments.length == 0"
            outlined
            dense
            v-model="invoice.InvoicePaymentType"
            attach
            :items="paymentTypes.map((current) => current.Valores)"
            label="Método de pago"
            class="fast-ease-in-transitioned-element"
            :rules="required"
            readonly
          >
          </v-select>
          <v-btn
            v-else
            class="btnsave mb-3"
            @click="showPayments = !showPayments"
            >Ver método de pago</v-btn
          >
        </v-col>

        <v-col
          cols="12"
          :md="invoice.InvoiceStatusDescription != 'Crédito' ? 6 : 4"
        >
          <v-select
            outlined
            dense
            attach
            v-model="invoice.InvoiceStatusDescription"
            :items="['Cancelada', 'Pendiente de pago', 'Crédito', 'Anulada']"
            label="Estado de la factura"
            readonly
          >
          </v-select>
        </v-col>

        <v-col cols="12" sm="6" md="4">
          <v-select
            outlined
            dense
            attach
            v-if="invoice.InvoiceStatusDescription == 'Crédito'"
            v-model="invoice.InvoiceCreditDay"
            :items="[
              '8 días',
              '15 días',
              '30 días',
              '90 días',
              '120 días',
              '180 días',
            ]"
            label="Días de crédito"
            readonly
          >
          </v-select>
        </v-col>
      </v-row>
      <v-row>
        <!-- <v-col cols="3">
                    <v-text-field outlined dense  v-model="invoice.InvoiceName" :label="$t('vwinvoices_invoicename')" readonly>
                    </v-text-field>
                </v-col>
                <v-col cols="6">
                    <v-text-field outlined dense v-model="invoice.InvoiceAddress" :label="$t('vwinvoices_invoiceaddress')" readonly>
                    </v-text-field>
                </v-col>
                <v-col cols="3">
                    <v-text-field outlined dense  v-model="invoice.InvoiceNRC" label="NRC Legal" readonly>
                    </v-text-field>
                </v-col> -->

        <v-col cols="12" sm="6" md="4" v-if="invoice.InvoiceType != 'FCF'">
          <v-text-field
            outlined
            dense
            v-model="invoice.InvoiceNRC"
            readonly
            :label="$t('vwinvoices_invoicenrc')"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="4" v-if="invoice.InvoiceDUI">
          <v-text-field
            outlined
            dense
            v-model="invoice.InvoiceDUI"
            readonly
            label="DUI"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="4" v-if="invoice.InvoiceType != 'FCF'">
          <v-text-field
            outlined
            dense
            v-model="invoice.InvoiceNIT"
            readonly
            :label="$t('vwinvoices_invoicenit')"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="4" v-if="invoice.InvoiceType != 'FCF'">
          <v-text-field
            outlined
            dense
            v-model="invoice.InvoiceEntity"
            readonly
            :label="$t('vwinvoices_invoiceentity')"
          >
          </v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="4" v-if="invoice.InvoiceType != 'FCF'">
          <v-text-field
            outlined
            dense
            v-model="invoice.InvoiceTaxPayerSize"
            readonly
            :label="$t('vwinvoices_invoicetaxpayersize')"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="4" v-if="invoice.InvoiceType != 'FCF'">
          <v-text-field
            outlined
            dense
            v-model="invoiceDteJson.ReceptorDescActividad"
            readonly
            label="Actividad Económica"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="4">
          <v-checkbox
            v-model="invoice.IsNonTaxable"
            readonly
            :label="$t('vwinvoices_cclientisnontaxable')"
          >
          </v-checkbox>
        </v-col>
        <v-col cols="12">
          <h3
            :class="!invoice.InvoiceInternalNote ? 'tabs' : 'tabsContent'"
            @click="showComment = !showComment"
          >
            Comentarios
          </h3>
          <v-dialog v-model="showComment" max-width="1000">
            <v-card>
              <v-card-title class="text-h5">Comentario de factura</v-card-title>
              <v-card-text>
                <quill-editor
                  v-model="invoice.InvoiceInternalNote"
                  :label="$t('vwinvoices_invoicenotes')"
                  readonly
                >
                </quill-editor>
              </v-card-text>
              <v-card-actions>
                <v-col class="text-right">
                  <v-btn @click="showComment = !showComment" class="btndisable">
                    Continuar
                  </v-btn>
                </v-col>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
        <v-col cols="4"></v-col>
        <v-col cols="4"></v-col>
      </v-row>

      <div class="mt-10">
        <h3 v-if="invoice.IsNDorNC == true">
          SELECCIONE LOS PRODUCTOS A MODIFICAR PARA CREAR LA NOTA DE CRÉDITO O
          DÉBITO
        </h3>
        <p v-if="invoice.IsNDorNC == false" class="mb-4">
          {{ $t("vwinvoices_currentinvoiceitems") }}
        </p>
        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th v-if="invoice.IsNDorNC == true"></th>
                <th class="text-left">ID</th>
                <th class="text-left">Descripción Producto/Servicio</th>
                <th class="text-left">Cantidad</th>
                <th class="text-left">Precio Unitario</th>
                <th class="text-left">Descuento</th>
                <th class="text-left">Exenta</th>
                <th class="text-left">No Sujeta</th>
                <th class="text-left">Total</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in invoiceItems" :key="item">
                <td v-if="invoice.IsNDorNC == true">
                  <v-checkbox @click="checkItemChanged(item)"> </v-checkbox>
                </td>
                <td>{{ item.ID_Item }}</td>
                <td>{{ item.ItemDescription }}</td>
                <td>{{ item.ItemQuantity }}</td>
                <td>
                  {{ item.ItemCurrency == "USD" ? "$" : item.ItemCurrency
                  }}{{ item.ItemUnitPrice.toFixed(4) }}
                </td>
                <td>
                  {{ item.ItemCurrency == "USD" ? "$" : item.ItemCurrency
                  }}{{ item.ItemDiscount.toFixed(4) }}
                </td>
                <td>
                  {{ item.ItemCurrency == "USD" ? "$" : item.ItemCurrency
                  }}{{ item.ItemExenta.toFixed(4) }}
                </td>
                <td>
                  {{ item.ItemCurrency == "USD" ? "$" : item.ItemCurrency
                  }}{{ item.ItemNoSuj.toFixed(4) }}
                </td>
                <td>
                  {{ item.ItemCurrency == "USD" ? "$" : item.ItemCurrency
                  }}{{ item.ItemGravada.toFixed(4) }}
                </td>
              </tr>
            </tbody>
            <!-- <tfoot>
                            <tr>
                                <td colspan="2"></td>
                                <td>
                                    Elementos: {{ invoiceItems.length }}
                                </td>
                                <td></td>
                                <td>
                                    Excentas <br />${{ invoice.InvoiceNonTaxable }}
                                </td>
                                <td>
                                    Total: <br />{{ invoiceItems[0].ItemCurrency }}{{ invoice.InvoiceTotal }}
                                </td>
                            </tr>
                        </tfoot> -->
          </template>
        </v-simple-table>

        <div class="d-flex flex-wrap justify-space-between align-start my-4">
          <v-btn
            v-if="invoice.IsNDorNC == true"
            color="#00A178"
            dark
            @click="addNDorNC('DNC')"
            >Relacionar Nota de Crédito</v-btn
          >
          <v-btn
            v-if="invoice.IsNDorNC == true"
            color="#00A178"
            dark
            @click="addNDorNC('DND')"
            >Relacionar Nota de Débito</v-btn
          >
          <!-- class="summ"  p>{{$t('vwinvoices_totalinletters')}}</p-->
          <!--div>
                        {{invoice.InvoiceTotal_InLetters}}
                    </div-->
        </div>
      </div>

      <v-dialog
        v-model="showCustomer"
        scrollable
        :max-width="$vuetify.breakpoint.smAndDown ? '90%' : '1000px'"
      >
        <v-card>
          <v-card-title class="text-h5">Información del cliente </v-card-title>
          <v-card-text>
            <v-row class="mt-7">
              <v-col cols="12" md="6">
                <v-text-field
                  outlined
                  dense
                  readonly
                  v-model="invoice.InvoiceName"
                  :label="$t('vwinvoices_clegalname')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  outlined
                  dense
                  readonly
                  v-model="invoice.InvoiceAddress"
                  :label="$t('vwinvoices_clegaladdress')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2" v-if="!invoice.isa_business">
                <v-text-field
                  outlined
                  dense
                  readonly
                  v-model="invoice.InvoiceDUI"
                  label="DUI"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="2">
                <v-text-field
                  outlined
                  dense
                  readonly
                  v-model="invoice.InvoiceNIT"
                  :label="$t('vwinvoices_clegalnit')"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="2">
                <v-text-field
                  outlined
                  dense
                  readonly
                  v-model="invoice.InvoiceNRC"
                  :label="$t('vwinvoices_clegalnrc')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  outlined
                  dense
                  readonly
                  v-model="invoice.InvoiceEntity"
                  :label="$t('vwinvoices_clegalentity')"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="3">
                <v-text-field
                  outlined
                  dense
                  readonly
                  v-model="invoice.InvoiceTaxPayerSize"
                  :label="$t('vwinvoices_clegaltaxpayersize')"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" md="5">
                <v-text-field
                  outlined
                  dense
                  readonly
                  v-model="invoice.InvoiceSector"
                  :label="$t('vwinvoices_clegalsector')"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field
                  outlined
                  dense
                  readonly
                  v-model="invoice.Invocie_Legal_ID_Reference"
                  :label="$t('vwinvoices_clegalinformation')"
                ></v-text-field>
              </v-col>
              <v-col class="align-self-center" cols="12" md="3">
                <v-checkbox
                  :value="invoice.legalisnontaxable"
                  readonly
                  v-model="invoice.InvoiceIsNonTaxable"
                  :label="$t('vwinvoices_cclientisnontaxable')"
                ></v-checkbox>
              </v-col>
              <!--<v-col cols="12" lg="4">
                                            <v-select outlined dense  :items="['Foo', 'Bar', 'Fizz', 'Buzz']" label="Payment Type" attach>
                                                <template v-slot:item="{ item, attrs, on }">
                                                    <v-list-item v-bind="attrs" v-on="on">
                                                        <v-list-item-title :id="attrs['aria-labelledby']" v-text="item"
                                                        ></v-list-item-title>
                                                    </v-list-item>
                                                </template>
                                            </v-select>
                                        </v-col>-->
              <v-col cols="12"></v-col>
              <br />
              <v-col cols="10"></v-col>
              <v-col cols="2"
                ><v-btn class="btndisable" @click="showCustomer = !showCustomer"
                  >Continuar</v-btn
                ></v-col
              >
              <br /><br />
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="showPayments"
        scrollable
        :max-width="$vuetify.breakpoint.smAndDown ? '90%' : '1000px'"
      >
        <v-card>
          <v-card-title class="text-h5"> Método de pago </v-card-title>
          <v-card-text>
            <DxDataGrid :data-source="invoicePayments" :show-borders="true">
              <DxColumn
                data-field="DescriptionTypePayment"
                caption="Tipo de pago"
              />
              <DxColumn data-field="TotalPayment" caption="Total" />
              <DxColumn data-field="Reference" caption="N. Referencia" />
            </DxDataGrid>
          </v-card-text>
          <v-card-actions class="text-right">
            <v-btn class="btndisable" @click="showPayments = false"
              >Continuar</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="showDelAlert"
        :max-width="$vuetify.breakpoint.smAndDown ? '90%' : '500px'"
      >
        <v-card v-if="showDelAlert" class="text-center">
          <v-card-title class="text-h5">
            ¿Esta seguro de eliminar esta factura?
          </v-card-title>

          <v-btn class="ma-2" large color="info" @click="deleteInvoice">
            Si
          </v-btn>

          <v-btn class="ma-2" large color="error" @click="showDelAlert = false">
            No
          </v-btn>
        </v-card>
      </v-dialog>

      <v-dialog
        v-model="showInvalidAlert"
        :max-width="$vuetify.breakpoint.smAndDown ? '90%' : '500px'"
      >
        <v-card v-if="showInvalidAlert" class="text-center">
          <template>
            <v-card-title class="text-h5">
              ¿Esta seguro de invalidar esta factura de consumidor final?

              <v-text-field
                outlined
                dense
                v-model="invalidForm.motivoInvalid"
                :rules="motivoInvalidRules"
                label="Motivo de invalidación"
              ></v-text-field>

              <v-select
                outlined
                dense
                attach
                v-model="invalidForm.tipo"
                :items="invalidForm.tipoInvalid"
                item-text="value"
                item-value="id"
                label="Seleccione el tipo de invalidación"
              >
              </v-select>

              <v-checkbox
                v-if="isCarsem"
                outlined
                dense
                attach
                v-model="invalidForm.IsReturn"
                label="Es devolución?"
              >
              </v-checkbox>
            </v-card-title>

            <v-btn
              class="ma-2"
              large
              color="info"
              @click="invalidDTE(invalidForm)"
            >
              Invalidar
            </v-btn>

            <v-btn
              class="ma-2"
              large
              color="error"
              @click="showInvalidAlert = false"
            >
              Cancelar
            </v-btn>
          </template>

          <!--template v-if="invoice.InvoiceType == 'CCF'">
            <v-card-title class="text-h5">
              ¿Esta seguro de invalidar esta factura de crédito fiscal?

              <v-text-field outlined dense v-model="invalidForm.motivoInvalid"
                label="Motivo de invalidación"></v-text-field>

              <v-select outlined dense attach v-model="invalidForm.tipo" :items="invalidForm.tipoInvalid"
                item-text="value" item-value="id" label="Seleccione el tipo de invalidación">
              </v-select>
            </v-card-title>

            <v-btn class="ma-2" large color="info" @click="invalidDTE(invalidForm)">
              Invalidar CCF
            </v-btn>

            <v-btn class="ma-2" large color="error" @click="showInvalidAlert = false">
              Cancelar
            </v-btn>
          </template-->
        </v-card>
      </v-dialog>
    </v-card-text>
    <alerts
      v-if="alert.show"
      v-on:close_alert="closeAlert"
      v-on:accept_alert="acceptAlert"
      :properties="alert"
    >
    </alerts>

    <template v-if="isFCF">
      <print-pos-factura
        :hideQr="hideQr"
        :dataRegular="print"
        :dataTicket="print3"
      ></print-pos-factura>
    </template>
    <template v-else>
      <PrintPosFacturaSolAmbiental v-if="infobusiness.ID_KeyMaster === '7accd5a9b298117d5fd9b94c54d8f97f'" :hideQr="hideQr" :dataProps="print"></PrintPosFacturaSolAmbiental>
      <print-pos-fa v-else :hideQr="hideQr" :dataProps="print"></print-pos-fa>
    </template>

    <PrintPosTk :dataProps="print2"></PrintPosTk>

    <!-- <PrintPosTkFcf :hideQr="hideQr" :dataProps="print3"></PrintPosTkFcf> -->

    <PrintPosDeliveryDoc
      :hideQr="true"
      :dataProps="printDeliveryDoc1"
    ></PrintPosDeliveryDoc>

    <block v-if="loading"></block>

    <alert
      v-model="alertInvoiceDetails.show"
      :title="alertInvoiceDetails.title"
      :color="alertInvoiceDetails.color"
      :altColor="alertInvoiceDetails.colorBtn"
      :titleColor="alertInvoiceDetails.colorTitle"
    >
      <div :class="`mb-3 ${['RECHAZADO', 'CONTINGENCIA'].includes(alertInvoiceDetails.estado) ? 'black--text' : 'white--text'}`">{{alertInvoiceDetails.message}}</div>
      <template v-if="!!alertInvoiceDetails.total">
        <div class="mb-1"><span :class="`'mb-1 text-h5' ${['RECHAZADO', 'CONTINGENCIA'].includes(alertInvoiceDetails.estado) ? 'black--text' : 'primary--text'}`">Total facturado:</span> ${{alertInvoiceDetails.total}}</div>
        <div class="text-h5"><span :class="`'text-h5' ${['RECHAZADO', 'CONTINGENCIA'].includes(alertInvoiceDetails.estado) ? 'black--text' : 'primary--text'}`">Total pagado:</span> ${{alertInvoiceDetails.pagoCon}}</div>
        <div class="mt-1 text-h5"><span :class="`'text-h5' ${['RECHAZADO', 'CONTINGENCIA'].includes(alertInvoiceDetails.estado) ? 'black--text' : 'primary--text'}`">Cambio:</span> ${{alertInvoiceDetails.cambio}}</div>
      </template>
      <div class="mt-3"><span :class="`${['RECHAZADO', 'CONTINGENCIA'].includes(alertInvoiceDetails.estado) ? 'black--text' : 'primary--text'}`">Estado: </span>{{alertInvoiceDetails.estado}}</div>
      <div :class="`${['RECHAZADO', 'CONTINGENCIA'].includes(alertInvoiceDetails.estado) ? 'black--text' : 'primary--text'}`">Mensaje del Ministerio de Hacienda:</div><span>{{alertInvoiceDetails.description}}</span>
      <div>
        <span :class="`${['RECHAZADO', 'CONTINGENCIA'].includes(alertInvoiceDetails.estado) ? 'black--text' : 'primary--text'}`">Observaciones DTE:</span>
        <template v-if="!!alertInvoiceDetails.observaciones.length">
          <div :key="index" v-for="(observacion, index) in alertInvoiceDetails.observaciones">- {{observacion}}</div>
        </template>
        <div v-else>- Ninguna observación</div>   
      </div>
    </alert>

  </div>
</template>

<script>
import DatePicker from "../../components/DatePicker";
import Alerts from "@/components/Alerts";
import PrintPosDeliveryDoc from "../../components/ReportsAndPrintings/PrintPosDeliveryDoc";
import PrintPosFa from "../../components/ReportsAndPrintings/PrintPosFa";
import PrintPosFacturaSolAmbiental from "../../components/ReportsAndPrintings/PrintPosFacturaSolAmbiental";
import PrintPosTk from "../../components/ReportsAndPrintings/PrintPosTk";
// import PrintPosTkFcf from "../../components/ReportsAndPrintings/PrintPosTkFcf";
import Block from "@/components/Block";
import { mapGetters } from "vuex";
import { DxDataGrid, DxColumn } from "devextreme-vue/data-grid";
import {
  VUE_APP_CARSEM_ID_KEYMASTER1,
  VUE_APP_CARSEM_ID_KEYMASTER2,
  VUE_APP_CARSEM_ID_KEYMASTER3,
} from "../../config";
import PrintPosFactura from "../../components/ReportsAndPrintings/PrintPosFactura.vue";
//import moment from 'moment';
export default {
  name: "InvoiceDetails",
  props: {
    invoice: {
      type: Object,
      default: () => {},
    },
    hasDeleteBtn: {
      type: Boolean,
      default: false,
    },
    hasQoute: {
      type: Boolean,
      default: false,
    },
    hasPrintButtom: {
      type: Boolean,
      default: false,
    },
    hideQr: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    DatePicker,
    Alerts,
    PrintPosFa,
    Block,
    PrintPosTk,
    DxDataGrid,
    DxColumn,
    // PrintPosTkFcf,
    PrintPosDeliveryDoc,
    PrintPosFactura,
    PrintPosFacturaSolAmbiental
  },
  data() {
    return {
      loading: false,
      alertInvoiceDetails:{
        show:false,
        title:"",
        message:"",
        color:"comment",
        total:"",
        pagoCon:"",
        cambio:"",
        estado:"",
        description:"",
        observaciones:[],
        colorBtn:"primary",
        colorTitle: "",
      },
      print: {
        show: false,
        data: [],
      },
      print2: {
        show: false,
        data: [],
      },
      print3: {
        show: false,
        data: [],
      },
      printDeliveryDoc1: {
        show: false,
        data: [],
      },
      windowsList: [],
      userinfo: JSON.parse(localStorage.getItem("user")),
      branchinfo: JSON.parse(localStorage.getItem("branch")),
      keyMaster: JSON.parse(localStorage.getItem("user")).keymaster,
      alert: {
        type: "success",
        show: false,
        header: "",
        body: "",
      },
      form: {},
      date: null,
      invoiceItems: [],
      invoiceDteJson: {
        ReceptorDescActividad: null,
      },
      invoiceDocRelated: [],
      invoiceSelectItems: [],
      totalItemsPrice: null,
      showDelAlert: false,
      showInvalidAlert: false,
      searchbusiness: {},
      infobusiness: {},
      header: {},
      footer: {},
      showCustomer: false,
      showComment: false,
      required: [(v) => !!v || "Este campo es requerido"],
      invalidForm: {
        motivoInvalid: "",
        tipo: 1,
        tipoInvalid: [
          {
            id: 1,
            value:
              "Error en la Información del Documento Tributario Electrónico a invalidar.",
          },
          { id: 2, value: "Rescindir de la operación realizada." },
          { id: 3, value: "Otro" },
        ],
        IsReturn: false,
      },
      filterType: [
        {
          name: "CONSUMIDOR FINAL",
          key: "FCF",
        },
        {
          name: "CRÉDITO FISCAL",
          key: "CCF",
        },
        {
          name: "SUJETO EXCLUIDO",
          key: "FNS",
        },
        {
          name: "EXPORTACIÓN",
          key: "FEX",
        },
        {
          name: "NOTA DE CRÉDITO",
          key: "DNC",
        },
        {
          name: "NOTA DE DÉBITO",
          key: "DND",
        },
        {
          name: "COMPROBANTE DE DONACIÓN",
          key: "CDD",
        },
        {
          name: "NOTA DE REMISIÓN",
          key: "DNR",
        },
      ],
      motivoInvalidRules: [
        (value) => {
          if (value) return true;

          return "Este campo es requerido.";
        },
        (value) => {
          if (value.length < 5)
            return "La longitud mínima de este campo es 5 caracteres";

          if (value.length > 250)
            return "La longitud máxima de este campo es 250 caracteres";

          return true;
        },
      ],
      authFeActive: JSON.parse(localStorage.getItem("FaMh")).AuthFeActive,
      invoicePayments: [],
      showPayments: false,
      customer: null,
    };
  },
  computed: {
    ...mapGetters(["getCatalogMH"]),
    isFCF() {
      return (
        JSON.parse(localStorage.getItem("user")).ticketformatfcf &&
        JSON.parse(localStorage.getItem("user")).TipoPlan != "Emprendedor" &&
        JSON.parse(localStorage.getItem("user")).TipoPlan != "Ecoanual" &&
        (this.invoice.InvoiceType == "FCF" || this.invoice.InvoiceType == "CCF" )
      );
    },
    paymentTypes() {
      return this.getCatalogMH("FormaPago");
    },
    isCarsem() {
      const keyMastersArray = JSON.parse(this.getCookie("ID_KeyMaster"));
      const specialKeyMasters =
        keyMastersArray.includes(VUE_APP_CARSEM_ID_KEYMASTER1) ||
        keyMastersArray.includes(VUE_APP_CARSEM_ID_KEYMASTER2) ||
        keyMastersArray.includes(VUE_APP_CARSEM_ID_KEYMASTER3);
      if (specialKeyMasters) {
        return true;
      } else {
        return false;
      }
    },
    invalidDate() {
      if (
        this.invoice &&
        this.invoice.FeMhEstado === "INVALIDADO" &&
        this.invoice.InvalidDate
      ) {
        const date = new Date(this.invoice.InvalidDate);
        return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
      }
      else if (
        this.invoice &&
        this.invoice.FeMhEstado === "INVALIDADO" &&
        this.invoice.SystemDateUpdate
      ) {
        const date = new Date(this.invoice.SystemDateUpdate);
        return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
      }
      return "";
    },
  },
  mounted() {
    this.searchbusiness.branch =
      JSON.parse(localStorage.getItem("user")).branch || "";
    this.searchbusiness.business =
      JSON.parse(localStorage.getItem("user")).businessid || "";
    this.initialDataCall();
    this.date = this.invoice.InvoiceDate;
  },
  methods: {
    async getCustomer() {
      await this.$API.customers
        .getOneCustomer(this.invoice.ID_Customer)
        .then((res) => {
          this.customer = res;
          this.invoice.customerEmail =
            res.Email || res.BusinessEmail || res.BusinessContactEmail;
        })
        .catch((err) => {
          console.log("ERROR CUSTOMER", err);
        });
    },
    getCookie(cname) {
      let name = cname + "=";
      let decodedCookie = decodeURIComponent(document.cookie);
      let ca = decodedCookie.split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ") {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
    async initialDataCall() {
      this.loading = true;
      if (
        this.invoice.InvoiceType == "DNC" ||
        this.invoice.InvoiceType == "DND"
      ) {
        await Promise.all([
          this.getHeaderFooter(),
          this.getInfoBranch(),
          this.getInvoiceProductItems(),
          this.getInvoiceServiceItems(),
          this.getDteJsonHeaderInfo(),
          this.getDocRelated(),
          this.getCustomer(),
        ]);
      } else {
        await Promise.all([
          this.getHeaderFooter(),
          this.getInfoBranch(),
          this.getInvoiceProductItems(),
          this.getInvoiceServiceItems(),
          this.getDteJsonHeaderInfo(),
          this.getInvoicePayments(),
          this.getCustomer(),
        ]);
      }

      await new Promise((resolve) => setTimeout(resolve, 1000));

      this.loading = false;
    },

    async getDteJsonHeaderInfo() {
      if (!this.hasDeleteBtn) {
        this.$API.invoices
          .getDteJsonHeaderByInvoice(this.invoice.ID_Invoice)
          .then((response) => {
            if (response.length > 0) this.invoiceDteJson = response[0];
          })
          .catch((err) => {
            console.log("Error", err);
          });
      }
    },

    async getDocRelated() {
      if (!this.hasDeleteBtn) {
        this.$API.invoices
          .getDocRelatedByInvoice(this.invoice.ID_Invoice)
          .then((response) => {
            this.invoiceDocRelated = response;
          })
          .catch((err) => {
            console.log("Error", err);
          });
      }
    },

    async getInvoiceProductItems() {
      this.$API.invoices
        .getInvoiceProductItems(this.invoice.ID_Invoice)
        .then((response) => {
          if (response != []) {
            response.map((el) => {
              this.invoiceItems.push(el);
              this.totalItemsPrice +=
                el.ItemGravada + el.ItemExenta + el.ItemNoSuj;
            });
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    },

    async getInvoiceServiceItems() {
      this.$API.invoices
        .getInvoiceServiceItems(this.invoice.ID_Invoice)
        .then((response) => {
          if (response != []) {
            response.map((el) => {
              this.invoiceItems.push(el);
              this.totalItemsPrice +=
                el.ItemGravada + el.ItemExenta + el.ItemNoSuj;
            });
          }
        });
    },

    closeAlert() {
      this.alert.show = false;
      if (this.alert.options == "reload") {
        this.$emit("close");
        this.$store.commit("posData/setReloaders", "reloadBill");
        this.$store.dispatch("reloadData", {
          name: "invoices",
          component: "Invoices",
          unique: true,
        });
      }
      if (this.alert.options == "reloadInfoInvalidInvoice") {
        this.$emit("close");
        this.$store.commit("posData/setReloaders", "reloadHistory");
        this.$store.dispatch("reloadData", {
          name: "invoices",
          component: "Invoices",
          unique: true,
        });
      }
      if (this.alert.options == "showInvalid") {
        this.showInvalidAlert = true;
      }
    },
    showAlert(type, header, body, options = null) {
      type = type == null ? "danger" : type;
      this.alert.type = type;
      this.alert.header = header;
      this.alert.body = body;
      this.alert.show = true;
      this.alert.options = options != null ? options : null;
    },
    getHeaderFooter() {
      let search = {
        ID_Branch: JSON.parse(localStorage.getItem("user")).branch || "",
        ID_Business: JSON.parse(localStorage.getItem("user")).businessid || "",
      };
      this.$API.HF.getHF(search).then((data) => {
        let header = [],
          footer = [];
        header = data.filter((x) => x.State == "1" && x.Type == "Header");
        this.header = header.length > 0 ? JSON.parse(header[0].Body).html : "";
        footer = data.filter((x) => x.State == "1" && x.Type == "Footer");
        this.footer = footer.length > 0 ? JSON.parse(footer[0].Body).html : "";
      });
    },
    deleteInvoice() {
      this.$API.invoices
        .deleteInvoice(this.invoice.ID_Invoice)
        .then(() => {
          this.showAlert(
            "success",
            "Factura eliminada",
            "La factura se ha eliminado correctamente",
            "reload"
          );
          this.showDelAlert = false;
        })
        .catch(() => {
          this.showAlert(
            "danger",
            "Error",
            "Ha ocurrido un error al eliminar la factura"
          );
          this.showDelAlert = false;
        });
    },

    Print() {
      this.$print(this.$refs.print);
    },

    async getInfoBranch() {
      this.$API.branches.findSpecificBranch(this.searchbusiness).then((res) => {
        this.infobusiness = res[0];
        this.branchinfo.AuthFeMode = this.infobusiness.AuthFeMode;
        this.branchinfo.BusinessBillingNRC =
          this.infobusiness.BusinessBillingNRC;
        this.branchinfo.BusinessBillingNIT =
          this.infobusiness.BusinessBillingNIT;
        this.branchinfo.BusinessBillingName =
          this.infobusiness.BusinessBillingName;
      });
    },
    manageprint() {
      if (
        JSON.parse(localStorage.getItem("user")).ticketformatfcf &&
        JSON.parse(localStorage.getItem("user")).TipoPlan != "Emprendedor" &&
        JSON.parse(localStorage.getItem("user")).TipoPlan != "Ecoanual" &&
        (this.invoice.InvoiceType == "FCF" || this.invoice.InvoiceType == "CCF")
      ) {
        this.print3 = {
          show: true,
          data: {
            header: this.invoice,
            user: this.userinfo,
            body: this.invoiceItems,
            branch: this.branchinfo,
            dteJson: this.invoiceDteJson,
            docRelated: this.invoiceDocRelated,
            invoicePayments: this.invoicePayments
          },
        };
        this.print = {
          show: true,
          data: {
            header: this.invoice,
            user: this.userinfo,
            body: this.invoiceItems,
            branch: this.branchinfo,
            dteJson: this.invoiceDteJson,
            docRelated: this.invoiceDocRelated,
            invoicePayments: this.invoicePayments
          },
        };
      } else {
        if (this.invoice.ID_Invoice.startsWith("TCKS")) {
          this.print2 = {
            show: true,
            data: {
              header: this.invoice,
              user: this.userinfo,
              body: this.invoiceItems,
              branch: this.branchinfo,
              dteJson: this.invoiceDteJson,
              docRelated: this.invoiceDocRelated,
              invoicePayments: this.invoicePayments
            },
          };
        } else {
          this.print = {
            show: true,
            data: {
              header: this.invoice,
              user: this.userinfo,
              body: this.invoiceItems,
              branch: this.branchinfo,
              dteJson: this.invoiceDteJson,
              docRelated: this.invoiceDocRelated,
              invoicePayments: this.invoicePayments
            },
          };
        }
      }
    },
    managePrintDeliveryDoc() {
      if (
        JSON.parse(localStorage.getItem("user")).ticketformatfcf &&
        JSON.parse(localStorage.getItem("user")).TipoPlan != "Emprendedor" &&
        JSON.parse(localStorage.getItem("user")).TipoPlan != "Ecoanual" &&
        (this.invoice.InvoiceType == "FCF" || this.invoice.InvoiceType == "CCF")
      ) {
        this.printDeliveryDoc1 = {
          show: true,
          data: {
            header: this.invoice,
            user: this.userinfo,
            body: this.invoiceItems,
            branch: this.branchinfo,
            dteJson: this.invoiceDteJson,
            docRelated: this.invoiceDocRelated,
          },
        };
      } else {
        if (this.invoice.ID_Invoice.startsWith("TCKS")) {
          this.printDeliveryDoc1 = {
            show: true,
            data: {
              header: this.invoice,
              user: this.userinfo,
              body: this.invoiceItems,
              branch: this.branchinfo,
              dteJson: this.invoiceDteJson,
              docRelated: this.invoiceDocRelated,
            },
          };
        } else {
          this.printDeliveryDoc1 = {
            show: true,
            data: {
              header: this.invoice,
              user: this.userinfo,
              body: this.invoiceItems,
              branch: this.branchinfo,
              dteJson: this.invoiceDteJson,
              docRelated: this.invoiceDocRelated,
            },
          };
        }
      }
    },
    editInvoice() {
      if (this.invoicePayments.length > 0) {
        this.invoice.payments = this.invoicePayments;
      } else {
        this.invoice.payments = [];
      }

      this.$store.commit("posData/setReloaders", "reloadCreate");
      this.$store.commit("posData/setHeader", this.invoice);
      this.$store.commit("posData/setBody", this.invoiceItems);
      this.$store.commit("posData/setInvalid", null);
      if( this.invoiceDocRelated.length > 0 ) {
        const [
          {
            NumeroDocumentoRelacionado,
            FechaEmisionRelacionado,
            TipoGeneracionRelacionado,
            TipoDocumentoRelacionado
          }
        ] = this.invoiceDocRelated;

        this.$store.commit("posData/setNDorNC", {
          Type: this.invoice.InvoiceType,
          TipoDocumentoRelacionado,
          TipoGeneracionRelacionado,
          NumeroDocumentoRelacionado,
          FechaEmisionRelacionado,
        } );
      } else {
        this.$store.commit("posData/setNDorNC", null);
      }
      this.$store.commit("posData/setQuote", null);

      if (
        this.invoice.FeMhEstado == "RECHAZADO" ||
        this.invoice.FeMhEstado == "NO_PROCESADO"
      ) {
        this.$store.commit("posData/setContingency", {
          CodigoGeneracion: this.invoice.CodigoGeneracion,
          NumeroControl: this.invoice.NumeroControl,
        });
      } else {
        this.$store.commit("posData/setContingency", null);
      }

      this.$emit("close");
      this.$store.commit("posData/changeView", "tab-1");
    },

    invalidDTE(invForm) {
      if (this.invoicePayments.length > 0) {
        this.invoice.payments = this.invoicePayments;
      } else {
        this.invoice.payments = [];
      }

      invForm.header = {
        ID_Invoice: this.invoice.ID_Invoice,
        InvoiceDate: this.invoice.InvoiceDate,
        InvoiceType: this.invoice.InvoiceType,
      };
      invForm.items = this.invoiceItems;

      if (!invForm.motivoInvalid || invForm.motivoInvalid.length < 5) {
        this.showInvalidAlert = false;
        this.showAlert(
          "warning",
          "Advertencia",
          "El motivo debe tener al menos 5 caracteres para poderse invalidar",
          "showInvalid"
        );
        return;
      }

      if (invForm.tipo == 1 || invForm.tipo == 3) {
        this.$store.commit("posData/setReloaders", "reloadCreate");
        this.$store.commit("posData/setHeader", this.invoice);
        this.$store.commit("posData/setBody", this.invoiceItems);
        this.$store.commit("posData/setNDorNC", null);
        this.$store.commit("posData/setQuote", null);
        this.$store.commit("posData/setInvalid", {
          ID_Invoice: this.invoice.ID_Invoice,
          InvalidForm: invForm,
          InvalidType: null,
          ID_Business: this.invoice.ID_Business,
          ID_Branch: this.invoice.ID_Branch,
          user: JSON.parse(localStorage.getItem("user")).id,
        });
        this.$emit("close");
        this.$store.commit("posData/changeView", "tab-1");
      }

      if (invForm.tipo == 2) {
        this.showInvalidAlert = false;
        this.loading = true;
        this.$API.invoices
          .invalidInvoice({
            ID_Invoice: this.invoice.ID_Invoice,
            InvalidForm: invForm,
            InvalidType: null,
            ID_Business: this.invoice.ID_Business,
            ID_Branch: this.invoice.ID_Branch,
            user: JSON.parse(localStorage.getItem("user")).id,
          })
          .then((response) => {
            this.loading = false;
            if (response.estado == "PROCESADO") {
              this.alertInvoiceDetails.title = "Invalidación Procesada"
              this.alertInvoiceDetails.message =
                "Se ha realizado y procesado la invalidación";
              this.alertInvoiceDetails.estado = response.estado;
              this.alertInvoiceDetails.description = response.descripcionMsg;
              this.alertInvoiceDetails.observaciones = response.observaciones;
              this.alertInvoiceDetails.colorBtn = "primary";
              this.alertInvoiceDetails.show = true;
              this.alert.options = "reloadInfoInvalidInvoice"
            } else {
              this.alertInvoiceDetails.title = "Invalidación Rechazada"
              this.alertInvoiceDetails.colorTitle = "black--text font-weight-bold"
              this.alertInvoiceDetails.color = "error"
              this.alertInvoiceDetails.colorBtn = "comment"
              this.alertInvoiceDetails.message =
                "Se ha rechazado la invalidación, tomar en cuenta las observaciones del Ministerio de Hacienda y realizarla de nuevo.";
              this.alertInvoiceDetails.estado =  response.estado;
              this.alertInvoiceDetails.description = response.descripcionMsg;
              this.alertInvoiceDetails.observaciones = response.observaciones;
              this.alertInvoiceDetails.show = true;
              this.loading = false;
            }
          })
          .catch((err) => {
            this.showInvalidAlert = false;
            let message =
              "Ocurrio un error crítico al realizar la invalidación. Luego de 3 intentos sin éxito contáctese con el administrador. ";
            message += "" + JSON.stringify(err);
            this.showAlert("warning", "Advertencia", message);
          });
      }
    },

    checkItemChanged(item) {
      const index = this.invoiceSelectItems.findIndex(
        (selectedItem) => selectedItem.ID_Item === item.ID_Item
      );
      if (index > -1) {
        this.invoiceSelectItems.splice(index, 1);
      } else {
        this.invoiceSelectItems.push(item);
      }
    },

    addNDorNC(type) {
      this.$store.commit("posData/setReloaders", "reloadCreate");
      this.$store.commit("posData/setHeader", this.invoice);
      this.$store.commit("posData/setBody", this.invoiceSelectItems);
      this.$store.commit("posData/setInvalid", null);
      this.$store.commit("posData/setQuote", null);
      this.$store.commit("posData/setNDorNC", {
        Type: type,
        TipoDocumentoRelacionado: "03",
        TipoGeneracionRelacionado: 2,
        NumeroDocumentoRelacionado: this.invoice.CodigoGeneracion,
        FechaEmisionRelacionado: this.invoice.InvoiceDate,
      });
      this.$emit("close");
      this.$store.commit("posData/changeView", "tab-1");
    },
    async getInvoicePayments() {
      this.$API.invoices
        .getInvoicePayments(this.invoice.ID_Invoice)
        .then((response) => {
          if (response != null) {
            this.invoicePayments = [];
            response.forEach((payment) => {
              payment.TotalPayment = parseFloat(payment.TotalPayment).toFixed(
                2
              );
              this.invoicePayments.push(payment);
            });
          }
        });
    },
  },
};
</script>

<style scoped>
.tabs {
  width: 100%;
  padding: 10px;
  background-color: rgba(60, 60, 60, 1);
  color: white !important;
  border-style: solid;
  border-color: black;
  border-width: 1px;
  border-radius: 10px;
}

.summaryMobile {
  display: none;
}
.tabs:hover {
  background-color: rgba(80, 80, 80, 1);
  cursor: pointer;
}

.tabsContent:hover {
  background-color: #ffb266;
  cursor: pointer;
}

.tabsContent {
  width: 100%;
  padding: 10px;
  background-color: #f29d35;
  color: white !important;
  border-style: solid;
  border-color: black;
  border-width: 1px;
  border-radius: 10px;
}

table {
  width: 100%;
}

.btnsave {
  background-color: #3498db !important;
  color: white;
}

.btndelete {
  background-color: #e3191e !important;
  color: white;
}

.btndisable {
  background-color: #41464c !important;
  color: white;
}

.form-control {
  background: #d6eaf3;
  border: 1px solid #1976d2;
  text-align: center;
  max-width: 80px;
  height: 30px;
  border-radius: 20px;
}

.v-application .mx-2 {
  margin-right: 0px !important;
}

.v-application .mx-3 {
  margin-right: 30px !important;
  display: flex;
  flex-direction: column;
}

.dialog-content {
  max-height: 115vh; /* Establece una altura máxima para el contenido */
  overflow-y: auto;
}

@media only screen and (max-width: 1174px) {
  .summaryMobile {
    display: block;
  }
  .summaryDesktop {
    display: none !important;
  }
}
@media (max-width: 600px) {
  .toolbar-container {
    flex-direction: column;
  }
  .dialog-content {
    max-height: 90vh; /* Establece una altura máxima para el contenido */
    overflow-y: auto;
  }
  /* Ajustes adicionales para mejorar la presentación en móviles */
  .toolbar-container > * {
    margin-bottom: 8px; /* Espacio entre elementos */
  }
  .mobile {
    justify-content: center;
    flex-direction: column;
  }
  .center {
    align-items: center !important;
  }
  .summaryMobile {
    justify-content: center;
    flex-direction: column;
  }
}
</style>
