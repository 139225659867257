<template>
  <window-portal
    :open="dataProps.show"
    @close="
      () => {
        dataProps.show = false;
        if (dataProps?.closeCallback) {
          dataProps?.closeCallback();
        }
      }
    "
  >
    <div
      style="
        font-family: arial !important;
        margin-top: 10px;
        margin-bottom: 10px;
      "
    >
      <center>
        <div>
          <p style="font-size: 30px">
            Vista Previa de Impresión de Factura Electrónica
          </p>
        </div>
      </center>
      <br />
      <center>
        <button class="btnclean" style="font-size: 18px" @click="Print()">
          Imprimir
        </button>
        &nbsp;&nbsp;
        <button class="btnclean" style="font-size: 18px" @click="Download()">
          Descargar
        </button>
        &nbsp;&nbsp;
        <button
          v-if="!hideQr"
          class="btnclean"
          style="font-size: 18px"
          @click="sendMail()"
        >
          Enviar Correo
        </button>
        &nbsp;&nbsp;
        <button
          v-if="!hideQr"
          class="btnclean"
          style="font-size: 18px"
          @click="downLoadJSON()"
        >
          Descargar JSON
        </button>
      </center>
      <center>
        <div v-if="loading"><p>Cargando...</p></div>
      </center>
    </div>
    <div
      style="
        font-family: arial !important;
        margin-top: 10px;
        margin-bottom: 10px;
      "
    >
      <center v-if="mailLabel">
        {{ mailLabel }}
      </center>
    </div>
    <div
      class="book"
      style="
        margin: 0;
        padding: 0;
        background-color: rgb(105, 105, 105);
        font-family: arial !important;
        padding-top: 20px;
        padding-bottom: 20px;
        letter-spacing: 0.01px !important;
      "
    >
      <div style="margin: 0 auto; width: 1000px">
        <div
          ref="dataprint"
          style="
            margin: 0 !important;
            padding: 0 !important;
            font-family: Arial !important;
          "
        >
          <!--div style="position: relative; width: 1000px; min-height: 1294px; background: white;"-->
          <div
            v-for="(page, indexPage) in pages"
            :key="indexPage"
            style="
              position: relative;
              width: 1000px;
              min-height: 1293px;
              background: white;
            "
          >
            <hr />
            <div
              style="
                width: 11.7cm;
                height: 10.4cm;
                position: absolute;
                top: calc(50% - 5.2cm);
                left: calc(50% - 5.85cm);
                z-index: 1;
              "
            >
              <img
                :src="imageOpacity"
                style="
                  position: static;
                  display: block;
                  opacity: 0.15;
                  width: 11.7cm;
                  height: 10.4cm;
                "
              />
            </div>
            <div
              class="subpage"
              style="
                height: 1250px;
                padding-top: 20px;
                overflow: hidden;
                position: relative;
                z-index: 99;
                display: flex;
              "
            >
              <div
                style="
                  margin-left: 80px;
                  margin-right: 50px;
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                  margin-bottom: 25px;
                "
              >
                <div>
                  <div v-if="hideQr" style="width: 100%; background-color: red">
                    <h3 style="color: white; text-align: center">
                      DOCUMENTO SIN VALIDEZ LEGAL
                    </h3>
                  </div>

                  <div
                    v-if="dataProps.data.header.FeMhEstado == 'RECHAZADO'"
                    style="width: 100%; background-color: red"
                  >
                    <h3 style="color: white; text-align: center">
                      DOCUMENTO RECHAZADO POR HACIENDA
                    </h3>
                  </div>

                  <div
                    v-if="dataProps.data.header.FeMhEstado == 'INVALIDADO'"
                    style="width: 100%; background-color: red"
                  >
                    <h3 style="color: white; text-align: center">
                      DOCUMENTO INVALIDADO POR HACIENDA
                    </h3>
                  </div>

                  <div class="header">
                    <div
                      class="subheader1"
                      style="display: flex; justify-content: space-between"
                    >
                      <div class="logoheader" style="align-items: left">
                        <img
                          v-if="
                            dataProps.data.branch?.BranchLogoPath != null &&
                            dataProps.data.branch?.BranchLogoPath != '' &&
                            dataProps.data.branch?.BranchLogoPath != 'null'
                          "
                          style="
                            max-width: 430px !important;
                            height: auto;
                            max-height: 200px !important;
                          "
                          :src="dataProps.data.branch.BranchLogoPath"
                          crossorigin="anonymous"
                        />
                      </div>
                      <div
                        class="info_qr"
                        style="
                          display: flex;
                          flex-direction: column;
                          border-bottom: 1px solid #284059;
                          justify-content: center;
                        "
                      >
                        <div
                          class="typefactura"
                          style="text-align: right; font-size: 14pt"
                        >
                          <div style="color: #96bf0e">
                            {{
                              !hideQr
                                ? "DOCUMENTO TRIBUTARIO ELECTRÓNICO"
                                : "DOCUMENTO SIN VALIDEZ LEGAL"
                            }}
                          </div>
                          <div style="color: #284059">
                            {{
                              dataProps.data.header.InvoiceType == "FCF"
                                ? "FACTURA CONSUMIDOR FINAL"
                                : ""
                            }}
                          </div>
                          <div style="color: #284059">
                            {{
                              dataProps.data.header.InvoiceType == "CCF"
                                ? "COMPROBANTE CRÉDITO FISCAL"
                                : ""
                            }}
                          </div>
                          <div style="color: #284059">
                            {{
                              dataProps.data.header.InvoiceType == "FNS"
                                ? "FACTURA SUJETO EXCLUIDO"
                                : ""
                            }}
                          </div>
                          <div style="color: #284059">
                            {{
                              dataProps.data.header.InvoiceType == "FEX"
                                ? "FACTURA DE EXPORTACIÓN"
                                : ""
                            }}
                          </div>
                          <div style="color: #284059">
                            {{
                              dataProps.data.header.InvoiceType == "DNC"
                                ? "NOTA DE CRÉDITO"
                                : ""
                            }}
                          </div>
                          <div style="color: #284059">
                            {{
                              dataProps.data.header.InvoiceType == "DND"
                                ? "NOTA DE DÉBITO"
                                : ""
                            }}
                          </div>
                          <div style="color: #284059">
                            {{
                              dataProps.data.header.InvoiceType == "CDD"
                                ? "DONACIÓN"
                                : ""
                            }}
                          </div>
                          <div style="color: #284059">
                            {{
                              dataProps.data.header.InvoiceType == "DNR"
                                ? "NOTA DE REMISIÓN"
                                : ""
                            }}
                          </div>
                        </div>

                        <div
                          style="
                            display: flex;
                            justify-content: space-between;
                            margin-top: 20px;
                          "
                        >
                          <div
                            class="infofactura"
                            style="
                              color: #284059;
                              font-size: 10pt;
                              text-align: left;
                              margin-right: 40px;
                              margin-bottom: 10px;
                            "
                          >
                            <div>
                              <b>Codigo de generación:</b>
                              {{ dataProps.data.header.CodigoGeneracion }}
                            </div>
                            <div>
                              <b>Sello de recepción:</b>
                              {{ dataProps.data.header.SelloRecepcion }}
                            </div>
                            <div>
                              <b>Número de control:</b>
                              {{ dataProps.data.header.NumeroControl }}
                            </div>
                            <div
                              v-if="
                                dataProps.data.header
                                  .InvoiceStatusDescription == 'Cancelada'
                              "
                            >
                              <b>Fecha emisión:</b>
                              {{ dataProps.data.header.InvoiceDate }}
                            </div>
                            <div v-else>
                              <b>Fecha vencimiento:</b>
                              {{ dateWithDays }}
                            </div>
                            <div>
                              <b>Correlativo factura:</b>
                              {{ dataProps.data.header.ID_Invoice }}
                            </div>
                          </div>
                          <div v-if="!hideQr" style="margin-bottom: 10px">
                            <img ref="qrcode" :src="urlQr" />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      class="subheader2"
                      style="
                        display: flex;
                        justify-content: space-between;
                        margin-top: 20px;
                        color: #284059;
                      "
                    >
                      <div style="font-size: 9pt">
                        <div><b>NIT:</b> {{ BillingNit }}</div>
                        <div><b>NRC:</b> {{ BillingNrc }}</div>
                      </div>
                      <div
                        style="
                          display: flex;
                          justify-content: space-around;
                          font-size: 10pt;
                          width: 700px;
                        "
                      >
                        <div>
                          <div>
                            <b>Modelo de facturación:</b>
                            {{
                              dataProps.data.dteJson.IdentificacionTipoModelo ==
                              1
                                ? "Previo"
                                : "Diferido"
                            }}
                          </div>
                          <div>
                            <b>Tipo de transmisión:</b>
                            {{
                              dataProps.data.dteJson
                                .IdentificacionTipoOperacion == 1
                                ? "Normal"
                                : "Por contingencía"
                            }}
                          </div>
                        </div>
                        <div>
                          <b>Proyecto:</b>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- datos emisor y receptor -->

                  <div
                    style="
                      display: flex;
                      font-size: 8pt;
                      color: #284059;
                      margin-top: 10px;
                    "
                  >
                    <div
                      style="
                      -webkit-border-radius: 10px;
                      -moz-border-radius: 10px
                      border-radius: 10px;
                      border: 1px solid #284059;
                      margin-right: 5px;
                      flex-grow: 1;
                      padding: 20px;
                      flex: 1 1 0px;
                    "
                    >
                      <div style="color: #96bf0e; font-size: 10pt;">
                        DATOS EMISOR:
                      </div>
                      <div>
                        <b>Razón social:</b>
                        {{ dataProps.data.user.business }}
                      </div>
                      <div>
                        <b>Actividad Económica:</b>
                        {{ dataProps.data.header.EmisorDescActividad }}
                      </div>
                      <div>
                        <b>Dirección:</b>
                        {{ dataProps.data.branch.BranchLegalAddress }}
                      </div>
                      <div>
                        <b>Teléfono: </b>{{ dataProps.data.branch.BranchPhone }}
                      </div>
                      <div>
                        <b>WhatsApp: </b
                        >{{ dataProps.data.branch.BranchMobile }}
                      </div>
                      <div>
                        <b>Correo: </b>{{ dataProps.data.branch.BranchEmail }}
                      </div>
                      <div></div>
                    </div>

                    <div
                      style="
                        -webkit-border-radius: 10px;
                        -moz-border-radius: 10px;
                        border-radius: 10px;
                        border: 1px solid #284059;
                        margin-left: 5px;
                        flex-grow: 1;
                        padding: 20px;
                        flex: 1 1 0px;
                      "
                    >
                      <div style="color: #96bf0e; font-size: 10pt;">
                        DATOS RECEPTOR:
                      </div>
                      <div>
                        <b>Razón social:</b>
                        {{ dataProps.data.header.InvoiceName }}
                      </div>
                      <div>
                        <b>Código cliente: </b
                        >{{ dataProps.data.header.ID_Customer }}
                      </div>
                      <div>
                        <b>NIT:</b> {{ dataProps.data.header.InvoiceNIT }}
                      </div>
                      <div>
                        <b>NRC:</b> {{ dataProps.data.header.InvoiceNRC }}
                      </div>
                      <div>
                        <b>Actividad Económica:</b>
                        {{ dataProps.data.dteJson.ReceptorDescActividad }}
                      </div>
                      <div>
                        <b>Dirección:</b>
                        {{ dataProps.data.header.InvoiceAddress }}
                      </div>
                      <div>
                        <b>Correo: </b
                        >{{
                          dataProps.data.header.customerEmail ||
                          dataProps.data.dteJson.ReceptorCorreo
                        }}
                      </div>
                      <div></div>
                    </div>
                  </div>

                  <br />
                  <!-- INCIO TABLA DE RESULTADOS -->
                  <div style="width: 100%">
                    <table
                      style="
                        width: 100% !important;
                        font-size: 11px;
                        border-collapse: collapse;
                        color: #284059;
                      "
                    >
                      <tr
                        style="
                          -webkit-border-radius: 10px;
                          -moz-border-radius: 10px;
                          border-radius: 10px;
                          border: 1px solid #284059;
                        "
                      >
                        <td
                          style="padding-left: 2px; width: 35px; padding: 5px"
                        >
                          No.<br />Item
                        </td>
                        <td
                          style="padding-left: 2px; width: 35px; padding: 5px"
                        >
                          Tipo<br />Item
                        </td>
                        <td
                          style="padding-left: 2px; width: 35px; padding: 5px"
                        >
                          Cant.
                        </td>
                        <td
                          style="padding-left: 2px; width: 95px; padding: 5px"
                        >
                          Código
                        </td>
                        <td
                          style="padding-left: 2px; width: 95px; padding: 5px"
                        >
                          Código<br />Alterno
                        </td>
                        <td style="padding-left: 2px; padding: 5px">
                          Descripcion
                        </td>
                        <td
                          style="padding-left: 2px; width: 65px; padding: 5px"
                        >
                          Precio<br />unitario
                          {{ dataProps.data.header.InvoiceCurrency }}
                        </td>
                        <td
                          style="padding-left: 2px; width: 65px; padding: 5px"
                        >
                          Descuento {{ dataProps.data.header.InvoiceCurrency }}
                        </td>
                        <td
                          v-if="dataProps.data.header.InvoiceType != 'FEX'"
                          style="padding-left: 2px; width: 65px; padding: 5px"
                        >
                          Ventas<br />exentas
                          {{ dataProps.data.header.InvoiceCurrency }}
                        </td>
                        <td
                          v-if="dataProps.data.header.InvoiceType != 'FEX'"
                          style="padding-left: 2px; width: 65px; padding: 5px"
                        >
                          Ventas no<br />sujetas
                          {{ dataProps.data.header.InvoiceCurrency }}
                        </td>
                        <td
                          v-if="dataProps.data.header.InvoiceType == 'FEX'"
                          style="padding-left: 2px; width: 65px; padding: 5px"
                        >
                          Otros montos no afectos
                          {{ dataProps.data.header.InvoiceCurrency }}
                        </td>
                        <td
                          v-if="dataProps.data.header.InvoiceType != 'FEX'"
                          style="padding-left: 2px; width: 65px; padding: 5px"
                        >
                          Ventas <br />gravadas
                          {{ dataProps.data.header.InvoiceCurrency }}
                        </td>
                        <td
                          v-if="dataProps.data.header.InvoiceType == 'FEX'"
                          style="padding-left: 2px; width: 65px; padding: 5px"
                        >
                          Ventas <br />afectas
                          {{ dataProps.data.header.InvoiceCurrency }}
                        </td>
                      </tr>
                      <!--tr v-for="(item, index) in dataProps.data.body" :key="index"-->
                      <tr
                        v-for="(item, index) in valuesRows[indexPage]"
                        :key="index"
                      >
                        <td style="">
                          {{
                            typePrint == 0
                              ? indexPage != 0
                                ? index + 1 + valuesRows[indexPage - 1].length
                                : index + 1
                              : index + 1 + 30 * indexPage
                          }}
                        </td>
                        <td style="">3</td>
                        <td style="">
                          {{ item.ItemQuantity }}
                        </td>
                        <td style="">
                          {{ item.ID_Item }}
                        </td>
                        <td style="">
                          {{ item.PrincipalBarCode }}
                        </td>
                        <td style="">
                          {{ item.ItemDescription }}
                        </td>
                        <td style="text-align: right">
                          {{
                            numberWithCommas(
                              parseFloat(item.ItemUnitPrice).toFixed(2)
                            )
                          }}
                        </td>
                        <td style="text-align: right">
                          {{
                            numberWithCommas(
                              parseFloat(item.ItemDiscount).toFixed(2)
                            )
                          }}
                        </td>
                        <td
                          v-if="dataProps.data.header.InvoiceType != 'FEX'"
                          style="text-align: right"
                        >
                          {{
                            numberWithCommas(
                              parseFloat(item.ItemExenta).toFixed(2)
                            )
                          }}
                        </td>
                        <td
                          v-if="dataProps.data.header.InvoiceType != 'FEX'"
                          style="text-align: right"
                        >
                          {{
                            numberWithCommas(
                              parseFloat(item.ItemNoSuj).toFixed(2)
                            )
                          }}
                        </td>
                        <td
                          v-if="dataProps.data.header.InvoiceType == 'FEX'"
                          style="text-align: right"
                        >
                          {{ numberWithCommas(parseFloat(0).toFixed(2)) }}
                        </td>
                        <td style="text-align: right">
                          {{
                            numberWithCommas(
                              parseFloat(item.ItemGravada).toFixed(2)
                            )
                          }}
                        </td>
                      </tr>
                    </table>
                  </div>
                  <!-- Final tabla resultados  -->
                </div>

                <div class="footer" style="color: #284059; font-size: 7pt">
                  <div style="display: flex; font-size: 8pt; color: #284059">
                    <div
                      style="
                        -webkit-border-radius: 10px;
                        -moz-border-radius: 10px;
                        border-radius: 10px;
                        border: 1px solid #96bf0e;
                        margin-left: 5px;
                        flex-grow: 1;
                        padding: 20px;
                        flex: 1 1 0px;
                      "
                    >
                      <div style="color: #284059">Son:</div>
                      {{ dataProps.data.header.InvoiceTotalLetras }}
                    </div>
                    <div
                      style="
                        -webkit-border-radius: 10px;
                        -moz-border-radius: 10px;
                        border-radius: 10px;
                        border: 1px solid #96bf0e;
                        margin-left: 5px;
                        flex-grow: 1;
                        padding: 20px;
                        flex: 1 1 0px;
                      "
                    >
                      <div style="color: #284059">Sumas:</div>
                    </div>
                  </div>

                  <div style="display: flex">
                    <div style="margin-top: 5px; flex-grow: 1; font-size: 8pt">
                      <div style="margin-top: 10px">
                        <b>Responsable por parde del EMISOR:</b>
                      </div>
                      <div style="margin-top: 10px">
                        <b>Responsable por parde del RECEPTOR:</b>
                      </div>
                      <div style="display: flex; margin-top: 10px">
                        <div style="margin-right: 25px">
                          <div>
                            <img :src="leafBullet" /> Revestimiento vinil y goma
                          </div>
                          <div><img :src="leafBullet" /> Impermeabilizantes</div>
                          <div><img :src="leafBullet" />  Iluminación natural</div>
                          <div><img :src="leafBullet" />  Equipos de aireación</div>
                        </div>
                        <div>
                          <div><img :src="leafBullet" />  Cubiertas ajardinadas</div>
                          <div><img :src="leafBullet" />  Estabilización de suelos</div>
                          <div><img :src="leafBullet" />  Revestimientos industriales</div>
                          <div><img :src="leafBullet" />  Muros con fachada vegetada</div>
                        </div>
                      </div>
                    </div>

                    <div>
                      <table>
                        <tr v-if="indexPage == numPages - 1">
                          <td colspan="5" style="">
                            <table
                              style="
                                width: 100% !important;
                                font-size: 11px;
                                text-align: right;
                                color: #284059;
                              "
                            >
                              <tr>
                                <td>Sub total ventas gravadas</td>
                                <td>
                                  {{ dataProps.data.header.InvoiceCurrency }}
                                </td>
                                <td style="text-align: right; width: 50px">
                                  {{
                                    numberWithCommas(
                                      parseFloat(
                                        dataProps.data.header
                                          .InvoiceSubTotalVentas
                                      ).toFixed(2)
                                    )
                                  }}
                                </td>
                              </tr>
                              <tr>
                                <td>Total descuentos</td>
                                <td>
                                  {{ dataProps.data.header.InvoiceCurrency }}
                                </td>
                                <td style="text-align: right; width: 50px">
                                  {{
                                    numberWithCommas(
                                      parseFloat(
                                        dataProps.data.header.InvoiceTotalDescu
                                      ).toFixed(2)
                                    )
                                  }}
                                </td>
                              </tr>
                              <tr>
                                <td>IVA</td>
                                <td>
                                  {{ dataProps.data.header.InvoiceCurrency }}
                                </td>
                                <td style="text-align: right; width: 50px">
                                  {{
                                    numberWithCommas(
                                      parseFloat(
                                        dataProps.data.header.InvoiceTotalIva
                                      ).toFixed(2)
                                    )
                                  }}
                                </td>
                              </tr>
                              <tr>
                                <td>Sub total</td>
                                <td>
                                  {{ dataProps.data.header.InvoiceCurrency }}
                                </td>
                                <td style="text-align: right; width: 50px">
                                  {{
                                    numberWithCommas(
                                      parseFloat(
                                        dataProps.data.header
                                          .InvoiceMontoTotalOperacion
                                      ).toFixed(2)
                                    )
                                  }}
                                </td>
                              </tr>
                              <tr>
                                <td>IVA Retenido</td>
                                <td>
                                  {{ dataProps.data.header.InvoiceCurrency }}
                                </td>
                                <td style="text-align: right; width: 50px">
                                  {{
                                    numberWithCommas(
                                      parseFloat(
                                        dataProps.data.header.InvoiceIvaRete1
                                      ).toFixed(2)
                                    )
                                  }}
                                </td>
                              </tr>
                              <tr>
                                <td>IVA Percibido</td>
                                <td>
                                  {{ dataProps.data.header.InvoiceCurrency }}
                                </td>
                                <td style="text-align: right; width: 50px">
                                  {{
                                    numberWithCommas(
                                      parseFloat(
                                        dataProps.data.header.InvoiceIvaPerce1
                                      ).toFixed(2)
                                    )
                                  }}
                                </td>
                              </tr>
                              <tr>
                                <td>Impuesto sobre la renta</td>
                                <td>
                                  {{ dataProps.data.header.InvoiceCurrency }}
                                </td>
                                <td style="text-align: right; width: 50px">
                                  {{
                                    numberWithCommas(
                                      parseFloat(
                                        dataProps.data.header.InvoiceReteRenta
                                      ).toFixed(2)
                                    )
                                  }}
                                </td>
                              </tr>
                              <tr
                                v-if="
                                  parseFloat(dataProps.data.header.Fovial) > 0
                                "
                              >
                                <td>Fovial</td>
                                <td>
                                  {{ dataProps.data.header.InvoiceCurrency }}
                                </td>
                                <td style="text-align: right; width: 50px">
                                  {{
                                    numberWithCommas(
                                      parseFloat(
                                        dataProps.data.header.Fovial
                                      ).toFixed(2)
                                    )
                                  }}
                                </td>
                              </tr>
                              <tr
                                v-if="
                                  parseFloat(dataProps.data.header.Cotrans) > 0
                                "
                              >
                                <td>Cotrans</td>
                                <td>
                                  {{ dataProps.data.header.InvoiceCurrency }}
                                </td>
                                <td style="text-align: right; width: 50px">
                                  {{
                                    numberWithCommas(
                                      parseFloat(
                                        dataProps.data.header.Cotrans
                                      ).toFixed(2)
                                    )
                                  }}
                                </td>
                              </tr>
                              <tr style="font-size: 12px">
                                <td><b>Total</b></td>
                                <td>
                                  <b>{{
                                    dataProps.data.header.InvoiceCurrency
                                  }}</b>
                                </td>
                                <td style="text-align: right; width: 50px">
                                  <b>{{
                                    numberWithCommas(
                                      parseFloat(
                                        dataProps.data.header.InvoiceTotalPagar
                                      ).toFixed(2)
                                    )
                                  }}</b>
                                </td>
                              </tr>
                            </table>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                  <div>
                    <div
                      style="
                        color: #284059;
                        font-size: 7pt;
                        display: flex;
                        margin-bottom: 15px;
                      "
                    >
                      <div style="margin-right: 200px">
                        <b>solambiental.com</b>
                      </div>
                      <div><b>Emite:</b></div>
                      <div />
                    </div>
                    <div style="background-color: #96bf0e; height: 10px"></div>
                    <div style="background-color: #284059; height: 20px"></div>
                  </div>
                </div>

                <div
                  v-if="
                    indexPage == numPages - 1 &&
                    keyMaster === 'd9254e317333e6d6efd9dd323c1083a5'
                  "
                >
                  <h4
                    style="font-size: 8px; text-align: center; margin-top: 2rem"
                  >
                    PAGARÉ SIN PROTESTO
                  </h4>
                  <p style="font-size: 8px; text-align: justify">
                    Yo _______________________________ me (nos) obligo (amos) a
                    pagar incondicionalmente a la orden de
                    ________________________________ la cantidad de
                    _______________ el día _______ del mes de __________ del año
                    ________. En caso de mora, reconoceré (mos) intereses del
                    ____% mensual. Para los efectos de esta obligación
                    mercantil, fijo (amos) como domicilio especial
                    ______________________________ de la ciudad de
                    ___________________________. Siendo de mi (nuestro) cargo
                    cualquier gasto que la sociedad hiciere en el cobro de este
                    pagaré, faculto (amos) que se designe a la persona
                    depositaria de los bienes que se embarguen, a quien relevo
                    (amos) de la obligación de rendir fianza.
                  </p>
                  <p style="font-size: 8px">
                    Firma del cliente (suscriptor):
                    ________________________________________
                  </p>
                  <p style="font-size: 8px">
                    Nombre o identificación:
                    ____________________________________________
                  </p>
                  <p style="font-size: 8px">
                    Fecha y lugar de suscripción:
                    ________________________________________
                  </p>
                  <p style="font-size: 8px">
                    Nota aclaratoria: No se aceptarán devoluciones de mercadería
                    después de los 60 días de crédito fiscal. Art. 62, Ley de
                    IVA.
                  </p>
                </div>
              </div>

              <div
                style="position: absolute; bottom: 0; width: 100%; left: 80px"
              >
                <p style="font-size: 10px">
                  Pagina {{ indexPage + 1 }} de {{ numPages }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </window-portal>
</template>

<script>
import jsPDF from "jspdf";
import QRCode from "qrcode";
//import html2canvas from "html2canvas"
import WindowPortal from "@/components/ReportsAndPrintings/WindowPortal.vue";
import { addFormatToNIT, addFormatNRC } from "../../helpers/misc";
import { numberWithCommas } from "../../helpers/money";
import { numberToText } from "@/helpers/conveterNumberText.js";

export default {
  name: "PrintPosFacturaSolAmbiental",
  props: {
    dataProps: {
      type: Object,
    },
    hideQr: {
      type: Boolean,
      default: false,
    },
  },
  components: { WindowPortal },
  data() {
    return {
      header: null,
      footer: null,
      loading: false,
      leafBullet:
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAACXBIWXMAAAYmAAAGJgGfEzojAAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAPdJREFUGJWNkD9LA1EQxH/77pkTI/oFUlnkFA9tTCVYafINDiFoYWNnb2drZ2UtBAVjLxZCtBBs7A78g2AbVFJpIXlxbDxEFHFgYWYHltkxSfwH/i9ztrFafusPFuOh6NSKi5ZlUfXR1SNz84IEmAISxN312cG0B0iWmhOTwR9jJBI9jFtgHPCCPQBnZuaCDoFEYhfjCKgBFaDvLbQAXLqwXAHmhLYdDAPrRXYzdvJOuwvg4texLnBusgsZa1+v6HJk9GWrUCaJNMtKgyd/BaSf+/0oDhv5Sbv3rZ7wXKoZ7w/gNiPsJu+07n90JQlJzNRXygX/bT4Ae09lcDegkkMAAAAASUVORK5CYII=",
      //seal: "",
      //sealBranch: "",
      numPages: 0,

      //valuesRows: []
      mailLabel: null,
      valuesRows: [],
      urlQr: null,
      typePrint: 0,
      specialinvoicescolor: JSON.parse(localStorage.getItem("user"))
        .specialinvoicescolor,
      specialInvoicesTitleColor: JSON.parse(localStorage.getItem("user"))
        .specialInvoicesTitleColor,
      kitcoalternativeposclientdata: JSON.parse(localStorage.getItem("user"))
        .kitcoalternativeposclientdata,
      lexlitislatamclientdata: JSON.parse(localStorage.getItem("user"))
        .lexlitislatamclientdata,
      romerolascascadasdata: JSON.parse(localStorage.getItem("user"))
        .romerolascascadasdata,
      keyMaster: JSON.parse(localStorage.getItem("user")).keymaster,
      imageOpacity: "",
    };
  },
  mounted() {
    //console.log(this.dataProps);
    //console.log(this.dataProps.data.branch)
    //console.log(this.dataProps.data.dteJson)
    //this.seal = JSON.parse(localStorage.getItem("user")).seal || ""
    //this.sealBranch = JSON.parse(localStorage.getItem("user")).sealBranch || ""
    this.getHeaderFooter();
    // if(this.dataProps.)
    // this.$nextTick(() => {
    //     this.sendMail();
    // })
  },
  computed: {
    /*edad() {
            let edad = this.dataProps.data.profile.Age
            if (edad == null || edad == undefined) {
                return ''
            } else {
                return edad
            }
        },*/
    BillingNrc() {
      return addFormatNRC(this.dataProps.data.branch.BusinessBillingNRC);
    },
    BillingNit() {
      //console.log(this.dataProps.data.branch.BusinessBillingNIT);
      return addFormatToNIT(this.dataProps.data.branch.BusinessBillingNIT);
    },
    pages() {
      // Generar un arreglo con `numPages` elementos
      return Array.from({ length: this.numPages });
    },
    commentsOuterHtml() {
      return this.dataProps.data.header.InvoiceInternalNote.replace(
        /<[^>]*>/g,
        ""
      );
    },
    modifiedBranchBilledInfo() {
      const info = this.dataProps.data.branch.BranchBilledInfo;
      if (info && info !== "") {
        return info.replace(/<p>/g, "").replace(/<\/p>/g, "<br/>");
      }

      return null;
    },
    dateWithDays() {
      let fecha = this.dataProps.data.header.InvoiceDate;
      let dias = this.dataProps.data.header.InvoiceCreditDay;

      const partesFecha = fecha.split("/");
      const dia = parseInt(partesFecha[0], 10);
      const mes = parseInt(partesFecha[1], 10) - 1;
      const anio = parseInt(partesFecha[2], 10);
      const fechaInicial = new Date(anio, mes, dia);

      const diasAgregar = parseInt(dias.split(" ")[0], 10);

      fechaInicial.setDate(fechaInicial.getDate() + diasAgregar);

      const diaNuevo = ("0" + fechaInicial.getDate()).slice(-2);
      const mesNuevo = ("0" + (fechaInicial.getMonth() + 1)).slice(-2);
      const anioNuevo = fechaInicial.getFullYear();

      return `${diaNuevo}/${mesNuevo}/${anioNuevo}`;
    },
    showInvoiceStatus() {
      if (
        this.keyMaster == "381a9d3c7f684efc027cf51b77a453fd" ||
        this.keyMaster == "79f09442457787675efa1cc6a58186f7" ||
        this.keyMaster == "ffd46f416f5f6d70aba9dfcc386e04a9" ||
        this.keyMaster == "fe2f4246877fbe796e3af7c3ff5f3700" ||
        this.keyMaster == "adfb992c4b99e782140fbf37dbcc97d3"
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
  watch: {
    "dataProps.data": {
      handler() {
        this.showData();
      },
      deep: true,
    },
  },
  methods: {
    showData() {
      if (this.dataProps.show) {
        setTimeout(async () => {
          await this.calculatePagesOfDoc();
          const partes = this.dataProps.data.header.InvoiceDate.split("/");
          const dia = partes[0];
          const mes = partes[1];
          const anio = partes[2];

          const fechaNueva = `${anio}-${mes}-${dia}`;
          const url =
            this.dataProps.data.branch.AuthFeMode == 1
              ? `https://admin.factura.gob.sv/consultaPublica?ambiente=01&codGen=${this.dataProps.data.header.CodigoGeneracion}&fechaEmi=${fechaNueva}`
              : `https://admin.factura.gob.sv/consultaPublica?ambiente=00&codGen=${this.dataProps.data.header.CodigoGeneracion}&fechaEmi=${fechaNueva}`;

          const opt = {
            errorCorrectionLevel: "H",
            type: "image/png",
            quality: 0.3,
            margin: 1,
            color: {
              dark: "#000000",
              light: "#ffffff",
            },
            width: 100,
          };

          QRCode.toDataURL(url, opt, (error, url) => {
            if (error) {
              console.log("Error al generar el código QR:", error);
              throw error;
            }

            this.urlQr = url;
          });
          await this.getImages();
        }, 0);
      }
    },
    GetnumberToText(number) {
      if (number) return numberToText(number);
      else return "";
    },
    numberWithCommas(x) {
      return numberWithCommas(x);
    },
    getHeaderFooter() {
      let search = {};
      search.ID_business =
        JSON.parse(localStorage.getItem("user")).businessid || "";
      search.ID_branch = JSON.parse(localStorage.getItem("user")).branch || "";
      this.$API.HF.getHF(search).then((data) => {
        let header = [],
          footer = [];
        header = data.filter((x) => x.State == "1" && x.Type == "Header");
        this.header = header.length > 0 ? JSON.parse(header[0].Body).html : "";
        this.header = this.header.replace(/(\n)/gm, "");
        footer = data.filter((x) => x.State == "1" && x.Type == "Footer");
        this.footer = footer.length > 0 ? JSON.parse(footer[0].Body).html : "";
      });
    },

    calculatePagesOfDoc() {
      let pages = 0;
      let rows = 0;
      this.valuesRows[pages] = [];

      try {
        if (
          this.dataProps.data.branch.BusinessBillingNRC == "3213715" ||
          this.dataProps.data.branch.BusinessBillingNRC == "2201050"
        ) {
          this.typePrint = 1;
          //One page
          if (this.dataProps.data.body.length <= 24) {
            for (let i = 0; i < this.dataProps.data.body.length; i++) {
              rows++;
              this.valuesRows[pages].push(this.dataProps.data.body[i]);
            }
          } else {
            for (let i = 0; i < this.dataProps.data.body.length; i++) {
              if (rows < 29 && i !== this.dataProps.data.body.length - 1) {
                rows++;
                this.valuesRows[pages].push(this.dataProps.data.body[i]);
              } else if (rows <= 24) {
                rows++;
                this.valuesRows[pages].push(this.dataProps.data.body[i]);
              } else {
                this.valuesRows[pages].push(this.dataProps.data.body[i]);
                pages++;
                rows = 0;
                this.valuesRows[pages] = [];
              }
            }
          }
        } else {
          this.typePrint = 0;
          //Cuando es solo una página
          if (this.dataProps.data.body.length <= 14) {
            for (let i = 0; i < this.dataProps.data.body.length; i++) {
              rows++;
              this.valuesRows[pages].push(this.dataProps.data.body[i]);
            }
          } else {
            for (let i = 0; i < this.dataProps.data.body.length; i++) {
              if (rows < 16 && i !== this.dataProps.data.body.length - 1) {
                rows++;
                this.valuesRows[pages].push(this.dataProps.data.body[i]);
              } else if (rows <= 14) {
                rows++;
                this.valuesRows[pages].push(this.dataProps.data.body[i]);
              } else {
                this.valuesRows[pages].push(this.dataProps.data.body[i]);
                pages++;
                rows = 0;
                this.valuesRows[pages] = [];
              }
            }
          }
        }
      } catch (err) {
        alert(err);
      }
      pages = pages + 1;
      this.numPages = pages;
    },

    async Print() {
      this.loading = true;
      var doc = new jsPDF("p", "pt", "letter");
      await doc.html(this.$refs.dataprint, {
        callback: function (doc) {
          doc.output();
        },
        x: 0,
        y: 0,
        margin: [0, 0, 0, 0],
        html2canvas: {
          scale: 0.612,
          //scale: (doc.internal.pageSize.width - 10 * 2) / this.$refs.dataprint.scrollWidth,
          useCORS: true,
          logging: false,
          allowTaint: true,
        },
      });

      //doc.deletePage(2)

      doc.autoPrint({ variant: "non-conform" });
      const blob = doc.output("bloburl");
      window.open(blob);

      this.loading = false;
    },

    async Download() {
      this.loading = true;
      var doc = new jsPDF("p", "pt", "letter");
      doc.setFont("arial");
      await doc.html(this.$refs.dataprint, {
        callback: function (doc) {
          doc.output();
        },
        x: 0,
        y: 0,
        margin: [0, 0, 0, 0],
        html2canvas: {
          scale: 0.612,
          //scale: (doc.internal.pageSize.width - 10 * 2) / this.$refs.dataprint.scrollWidth,
          useCORS: true,
          logging: false,
          allowTaint: true,
        },
      });

      //doc.deletePage(2)
      doc.save(
        this.dataProps.data.header.ID_Invoice +
          "_" +
          this.dataProps.data.header.InvoiceDate.replaceAll("/", "-") +
          "_" +
          this.dataProps.data.header.InvoiceName.replaceAll(
            /[:/<>*|/]/g,
            ""
          ).replaceAll('\\"', "") +
          ".pdf"
      ); //Agregado por Juan 13082023

      this.loading = false;
    },
    async sendMail() {
      try {
        this.mailLabel = null;
        this.mailLabel = "Enviando...";
        var doc = new jsPDF("p", "pt", "letter");
        //console.log(doc);
        //console.log(this.$refs.dataprint);
        await doc.html(this.$refs.dataprint, {
          callback: function (doc) {
            doc.output();
          },
          x: 0,
          y: 0,
          margin: [0, 0, 0, 0],
          html2canvas: {
            scale: 0.612,
            //scale: (doc.internal.pageSize.width - 10 * 2) / this.$refs.dataprint.scrollWidth,
            useCORS: true,
            logging: false,
            allowTaint: true,
          },
        });

        this.assignCuerpoDocumento();

        const {
          data: {
            header: { jsonMH },
            dteJson: { ID_Invoice },
          },
        } = this.dataProps;

        const body = {
          dtefilebuffer: new Buffer.from(doc.output("arraybuffer")),
          dtejson: { ...jsonMH, ID_Invoice },
          dtefilebufferfilename:
            this.dataProps.data.header.ID_Invoice +
            "_" +
            this.dataProps.data.header.InvoiceDate.replaceAll("/", "-") +
            "_" +
            this.dataProps.data.header.InvoiceName.replaceAll(
              /[:/<>*|/]/g,
              ""
            ).replaceAll('\\"', ""),
        };

        // console.log(body);
        await this.$API.invoices.sendInvoiceMail(body);

        this.mailLabel = "Se envió el correo correctamente";
        this.$emit("mailSent");
      } catch (error) {
        this.mailLabel = `Ocurrió el siguiente error al enviar el correo ${error}`;
        // console.log(error);
      }
    },
    assignCuerpoDocumento() {
      this.dataProps.data.header.jsonMH.cuerpoDocumento =
        this.dataProps.data.body.map((item, index) => ({
          descripcion: item.ItemDescription,
          montoDescu: item.ItemDiscount,
          codigo: item.ID_Item,
          ventaGravada: item.ItemGravada,
          ivaItem: item.ItemIva,
          ventaNoSuj: item.ItemNoSuj,
          ventaExenta: item.ItemExenta,
          // "tributos": null,
          numItem: index + 1,
          noGravado: item.ItemNoGravada,
          psv: item.ItemPsv,
          // "tipoItem": 3,
          // "codTributo": null,
          uniMedida: item.ItemMeasureUnit,
          // "numeroDocumento": null,
          cantidad: item.ItemQuantity,
          precioUni: item.ItemUnitPrice,
        }));
      // for( const item in this.dataProps.data.body ) {
      //      [
      //         "descr"
      //     ]
      // }
    },
    async downLoadJSON() {
      try {
        const selloRecibido =
          JSON.parse(this.dataProps.data.header.ResponseMhJson)
            ?.selloRecibido || "";
        const firmaElectronica =
          this.dataProps.data.header.JsonMhSigned?.documento || "";
        const jsonToSend = {
          ...this.dataProps.data.header.JsonMh.dteJson,
          firmaElectronica,
          selloRecibido,
        };
        delete jsonToSend.passwordPri;

        const aElement = document.createElement("a");
        aElement.setAttribute(
          "download",
          this.dataProps.data.header.ID_Invoice +
            "_" +
            this.dataProps.data.header.InvoiceDate.replaceAll("/", "-") +
            "_" +
            this.dataProps.data.header.InvoiceName.replaceAll(
              /[:/<>*|/]/g,
              ""
            ).replaceAll('\\"', "") +
            ".pdf"
        );
        this.assignCuerpoDocumento();
        const blob = new Blob([JSON.stringify(jsonToSend)], {
          type: "application/json",
        });
        const href = URL.createObjectURL(blob);
        aElement.href = href;
        aElement.download =
          this.dataProps.data.header.ID_Invoice +
          "_" +
          this.dataProps.data.header.InvoiceDate.replaceAll("/", "-") +
          "_" +
          this.dataProps.data.header.InvoiceName.replaceAll(
            /[:/<>*|/]/g,
            ""
          ).replaceAll('\\"', "") +
          ".json";
        aElement.click();
        URL.revokeObjectURL(href);
      } catch (error) {
        this.mailLabel = "Ocurrió un error al descargar JSON";
        console.log(error);
      }
    },
    async getImages() {
      const img = this.dataProps.data.branch.BranchBannerPath;
      const logo = this.dataProps.data.branch.BranchLogoPath;
      const { imageBase64: bannerImageBase64 } =
        await this.$API.utilities.getImageInBase64({ file: img });
      this.dataProps.data.branch.BranchBannerPath = `${bannerImageBase64}`;

      const { imageBase64: logoImageBase64 } =
        await this.$API.utilities.getImageInBase64({ file: logo });
      this.dataProps.data.branch.BranchLogoPath = `${logoImageBase64}`;

      const { imageBase64: opacityImageBase64 } =
        await this.$API.utilities.getImageInBase64({
          file: "https://innova-apps.sfo3.digitaloceanspaces.com/SoAmbientalMarcaDeAgua.jpg",
        });
      this.imageOpacity = `${opacityImageBase64}`;
    },
  },
};
</script>
